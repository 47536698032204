import ChevronRightCircled from "../../../assets/chevron-right-circled.svg";
import { useState, useEffect, useRef } from "react";
import api from "../../../utils/api";
import { useSelector } from "react-redux";
import { openModal, setModalDirty } from "../../../app/slices/globalUISlice";
import { useDispatch } from "react-redux";

const CommentForm = ({ resourceType, resourceId, onSuccess, showDivider = true }) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const { currentUserId, currentUser } = useSelector((state) => state.user);
  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on content
    }
  }, [comment]);

  const handleCreateComment = async (event) => {
    event.preventDefault();
    const commentData = { content: comment, resource_type: resourceType, resource_id: resourceId };

    api
      .post(`/comments`, { comment: commentData })
      .then((data) => {
        dispatch(setModalDirty(false));
        onSuccess(data);
        setComment("");
      })
      .catch((err) => console.log("Error posting comment", err));
  };

  const handleChange = (event) => {
    dispatch(setModalDirty(true));
    setComment(event.target.value);
  };

  if (!currentUserId) {
    return (
      <button
        className="flex gap-2 pt-3.5 w-full border-t border-t-gray"
        onClick={() => dispatch(openModal("loginPrompt"))}
      >
        <div className="px-2.5 py-2 flex-grow resize-none border rounded-default text-gray text-base">
          Write a comment...
        </div>
      </button>
    );
  }

  return (
    <form className={`w-full ${showDivider ? "border-t border-t-gray" : ""}`} onSubmit={handleCreateComment}>
      <div className="flex gap-2 pt-3.5">
        <div className="relative rounded-full w-[30px] h-[30px] bg-gray">
          {currentUser?.image_url && (
            <img src={currentUser.image_url} className="w-full h-full rounded-full border border-offBlack absolute" />
          )}
        </div>
        <textarea
          ref={textareaRef}
          minLength="2"
          value={comment}
          placeholder="Write a comment..."
          onChange={handleChange}
          className="inline-block px-2.5 py-2 flex-grow h-auto focus:outline-none resize-none placeholder:text-gray text-offBlack text-base"
          required
        />
        <button type="submit" className="self-end mb-6">
          <img src={ChevronRightCircled} alt="submit comment" />
        </button>
      </div>
    </form>
  );
};

export default CommentForm;
