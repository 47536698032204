import React, { useState } from "react";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import api from "../../utils/api";
import ClubCard from "../cards/ClubCard";
import InternalSearchbar from "../helpers/InternalSearchbar";
import HeadTags from "../helpers/HeadTags";
import ViewMoreButton from "../helpers/ViewMoreButton";

const AllClubs = () => {
  const [viewAll, setViewAll] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const allClubsQuery = useSuspenseInfiniteQuery({
    queryKey: ["allClubs"],
    queryFn: ({ pageParam }) => api.get(`/clubs?page=${pageParam}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage.length < 20 ? undefined : pages.length + 1,
  });

  const clubs = allClubsQuery.data.pages.flat();

  const handleSearchResults = (results, query) => {
    setViewAll(false);
    setSearchResults(results);
    setSearchQuery(query);
  };

  const onReset = () => {
    setViewAll(true);
  };

  return (
    <div className="container max-w-[900px] m-auto text-left px-2 py-8 md:py-16 flex flex-col">
      <HeadTags title="Browse Clubs" description="Discuss books with likeminded readers and find your community." />
      <div className="flex flex-col gap-6 self-center">
        <h2 className="self-center">All Clubs</h2>
        <InternalSearchbar type="Club" resultHandler={handleSearchResults} onReset={onReset} />
      </div>

      <div className="mt-4 md:mt-8 flex flex-col gap-3 md:gap-6">
        {viewAll ? (
          <>
            {clubs.map((club) => (
              <ClubCard key={club.id} club={club} />
            ))}
            <ViewMoreButton {...allClubsQuery} />
          </>
        ) : searchResults.length > 0 ? (
          searchResults.map((club) => <ClubCard key={club.id} club={club} />)
        ) : (
          <p className="text-center">We couldn't find any clubs matching "{searchQuery}" ☹️</p>
        )}
      </div>
    </div>
  );
};

export default AllClubs;
