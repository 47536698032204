import React from "react";
import { Link } from "react-router-dom";

const BookCover = ({ book, showTitle, size, clickable = true }) => {
  const altBookImg = "/assets/AltBookNoBorder.svg";
  let img;
  if (book.image_url) {
    img = (
      <img
        className={`${size || "w-full h-full"} border shadow-main`}
        src={book.image_url}
        alt={book.title}
      />
    );
  } else {
    img = (
      <div
        className={`${size || "w-full h-full"} border shadow-main`}
        style={{
          backgroundImage: `url(${altBookImg})`,
          backgroundSize: "cover",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {showTitle && (
          <div className="text-[11px] text-ellipsis line-clamp-4 md:text-[14px] box-border text-center max-h-full w-full pl-2.5 pr-0.5 md:pl-4 md:pr-1 leading-none md:leading-tight">
            {book?.title}
          </div>
        )}
      </div>
    );
  }

  if (clickable && book.uuid) {
    return (
      <Link className={"w-full h-full hover:text-black"} to={`/books/${book.uuid}`}>
        {img}
      </Link>
    );
  } else {
    return img;
  }
};

export default BookCover;
