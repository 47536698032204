import clsx from "clsx";

const Tag = ({ text, color, size = "small", IconComponent }) => {
  const sizeClasses = size === "medium" ? "text-sm" : "text-[10px] md:text-xs";
  return (
    <div
      className={clsx(
        "flex flex-none gap-1 justify-center items-center border border-offBlack px-2 md:px-4 py-1 h-fit rounded-default",
        color,
        sizeClasses
      )}
    >
      {text}
      {IconComponent && <IconComponent fontSize="inherit" />}
    </div>
  );
};

export default Tag;
