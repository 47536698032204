import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../layout/Layout";
import StatusView from "./StatusView";
import LibraryList from "./LibraryList";
import IconButton from "../../helpers/IconButton";
import HeadTags from "../../helpers/HeadTags";
import CreateTagForm from "./CreateTagForm";
import { fetchShelves } from "../../../app/slices/shelfSlice";
import { setShowListView } from "../../../app/slices/globalUISlice";
import TagView from "./TagView";
import { getMaterialIcon } from "../../../utils/icons";
const GridViewRoundedIcon = getMaterialIcon("GridViewRounded");
const FormatListBulletedRoundedIcon = getMaterialIcon("FormatListBulletedRounded");

const LibraryBooks = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, uuid } = Object.keys(useParams()).length > 0 ? useParams() : { status: "current" };
  const [showLibraryList, setShowLibraryList] = useState(false);
  const { showListView } = useSelector((state) => state.globalUI);
  const { currentUserId } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchShelves());
  }, []);

  const ctas = [
    { text: "Books", isActive: true },
    { text: "Plan", onClick: () => navigate("/library/plan") },
    { text: "Bookmarks", onClick: () => navigate("/library/bookmarks") },
  ];

  return (
    <Layout
      title="Library"
      ctas={ctas}
      description="Your space to track your reading and categorize your books with tags."
      showUnauth={!currentUserId}
    >
      <HeadTags title="Library" description="Your space to track your reading and categorize your books with tags." />
      <div className="container max-w-[1280px] mx-auto flex lg:gap-12 justify-center box-border px-3 pb-[100px]">
        <div className="hidden lg:inline-block mt-12">
          <LibraryList currentStatus={status} shelfUuid={uuid} />
        </div>
        <div className="flex w-full lg:w-fit justify-center lg:justify-start">
          {showLibraryList ? (
            <div className="min-w-[300px] py-4 rounded-default border border-offBlack shadow-main bg-lightPurple flex m-auto justify-center items-center">
              <LibraryList currentStatus={status} shelfUuid={uuid} setShowLibraryList={setShowLibraryList} />
            </div>
          ) : (
            <div>
              <div className="hidden lg:flex flex-row justify-between mb-6">
                <div>{/* TODO: search bar */}</div>
                <div className="flex flex-row gap-2">
                  <IconButton
                    IconComponent={GridViewRoundedIcon}
                    onClick={() => dispatch(setShowListView(false))}
                    isActive={!showListView}
                    tooltip="Showcase view"
                  />
                  <IconButton
                    IconComponent={FormatListBulletedRoundedIcon}
                    onClick={() => dispatch(setShowListView(true))}
                    isActive={showListView}
                    tooltip="List view"
                  />
                </div>
              </div>
              {status ? (
                <StatusView
                  currentStatus={status}
                  showListView={showListView}
                  setShowLibraryList={setShowLibraryList}
                />
              ) : uuid === "new" ? (
                <CreateTagForm />
              ) : (
                <TagView shelfUuid={uuid} setShowLibraryList={setShowLibraryList} showListView={showListView} />
              )}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default LibraryBooks;
