export { Layout };

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MODAL_CONFIG from "../components/modals";
import Modal from "../components/modals/Modal";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { closeSnackbar } from "../app/slices/globalUISlice";
import './Layout.css';

const theme = createTheme({
  typography: {
    fontFamily: "Hanken Grotesk",
  },
  palette: {
    primary: {
      main: '#748BF6',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          borderBottom: "0px",
          padding: "6px",
        },
        head: {
          fontWeight: "bold",
        },
      },
    },
  },
});

function Layout({ children }) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const { modalName, modalData, snackbarData } = useSelector((state) => state.globalUI);
  const ModalComponent = MODAL_CONFIG[modalName] || Modal;

  useEffect(() => {
    if (currentUser?.uuid && typeof heap !== "undefined") heap.identify(currentUser?.uuid);
  }, [currentUser?.uuid]);

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        {modalName && <ModalComponent name={modalName} {...modalData} />}
        <NavBar />
        <div className="min-h-[70vh]">
          <ErrorBoundary>
            {children}
          </ErrorBoundary>
        </div>
        <Footer />
        <Snackbar
          open={!!snackbarData}
          key={snackbarData?.message}
          onClose={() => dispatch(closeSnackbar())}
          autoHideDuration={3000}
          ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
          {...snackbarData}
          ContentProps={{
            style: {
              backgroundColor: snackbarData?.error ? "red" : undefined,
            },
          }}
        />
      </ThemeProvider>
    </ErrorBoundary>
  );
};
