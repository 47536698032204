import axios from "axios";
import { API_URL } from "../constants.js";

// Use this wrapper around Axios for all requests to Rails BE (ie, where auth is needed)
const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let store;

// see https://stackoverflow.com/a/69148023
export const injectStore = (_store) => {
  store = _store;
};

instance.interceptors.request.use((config) => {
  config.headers.authorization = `Bearer ${store.getState().user.token}`;
  return config;
});

const get = async (path) => {
  const response = await instance.get(path);
  return response.data;
};

const post = async (path, body) => {
  const response = await instance.post(path, body);
  return response.data;
};

const postFile = async (path, body) => {
  const response = await instance.post(path, body, {
    headers: { "Content-Type": "application/binary" },
  });
  return response.data;
};

const put = async (path, body) => {
  const response = await instance.put(path, body);
  return response.data;
};

const destroy = async (path, body) => {
  const response = await instance.delete(path, { data: body });
  return response.data;
};

export default { get, post, postFile, put, destroy };
