import { useConfig } from 'vike-react/useConfig';

const HeadTags = ({ title, description, image }) => {
  const config = useConfig();
  const details = {};
  if (title) details.title = `${title} | Pagebound`;
  if (description) details.description = description.slice(0, 200);
  if (image) details.image = image;
  config(details);
};

export default HeadTags;
