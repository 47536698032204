import React, { useState} from "react";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import api from "../../utils/api";
import ListCard from "../cards/ListCard";
import InternalSearchbar from "../helpers/InternalSearchbar";
import HeadTags from "../helpers/HeadTags";
import ViewMoreButton from "../helpers/ViewMoreButton";

const AllLists = () => {
  const [viewAll, setViewAll] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const allListsQuery = useSuspenseInfiniteQuery({
    queryKey: ["allLists"],
    queryFn: ({ pageParam }) => api.get(`/lists?page=${pageParam}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage.length < 20 ? undefined : pages.length + 1,
  });

  const lists = allListsQuery.data.pages.flat();

  const handleSearchResults = (results, query) => {
    setViewAll(false);
    setSearchResults(results);
    setSearchQuery(query);
  };

  const onReset = () => {
    setViewAll(true);
  };

  return (
    <div className="container max-w-[900px] m-auto text-left px-2 py-8 md:py-16 flex flex-col">
      <HeadTags title="Browse Lists" description="Like a bookish Pinterest board, browse and create themed collections." />
      <div className="flex flex-col gap-6 self-center">
        <h2 className="self-center">All Lists</h2>
        <InternalSearchbar type="List" resultHandler={handleSearchResults} onReset={onReset} />
      </div>

      <div className="mt-4 md:mt-8 flex flex-col gap-3 md:gap-6">
        {viewAll ? (
          <>
            {lists.map((list) => (
              <ListCard key={list.id} listData={list} />
            ))}
            <ViewMoreButton {...allListsQuery} />
          </>
        ) : searchResults.length > 0 ? (
          searchResults.map((list) => <ListCard key={list.id} listData={list} />)
        ) : (
          <p className="text-center">We couldn't find any lists matching "{searchQuery}" ☹️</p>
        )}
      </div>
    </div>
  );
};

export default AllLists;
