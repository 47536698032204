import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import FullActivityItem from "../home/FullActivityItem";
import expand from "../../assets/graphic-expand.svg";
import { closeModal } from "../../app/slices/globalUISlice";

const ShowActivityItemModal = ({ activityItemId, setCardActivityItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (!activityItemId) return;

  return (
    <Modal
      isOpen
      classNames={{
        container: "min-w-[95%] md:min-w-[90%] lg:min-w-[700px] lg:max-w-[800px] !overflow-y-clipped",
        margin: "mt-[30%] md:mt-[120px]",
      }}
      headerActions={[
        {
          icon: expand,
          onClick: () => {
            navigate(`/activity/${activityItemId}`);
            dispatch(closeModal());
          },
        },
      ]}
    >
      <FullActivityItem activityItemId={activityItemId} isModal={true} setCardActivityItem={setCardActivityItem} />
    </Modal>
  );
};

export default ShowActivityItemModal;
