export { Wrapper };

import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { StaticRouter } from "react-router-dom/server";
import { Provider } from "react-redux";
import { usePageContext } from "vike-react/usePageContext";
import { PersistGate } from "redux-persist/lib/integration/react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { getStore } from "../app/store";
import { injectStore } from "../utils/api";
import { history } from '../utils/navigation';

function Wrapper({ children }) {
  const pageContext = usePageContext();
  const [RouterComponent, routerProps] = import.meta.env.SSR
    ? [StaticRouter, { location: pageContext.urlPathname }]
    : [HistoryRouter, { history: history }];

  let store = null, persistor = null;
  if (import.meta.env.SSR) {
    store = pageContext.preloadedStore;
  } else {
    const { reduxStore, reduxPersistor } = getStore(pageContext.preloadedState);
    store = reduxStore;
    persistor = reduxPersistor;
  }
  injectStore(store);

  return (
    <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
      <RouterComponent {...routerProps}>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            {/* Somehow including a function wrapper here makes the PersistGate play nicely on the server-side AND avoid
            hydration mismatches on the client. See https://github.com/vercel/next.js/issues/8240#issuecomment-647699316 */}
            {() => <>{children}</>}
          </PersistGate>
        </Provider>
      </RouterComponent>
    </GoogleOAuthProvider>
  );
};
