import React from "react";
import Button from "../../helpers/Button";
import { getMaterialIcon } from "../../../utils/icons";
const AddCircleOutlineIcon = getMaterialIcon("AddCircleOutline");

const LibraryAddBookCard = ({ onClick }) => {
  return (
    <div className="flex md:flex-col w-full max-w-[320px] md:w-[180px] md:h-[250px] box-border gap-4 md:gap-0 p-4 md:p-6 md:py-8 md:justify-between bg-mainBlue items-center shadow-main rounded-default border border-offBlack">
      <div
        onClick={onClick}
        className="flex justify-center items-center bg-white w-[62px] h-24 md:w-[104px] md:h-40 border border-offBlack cursor-pointer"
      >
        <AddCircleOutlineIcon fontSize={"small"} sx={{ color: "#333" }} />
      </div>
      <Button onClick={onClick} text="add book" color="bg-neonBlue md:hidden" size="medium" />
    </div>
  );
};

export default LibraryAddBookCard;
