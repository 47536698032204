import React from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import { useParams } from "react-router-dom";
import FullPost from "./showPost/FullPost";
import Button from "../helpers/Button";
import HeadTags from "../helpers/HeadTags";
import api from "../../utils/api";

const PostPage = withFallback(
  () => {
    const { id } = useParams();
    const { data: post } = useSuspenseQuery({
      queryKey: ["post", id],
      queryFn: () => api.get(`/posts/${id}`),
      retry: false,
    });

    const forumLink =
      post.forum.resource_type === "Book"
        ? `/books/${post.forum.resource_uuid}#forum-reviews`
        : `/clubs/${post.forum.resource_uuid}?view=forum`;

    return (
      <div className="container max-w-[856px] m-auto text-left px-2 py-8 md:py-16">
        <HeadTags title={`Post in the ${post?.forum?.name || ""} forum`} description={post?.content} />
        <div className="hidden md:block">
          <Button
            type="link"
            to={forumLink}
            text={`⬅ Back to ${post.forum.name ? post.forum.name : ""} forum`}
            color="bg-neonBlue"
          />
        </div>
        <div className="md:hidden">
          <Button
            type="link"
            to={forumLink}
            text={`⬅ Back to ${post.forum.resource_type === "Book" ? "book" : "club"} forum`}
            color="bg-neonBlue"
          />
        </div>
        <div className="bg-white shadow-main rounded-default mt-4 md:mt-8 p-4 md:p-6 border border-offBlack">
          <FullPost postData={post} />
        </div>
      </div>
    );
  },
  undefined, // use default Loading component
  () => (
    <div className="min-h-[100vh] h-max m-auto pt-8 flex flex-col gap-4 items-center justify-center pb-[50px] md:pb-[100px]">
      <h2>Hmm, we couldn't find that...</h2>
      <Button type="link" to="/feed" text="⬅ Back to home" color="bg-fuschia" />
    </div>
  )
);

export default PostPage;
