import React from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { useConfig } from 'vike-react/useConfig';
import api from "../../utils/api";

const AllAuthors = () => {
  const config = useConfig();
  config({ title: "Authors | Pagebound" });
  const { data: authors } = useSuspenseQuery({
    queryKey: ["allAuthors"],
    queryFn: () => api.get(`/authors`),
  });

  return (
    <div className="container m-auto text-left my-8">
      <div className="flex flex-row gap-2 flex-wrap">
        {authors.map((author) => (
          <Link key={`author_${author.id}`} to={`/authors/${author.uuid}`}>{author.name}</Link>
        ))}
      </div>
    </div>
  );
};

export default AllAuthors;
