import React from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import { useParams } from "react-router-dom";
import FullReview from "./FullReview";
import Button from "../helpers/Button";
import HeadTags from "../helpers/HeadTags";
import api from "../../utils/api";

const ReviewPage = withFallback(
  () => {
    const { id } = useParams();
    const { data: { review } } = useSuspenseQuery({
      queryKey: ["review", id],
      queryFn: () => api.get(`/reviews/${id}`),
      retry: false,
    });

    return (
      <div className="container max-w-[856px] m-auto text-left px-2 py-8 md:py-16">
        <HeadTags title={`Review for ${review?.book?.title}`} description={review?.review} />
        <Button type="link" to={`/books/${review.book.uuid}`} text={`⬅ Back to book reviews`} color="bg-neonBlue" />

        <div className="bg-white shadow-main rounded-default mt-4 md:mt-8 p-4 md:p-6 border border-offBlack">
          <FullReview reviewId={id} />
        </div>
      </div>
    );
  },
  undefined, // use default Loading component
  () => (
    <div className="min-h-[100vh] h-max m-auto pt-8 flex flex-col gap-4 items-center justify-center pb-[50px] md:pb-[100px]">
      <h2>Hmm, we couldn't find that...</h2>
      <Button type="link" to="/feed" text="⬅ Back to home" color="bg-fuschia" />
    </div>
  )
);

export default ReviewPage;
