import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api.js";

export const fetchClub = createAsyncThunk("club/fetchClub", (uuid) => api.get(`/clubs/${uuid}`));

export const createClub = createAsyncThunk("club/createClub", (clubData, { rejectWithValue }) =>
  api
    .post(`/clubs`, { club: clubData })
    .then((data) => data)
    .catch((err) => rejectWithValue(err.response.data.error))
);

export const joinClub = createAsyncThunk("club/joinClub", (uuid) => api.post(`/clubs/${uuid}/join`));

export const leaveClub = createAsyncThunk("club/leaveClub", (uuid) => api.post(`/clubs/${uuid}/leave`));

export const addClubBook = createAsyncThunk("club/addClubBook", (clubBookData, { rejectWithValue }) =>
  api
    .post(`/clubs/${clubBookData.club_uuid}/add_book`, {
      book_id: clubBookData.book_id,
      showcase: clubBookData.showcase,
    })
    .then((data) => ({ ...data, club_uuid: clubBookData.club_uuid }))
    .catch((err) => rejectWithValue("Book already added!"))
);

export const removeClubBook = createAsyncThunk("club/removeClubBook", (clubBookData) =>
  api
    .post(`/clubs/${clubBookData.club_uuid}/remove_book`, {
      book_id: clubBookData.book_id,
    })
    .then((data) => ({ ...data, club_uuid: clubBookData.club_uuid }))
);

export const updateClub = createAsyncThunk("club/updateClub", ({ clubData, uuid }) =>
  api.put(`/clubs/${uuid}`, { club: clubData })
);

const initialState = {
  currentClub: null,
  loading: false,
  error: null,
};

export const clubSlice = createSlice({
  name: "club",
  initialState,
  reducers: {
    clearCurrentClub: (state) => {
      return { ...state, currentClub: null };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClub.fulfilled, (state, action) => {
      return { ...state, currentClub: action.payload, loading: false };
    });
    builder.addCase(fetchClub.pending, (state, action) => {
      return { ...state, loading: true, error: null };
    });
    builder.addCase(fetchClub.rejected, (state, action) => {
      return { ...state, error: action.payload, loading: false };
    });
    builder.addCase(createClub.fulfilled, (state, action) => {
      return { ...state, currentClub: action.payload };
    });
    builder.addCase(joinClub.fulfilled, (state, action) => {
      return { ...state, currentClub: action.payload };
    });
    builder.addCase(leaveClub.fulfilled, (state, action) => {
      return { ...state, currentClub: action.payload };
    });
    builder.addCase(addClubBook.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(addClubBook.fulfilled, (state, action) => {
      if (state.currentClub.uuid !== action.payload.club_uuid) return;
      if (action.payload.showcase) {
        return {
          ...state,
          error: null,
          currentClub: {
            ...state.currentClub,
            showcased_books: [...state.currentClub.showcased_books, action.payload.book],
          },
        };
      }
    });
    builder.addCase(removeClubBook.fulfilled, (state, action) => {
      if (state.currentClub.uuid !== action.payload.club_uuid) return;
      return {
        ...state,
        currentClub: {
          ...state.currentClub,
          showcased_books: state.currentClub.showcased_books.filter((b) => b.id !== action.payload.book_id),
        },
      };
    });
    builder.addCase(updateClub.fulfilled, (state, action) => {
      if (state.currentClub.uuid !== action.payload.uuid) return;
      return { ...state, currentClub: action.payload };
    });
  },
});

export const { clearCurrentClub } = clubSlice.actions;
export default clubSlice.reducer;
