export default { component: LoadingComponent, layout: LoadingLayout };

import Loader from "../components/helpers/Loader";

function LoadingComponent() {
  // Applies on a component-level
  return (
    <Loader classes="min-h-[100px]">
      {process.env.NODE_ENV === "development" && (
        <div>COMPONENT: {import.meta.env.SSR ? "Server-side" : "Client-side"} loading...</div> 
      )}
    </Loader>
  );
};

function LoadingLayout() {
  // Applies to the page and all layouts
  return (
    <Loader variant="fullPage">
      {process.env.NODE_ENV === "development" && <div>LAYOUT: Server-side still loading...</div>}
    </Loader>
  );
};
