import React from "react";
import { useSuspenseInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import { useDispatch, useSelector } from "react-redux";
import ClubCard from "../cards/ClubCard";
import api from "../../utils/api";
import Button from "../helpers/Button";
import RecommendationCard from "../cards/RecommendationCard";
import { openModal, openSnackbar } from "../../app/slices/globalUISlice";
import QuestCard from "../quests/QuestCard";
import ListCard from "../cards/ListCard";
import ViewMoreButton from "../helpers/ViewMoreButton";
import { getMaterialIcon } from "../../utils/icons";
const AddCircleOutlineIcon = getMaterialIcon("AddCircleOutline");

const BookCommunityRecs = ({ bookUuid, view }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { book: currentBook } = queryClient.getQueryData(["book", bookUuid]);
  const { currentUserId } = useSelector((state) => state.user);

  const communityRecsQuery = useSuspenseInfiniteQuery({
    queryKey: ["communityRecs", bookUuid, view],
    queryFn: ({ pageParam }) => api.get(`/books/${bookUuid}/${view}?page=${pageParam}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => pages.length < lastPage.total_pages ? pages.length + 1 : undefined,
  });

  const communityRecs = communityRecsQuery.data.pages.map((p) => p[view]).flat();

  // only used when view === recommendations (ie, book recommendations)
  const addRecommendationCard = () => {
    const onClick = () => {
      if (!currentUserId) return dispatch(openModal("loginPrompt"));

      const addRecommendation = (recommended_book) => {
        const params = { book_id: currentBook.id, recommended_book_id: recommended_book.id };
        api
          .post(`/recommendations`, { recommendation: params })
          .then(communityRecsQuery.refetch)
          .catch((error) => {
            console.error("Error creating recommendation:", error?.message);
            if (error.response.status === 422) {
              dispatch(openSnackbar({ message: "Recommendation already added!", error: error }));
            }
          });
      };
      const addedBookIds = communityRecs.map((r) => r.recommended_book.id);
      dispatch(openModal({ name: "searchReturnBook", data: { handleClick: addRecommendation, addedBookIds } }));
    };

    return (
      <div className="flex" key="add-book-card-container">
        <button
          onClick={onClick}
          className="flex flex-col flex-none h-fit w-fit box-border py-[31.5px] px-[45px] md:px-[40px] md:py-[42.5px] justify-between bg-mainBlue items-center justify-center shadow-main rounded-default border border-offBlack"
        >
          <div className="flex justify-center items-center bg-white w-14 h-24 md:w-[100px] md:h-[165px] rounded-boxy border border-offBlack cursor-pointer">
            <AddCircleOutlineIcon fontSize={"small"} sx={{ color: "#333" }} />
          </div>
        </button>
      </div>
    );
  };

  if (communityRecs.length === 0) {
    return (
      <div className="w-full">
        <div className="flex flex-col md:flex-row max-w-[908px] justify-between items-center p-4 md:p-6 mb-4 rounded-default bg-pastelOrange border border-offBlack shadow-main">
          <div className="flex flex-col gap-2 md:gap-3 text-left pr-5">
            {view === "recommendations" && (
              <>
                <b>No book recs yet ☹️</b>
                <p>Know what other fans of this book would like? Add a rec below.</p>
              </>
            )}
            {view === "clubs" && (
              <>
                <b>No Clubs yet ☹️</b>
                <p>Are you a fan? Be the first to create a Club featuring this book.</p>
              </>
            )}
            {view === "quests" && (
              <b>No Quests yet!</b>
            )}
            {view === "lists" && (
              <>
                <b>No Lists yet ☹️</b>
                <p>Are you ready to be a tastemaker? Create a list featuring this book.</p>
              </>
            )}
          </div>
          {view === "clubs" && <Button view="link" to="/clubs/new" color="bg-fuschia self-end md:self-auto" text="Create club" />}
          {view === "lists" && <Button view="link" to="/lists/new" color="bg-fuschia self-end md:self-auto" text="Create list" />}
        </div>
        {view === "recommendations" && addRecommendationCard()}
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col gap-5 p-4 md:p-8 bg-gradient-to-b from-pastelOrange to-pastelGreen rounded-default">
      <div className={`flex flex-col gap-4 ${view === "recommendations" ? "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4" : ""}`}>
        {view === "recommendations" && addRecommendationCard()}
        {communityRecs.map((rec) => (
          <React.Fragment key={rec.id || rec.uuid}>
            {view === "recommendations" && <RecommendationCard key={`book_${rec.id}`} recommendation={rec} />}
            {view === "clubs" && <ClubCard key={`club_${rec.uuid}`} club={rec} />}
            {view === "quests" && <QuestCard key={`quest_${rec.uuid}`} quest={rec} />}
            {view === "lists" && <ListCard key={`list_${rec.uuid}`} listData={rec} />}
          </React.Fragment>
        ))}
      </div>
      <ViewMoreButton {...communityRecsQuery} />
    </div>
  );
};

export default withFallback(BookCommunityRecs);
