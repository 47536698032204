import React, { useEffect } from "react";
import { useQueryClient, useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { useConfig } from 'vike-react/useConfig'
import api from "../../utils/api";
import NotificationCard from "./NotificationCard";
import ViewMoreButton from "../helpers/ViewMoreButton";

const NotificationsHome = () => {
  const config = useConfig();
  const queryClient = useQueryClient();
  config({ title: "Notifications | Pagebound" });

  useEffect(() => {
    api.post(`users/mark_notifications_as_read`)
      .then((data) => queryClient.setQueryData(["unreadNotifications"], data));
  }, []);

  const notificationsQuery = useSuspenseInfiniteQuery({
    queryKey: ["notifications"],
    queryFn: ({ pageParam }) => api.get(`/users/notifications?page=${pageParam}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage.length < 30 ? undefined : pages.length + 1,
  });
  const notifications = notificationsQuery.data.pages.flat();

  return (
    <div className="container m-auto flex flex-col items-center px-2 md:px-0 pb-[50px]">
      <h1 className="mt-8 mb-4 md:mb-8">Notifications</h1>
      <div className="flex flex-col gap-2 md:gap-4 items-center w-full md:w-[700px]">
        {notifications.length == 0 && <p>No notifications yet!</p>}
        {notifications.map((notification) => (
          <NotificationCard key={notification.id} notification={notification} />
        ))}
        <ViewMoreButton {...notificationsQuery} />
      </div>
    </div>
  );
};

export default NotificationsHome;
