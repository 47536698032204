import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { closeModal } from "../../app/slices/globalUISlice";
import UserCard from "../cards/UserCard";
import Modal from "./Modal";
import api from "../../utils/api";
import Loader from "../helpers/Loader";
import Button from "../helpers/Button";

const ViewMembersModal = ({ club, quest }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(true);
  const [allMembers, setAllMembers] = useState([]);
  const [pendingMembers, setPendingMembers] = useState([]); // only relevant for clubs

  useEffect(() => {
    const route = club ? `/clubs/${club.uuid}/members` : `/challenges/${quest.uuid}/members`;
    api.get(route)
      .then((data) => {
        if (data.joined) {
          setAllMembers(data.joined);
        } else {
          setAllMembers(data);
        }
        if (data.pending) setPendingMembers(data.pending);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const approveMember = (user, e) => {
    e.stopPropagation();
    api.post(`/clubs/${club.uuid}/approve?user_id=${user.id}`)
      .then((data) => {
        setAllMembers(data.joined);
        setPendingMembers(data.pending);
        queryClient.refetchQueries(["club", club.uuid]);
      });
  };

  const denyMember = (user, e) => {
    e.stopPropagation();
    api.post(`/clubs/${club.uuid}/deny?user_id=${user.id}`)
      .then((data) => {
        setAllMembers(data.joined);
        setPendingMembers(data.pending);
        queryClient.refetchQueries(["club", club.uuid]);
      });
  };

  return (
    <Modal isOpen>
      <div className="md:min-w-[500px]">
        <h2 className="mb-6">All {club ? "members" : "participants"}</h2>
        {isLoading && <Loader />}
        <div className="flex flex-col gap-4">
          {pendingMembers?.map((user) => (
            <UserCard
              key={user.id}
              user={user}
              callback={handleClose}
              ActionOverride={
                <div className="flex flex-row gap-2">
                  <Button text="Deny" color="bg-red" onClick={(e) => denyMember(user, e)} />
                  <Button text="Approve" color="bg-mainGreen" onClick={(e) => approveMember(user, e)} />
                </div>
              }
            />
          ))}
          {allMembers?.map((user) => <UserCard key={user.id} user={user} callback={handleClose} />)}
        </div>
      </div>
    </Modal>
  );
};

export default ViewMembersModal;
