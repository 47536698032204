import React, { useEffect, useState } from "react";
import { Formik, Field, Form, useField, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { completeOnboarding } from "../../app/slices/onboardingSlice";
import { openModal } from "../../app/slices/globalUISlice";
import Switch from "../helpers/Switch";
import x from "../../assets/graphic-x.svg";
import { AVATARS } from "../../constants";
import BookCover from "../helpers/BookCover";
import OnboardingProgressBar from "./OnboardingProgressBar";
import { getMaterialIcon } from "../../utils/icons";
const RemoveCircleIcon = getMaterialIcon("RemoveCircle");
const AddCircleOutlineIcon = getMaterialIcon("AddCircleOutline");
const InfoIcon = getMaterialIcon("Info");

const OnboardingProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addedBooks, setAddedBooks] = useState([]);
  const currentUser = useSelector((state) => state.user.currentUser);
  const [openAvatarSelector, setOpenAvatarSelector] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser.onboarding_completed) {
      navigate("/feed");
    }
  }, [currentUser]);

  const AvatarSelector = ({ name, values }) => {
    const [field, meta, helpers] = useField(name);
    const { value } = meta;
    const { setValue } = helpers;

    const AvatarModal = () => {
      const handleSelectAvatar = (avatar) => {
        setValue(avatar);
        setOpenAvatarSelector(false);
      };

      return (
        <div
          className="fixed z-20 inset-0 overflow-y-auto bg-offBlack bg-opacity-35 flex items-center justify-center"
          onClick={() => setOpenAvatarSelector(false)}
        >
          <div
            className="relative bg-white w-fit overflow-y-scroll overflow-hidden border box-border max-h-[500px] border-offBlack shadow-main rounded-[20px]"
            onClick={(e) => e.stopPropagation()}
          >
            <button onClick={() => setOpenAvatarSelector(false)} className="absolute top-4 right-4">
              <img src={x} alt="close modal" className="h-5 w-5" />
            </button>
            <div className="p-7">
              <div className="grid grid-cols-4 gap-3 mt-3">
                {Object.entries(AVATARS).map(([key, value]) => (
                  <button
                    onClick={() => handleSelectAvatar(value)}
                    key={key}
                    className="flex-none w-[40px] h-[40px] md:w-[70px] md:h-[70px]"
                  >
                    <img
                      src={value}
                      className="flex-none w-[40px] h-[40px] md:w-[70px] md:h-[70px] border rounded-full"
                    />
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="flex gap-4 items-center">
        <div>Select Avatar</div>
        {openAvatarSelector && <AvatarModal />}
        <button onClick={() => setOpenAvatarSelector(true)}>
          <div className="relative w-[60px] h-[60px]">
            <div
              className={`w-full h-full rounded-full absolute border border-offBlack ${
                values.open_to_friends ? "bg-mainGreen shadow-main left-1 top-1" : "bg-white"
              }`}
            />
            {value && (
              <img src={value} alt="avatar" className="w-full h-full rounded-full border border-offBlack absolute" />
            )}
          </div>
        </button>
      </div>
    );
  };

  const UsernameField = (props) => {
    const [field, meta] = useField(props);
    const [didFocus, setDidFocus] = React.useState(false);
    const handleFocus = () => setDidFocus(true);
    const showFeedback = (!!didFocus && field.value.trim().length > 2) || meta.touched;

    return (
      <div className="flex flex-col gap-2 w-full items-start">
        <div className="flex flex-col gap-1">
          <div className="flex items-center space-between">
            <label htmlFor="username">Username</label>{" "}
            {showFeedback ? (
              <div
                id="username-feedback"
                aria-live="polite"
                className={`feedback text-sm ml-2 ${meta.error && "text-red"}`}
              >
                {meta.error ? meta.error : "✓"}
              </div>
            ) : null}
          </div>
          <div className="text-xs text-gray text-left flex-none whitespace-nowrap">You can change this later</div>
        </div>
        <div className="flex items-center justify-center gap-1">
          <input
            {...props}
            {...field}
            placeholder="spacebunny99"
            className="w-full ml-0 mr-5 px-4 py-3 leading-tight my-auto border border-offBlack rounded-default focus:outline-none focus:ring-1 focus:border-seafoam focus:ring-seafoam"
            aria-describedby="username-feedback username-help"
            onFocus={handleFocus}
          />
          <button
            type="button"
            onClick={() =>
              dispatch(
                openModal({
                  name: "info",
                  data: {
                    title: "Not sure what to choose?",
                    body: "Many in our community opt for anonymity. Here's what people have chosen recently: overbooked23, merlins.beard, xaddy11",
                  },
                })
              )
            }
          >
            <InfoIcon className="text-seafoam" fontSize="medium" />
          </button>
        </div>
      </div>
    );
  };

  const OpenToFriendsSwitch = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;

    return (
      <div className="flex">
        <label className="mr-4" htmlFor="open_to_friends">
          Open to making new friends?
        </label>
        <Switch id="open_to_friends" isChecked={value} onChange={() => setValue(!value)} />
      </div>
    );
  };

  const BookPicker = () => {
    const onClickAddBookCard = () => {
      const handleClick = (book) => {
        if (addedBooks?.length < 5) {
          setAddedBooks((prev) => [...prev, book]);
        }
      };
      const addedBookIds = addedBooks.map((b) => b.id);
      dispatch(openModal({ name: "searchReturnBook", data: { addedBookIds, handleClick } }));
    };

    return (
      <div className="flex flex-col gap-3 w-full items-start">
        <div className="flex flex-col gap-1 w-full items-start text-left">
          <p className="font-bold">What's your taste in 5 books?</p>
          <div className="text-base text-left">
            These will be on your profile & can be edited later. Add at least 3 for now.
          </div>
        </div>
        <div className="flex flex-wrap w-full gap-4 items-start">
          {addedBooks.map((book, index) => (
            <div className="relative w-16 h-24 md:w-[105px] md:h-40" key={index}>
              <button
                className="absolute -top-[8px] -right-[7px]"
                onClick={() => {
                  setAddedBooks((prev) => prev.filter((b) => b.id !== book.id));
                }}
                style={{
                  backgroundImage: `url(/assets/delete.svg)`,
                  backgroundSize: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxSizing: "border-box",
                }}
              >
                <RemoveCircleIcon
                  fontSize="small"
                  className="text-[16px] md:text-[20px]"
                  sx={{
                    cursor: "pointer",
                    color: "red",
                  }}
                />
              </button>
              <BookCover
                book={book}
                size="w-16 h-24 md:w-[105px] md:h-40 border shadow-main"
                clickable={false}
                showTitle={true}
              />
            </div>
          ))}
          {addedBooks?.length < 5 && (
            <button
              onClick={onClickAddBookCard}
              type="button"
              className="flex justify-center items-center bg-white w-16 h-24 md:w-[105px] md:h-40 border border-offBlack shadow-main"
            >
              <AddCircleOutlineIcon fontSize={"small"} sx={{ color: "#333" }} />
            </button>
          )}
        </div>
      </div>
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    const showcasedBookIds = addedBooks.map((book) => book.id);
    dispatch(completeOnboarding({ onboardingData: values, showcasedBookIds }))
      .unwrap()
      .then((payload) => {
        setSubmitting(false);
        navigate("/onboarding/join-clubs");
      })
      .catch((error) => {
        setSubmitting(false);
        setStatus({
          error: error,
        });
      });
  };

  const profileSchema = Yup.object().shape({
    username: Yup.string()
      .required("Required")
      .min(5, "Must be at least 5 characters")
      .max(20, "Must be less than 20 characters")
      .matches(/^[a-zA-Z0-9._-]+$/, "Cannot contain special characters or spaces")
      .test(
        "not-starting-ending-dot-dash-underscore",
        "Must start and end with letter or number",
        (value) => !/^[._-]|[._-]$/.test(value)
      ),
    bio: Yup.string().max(200, "Max length reached"),
    open_to_friends: Yup.boolean().required("Required"),
    image_url: Yup.string().required("Required"),
  });

  return (
    <div className="min-h-[100vh] h-max m-auto flex flex-col items-center bg-gradient-to-b from-pastelOrange to-pastelPurple">
      <header className="mt-[78px] md:mt-[120px] flex flex-col gap-2 items-center w-full md:w-[90%] lg:w-[70%] mb-12">
        <OnboardingProgressBar step={1} />
        <h2>Welcome to Pagebound!</h2>
        <p className="max-w-[600px] px-4">
          The vibe here is playful and judgement-free. While many opt for anonymity, create a profile that's true to
          your reader self.
        </p>
      </header>
      <Formik
        validationSchema={profileSchema}
        validateOnMount={true}
        validateOnChange={true}
        onSubmit={handleSubmit}
        initialValues={{
          username: "",
          bio: "",
          open_to_friends: false,
          image_url: undefined,
        }}
      >
        {({ errors, values, isSubmitting, isValid, status }) => (
          <Form
            onKeyDown={handleKeyDown}
            className="w-11/12 lg:w-[700px] lg:pl-[40px] m-auto flex flex-col items-start justify-center gap-8 h-fit"
          >
            <div className="flex flex-col gap-3 w-full items-start">
              <div className="text-base text-left font-bold">Let's set up your profile</div>
              <AvatarSelector name="image_url" values={values} />

              <div className="flex flex-col gap-3 w-[350px] items-start">
                <UsernameField name="username" />
              </div>
              <OpenToFriendsSwitch name="open_to_friends" />
            </div>
            <div className="flex flex-col gap-3 w-full items-start">
              <BookPicker />
              <div className="flex flex-col gap-2 w-full items-start">
                <label htmlFor="bio">About me (optional)</label>
                <Field
                  id="bio"
                  name="bio"
                  as="textarea"
                  placeholder="max 200 characters"
                  className="w-full md:w-[600px] ml-0 mr-5 px-4 py-3 leading-tight my-auto border border-offBlack rounded-default focus:outline-none focus:ring-1 focus:border-seafoam focus:ring-seafoam"
                />
                <ErrorMessage name="bio" render={(msg) => <div className="text-red leading-none">{msg}</div>} />
              </div>
            </div>
            <div className="w-full flex justify-end items-center">
              {status && status.error && <div className="text-red leading-none mr-4">{status.error}</div>}
            </div>
            <div className="w-full max-w-[600px] mt-2 pb-[50px] md:pb-[100px] flex justify-end relative">
              <div className="hidden md:flex absolute w-[40px] h-[40px] leading-tight -right-[10px] md:-right-[54px] -top-[60px] bg-yellow rounded-full border shadow-main justify-center items-center text-[13px] font-bold">
                +10
              </div>
              <button
                className="bg-neonBlue flex-none shadow-main w-fit h-fit text-sm md:px-6 md:py-2 whitespace-nowrap leading-none font-bold font-space px-4 py-1.5 rounded-full cursor-pointer border border-offBlack disabled:bg-offWhite disabled:shadow-none disabled:border-gray disabled:text-gray disabled:cursor-auto"
                type="submit"
                disabled={!isValid || !(addedBooks.length >= 3) || isSubmitting}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OnboardingProfile;
