import React from "react";
import { useParams } from "react-router-dom";
import FullActivityItem from "./FullActivityItem";
import Button from "../helpers/Button";

const ActivityItemPage = () => {
  const { id } = useParams();
  return (
    <div className="container max-w-[856px] m-auto text-left px-2 py-8 md:py-16">
      <div className="hidden md:block">
        <Button type="link" to="/feed" text={`⬅ Back to home`} color="bg-neonBlue" />
      </div>
      <div className="md:hidden">
        <Button type="link" to="/feed" text={`⬅ Back to home`} color="bg-neonBlue" />
      </div>
      <div className="mt-4 md:mt-8">
        <FullActivityItem activityItemId={id} />
      </div>
    </div>
  );
};

export default ActivityItemPage;
