import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BookCover from "../helpers/BookCover";
import Button from "../helpers/Button";
import api from "../../utils/api";
import Loader from "../helpers/Loader";

const YourReadingChallengeCard = ({ user, variant }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { currentUserId } = useSelector((state) => state.user);
  const currentYear = new Date().getFullYear();
  const [challengeData, setChallengeData] = useState(null);
  const [challengeBooks, setChallengeBooks] = useState([]);

  const isCurrentUser = user?.id === currentUserId;

  useEffect(() => {
    setLoading(true);
    api
      .get(`/yearly_goal/goal_card?user_uuid=${user.uuid}`)
      .then((data) => {
        setChallengeData(data.yearly_goal);
        setChallengeBooks(data.books);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [user.uuid]);

  const percentComplete = Math.ceil(((challengeData?.books_read_count || 0) / (challengeData?.goal || 0)) * 100);

  if (loading) {
    return (
      <div className="w-full max-w-[1000px] min-h-[206px] md:min-h-[252px] p-4 box-border md:p-7 rounded-default bg-gradient-to-b from-pastelOrange to-pastelGreen"></div>
    );
  }

  if (challengeData && challengeData.goal === 0) {
    if (isCurrentUser) {
      return (
        <div className="w-full max-w-[1000px] p-4 box-border md:p-7 rounded-default bg-gradient-to-b from-pastelOrange to-pastelBlue">
          <div className="flex flex-col md:flex-row items-center bg-white border shadow-main rounded-default p-3 md:p-6 w-full justify-between gap-3">
            <p className="font-bold">Your {currentYear} Reading Challenge</p>
            <Button text="Set Goal" color="bg-neonBlue" size="medium" onClick={() => navigate("/reading-challenge")} />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  const containerClass =
    variant === "feed"
      ? ""
      : "w-full flex justify-center max-w-[1000px] min-h-[190px] md:min-h-[232px] p-4 box-border md:p-7 rounded-default bg-gradient-to-b from-pastelOrange to-pastelGreen";

  return (
    <div className={containerClass}>
      {challengeData && (
        <div className="flex flex-col self-center w-full p-4 md:p-6 gap-4 rounded-default border border-offBlack shadow-main bg-white">
          <div className="flex flex-col w-full gap-2 items-start justify-start text-left">
            <h2 className="text-base font-bold">
              {isCurrentUser ? "Your" : `${user?.username}'s`} {currentYear} Reading Challenge
            </h2>
            <div className="relative z-0 w-full text-center px-4 py-1 bg-white rounded-default border border-offBlack overflow-hidden box-border">
              <div
                className="absolute z-10 h-full left-0 top-0"
                style={{
                  width: `${percentComplete}%`,
                  background: `${challengeData.complete ? "#D6FE63" : "#FEDE83"}`,
                }}
              />
              <div className="relative z-20 text-xs md:text-[14px] font-space">
                {challengeData.books_read_count} of {challengeData.goal} read {challengeData.complete ? "🎉" : ""}
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-4 items-center">
            <div className="flex flex:none max-w-full gap-3 md:gap-4 items-center overflow-hidden">
              {challengeBooks.map((book, index) => (
                <div className="w-[54px] h-[80px] md:w-[86px] md:h-[132px] flex flex-none" key={`${book.id}-${index}`}>
                  <BookCover book={book} showTitle={true} />
                </div>
              ))}
            </div>
            <Button
              text="View challenge"
              color="bg-neonBlue"
              onClick={() =>
                isCurrentUser ? navigate("/reading-challenge") : navigate(`/users/${user?.username}/reading-challenge`)
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default YourReadingChallengeCard;
