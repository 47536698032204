import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../utils/api";
import x from "../../assets/graphic-x.svg";
import { useDispatch } from "react-redux";
import { openModal } from "../../app/slices/globalUISlice";
import Button from "../helpers/Button";
import OnboardingProgressBar from "./OnboardingProgressBar";
import { getMaterialIcon } from "../../utils/icons";
const InsertDriveFileOutlinedIcon = getMaterialIcon("InsertDriveFileOutlined");

const OnboardingGoodreadsImport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uploadIcon = "/assets/Upload.svg";
  const [fileInput, setFileInput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleOnFileSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", fileInput);

    setIsLoading(true);
    setError();
    api
      .postFile(`/goodreads/csv`, formData)
      .then(() => navigate("/onboarding/referral"))
      .catch((err) => setError(err?.message || true))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="min-h-[100vh] h-max bg-gradient-to-b from-pastelOrange to-pastelPurple m-auto">
      <div className="container m-auto flex flex-col items-center w-fit px-3 pb-[50px] md:pb-[100px]">
        {isLoading ? (
          <div className="flex flex-col text-left w-[300px] md:w-[400px]">
            <p className="mt-[50px] md:mt-[200px]">Importing your ratings and reviews...</p>
            <p className="mt-[30px]">Importing your books and shelves...</p>
            <h2 className="mt-[70px] mb-[100px]">
              Your profile will be updated after processing your Goodreads data...
            </h2>
            <div className="relative flex w-full justify-center items-center">
              <div className="loadRow">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="absolute flex w-[60px] h-[60px] leading-tight right-[10px] lg:left-[400px] bg-yellow rounded-full border shadow-main justify-center items-center text-[11px] md:text-[13px] font-bold">
                Bonus <br /> +50 pts
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <header className="mt-[78px] md:mt-[120px] flex flex-col gap-4 text-left md:text-center w-[300px] md:w-[610px]">
              <OnboardingProgressBar step={3} />
              <h2>Import your Goodreads data</h2>
              <p className="text-left md:text-center">
                Why? It'll make your experience seamless. We'll bring over your shelves, books, ratings, and reviews.{" "}
                <i> 80% of people import their Goodreads.</i>
              </p>
              <p className="font-bold text-left md:text-center"></p>
            </header>
            <div className="relative flex flex-col p-8 mt-8 w-[318px] rounded-default border shadow-main bg-pastelOrange items-center">
              <div
                className="text-base text-fuschia mb-6 cursor-pointer"
                onClick={() => dispatch(openModal("goodreadsDirections"))}
              >
                Need help? View directions here
              </div>
              {!fileInput && (
                <div className="flex flex-col gap-3 w-[252px] h-[216px] bg-white border rounded-default items-center justify-center">
                  <div
                    className="w-[63px] h-[44px] mb-8"
                    style={{
                      backgroundImage: `url(${uploadIcon})`,
                      backgroundSize: "100%",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                  <form>
                    <span id="file-selected">{fileInput?.name}</span>
                    <label
                      htmlFor="csvFileInput"
                      className="bg-neonBlue shadow-main min-w-[60px] h-fit whitespace-nowrap font-bold leading-none font-space text-[11px] md:text-[13px] rounded-[50px] px-4 py-1.5 md:py-[.5em] cursor-pointer border border-offBlack"
                    >
                      Choose file
                    </label>

                    <input
                      type={"file"}
                      className="hidden"
                      id={"csvFileInput"}
                      accept={".csv"}
                      onChange={(e) => setFileInput(e.target.files[0])}
                    />
                  </form>
                </div>
              )}
              {fileInput && (
                <div className="flex flex-col items-center">
                  <div className="relative p-3 box-border flex w-[148px] h-[132px] items-center justify-center flex-col bg-white border rounded-default gap-2">
                    <button className="absolute top-2 right-2" onClick={() => setFileInput(null)}>
                      <img src={x} alt="remove" />
                    </button>
                    <InsertDriveFileOutlinedIcon fontSize="large" />
                    <p className="text-[11px] md:text-[13px] leading-tight w-full truncate">{fileInput?.name}</p>
                  </div>
                  <Button text="Submit" onClick={handleOnFileSubmit} color="bg-neonBlue mt-8" />
                  {error && (
                    <div className="mt-2 text-[12px] text-red font-bold leading-tight">
                      Sorry! That didn't work. You can try again or <br /> upload later on your profile page.
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-col mt-3 w-[318px]">
              <Link className="text-gray text-base font-bold text-right" to="/onboarding/referral">
                I'm not a Goodreads user
              </Link>
            </div>
            <div className="h-[100px] mt-[50px] flex flex-col w-full">
              {!fileInput && (
                <button
                  onClick={() => navigate("/onboarding/referral")}
                  className="self-end bg-neonBlue flex-none shadow-main w-fit h-fit text-sm md:px-6 md:py-2 whitespace-nowrap leading-none font-bold font-space px-4 py-1.5 rounded-full cursor-pointer border border-offBlack disabled:bg-offWhite disabled:shadow-none disabled:border-gray disabled:text-gray disabled:cursor-auto"
                >
                  Next
                </button>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default OnboardingGoodreadsImport;
