import React from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import { openModal } from "../../app/slices/globalUISlice";
import { BOOK_STATUS_BORDER_COLOR } from "../../constants";

const BookPageSocialActivity = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { book: currentBook } = queryClient.getQueryData(["book", uuid]);
  if (Object.keys(currentBook?.following_activity || {}).length === 0) return null;

  return (
    <div className="mt-6">
      <b>Activity from people you follow</b>
      <div className="flex flex-row gap-3 flex-wrap mt-2">
        {Object.entries(currentBook.following_activity || {}).map(([status, { people, image_urls }]) => {
          let text;
          switch (status) {
            case "tbr":
              text = `${people} ${image_urls.length > 1 ? "want" : "wants"} to read`;
              break;
            case "current":
              text = `${people} ${image_urls.length > 1 ? "are" : "is"} reading`;
              break;
            case "finished":
              text = people + " finished";
              break;
            case "dnf":
              text = people + " DNFd";
              break;
          }

          return (
            <div
              key={status}
              onClick={() => dispatch(openModal({ name: "bookSocialActivity", data: { uuid, view: status } }))}
              className={clsx(
                "flex flex-row gap-2 items-center border-2 rounded-boxy w-fit py-1.5 px-3 text-sm md:text-base cursor-pointer",
                BOOK_STATUS_BORDER_COLOR[status]
              )}
            >
              {image_urls.length > 0 && (
                <div className="flex flex-row">
                  {image_urls.map((img, i) => (
                    <div key={i} className="relative h-[30px] w-[30px] -ml-[5px]">
                      <img src={img} alt="avatar" className="rounded-full border border-offBlack absolute" />
                    </div>
                  ))}
                </div>
              )}
              {text}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BookPageSocialActivity;
