import { getMaterialIcon } from "../../utils/icons";
const ExpandMoreOutlinedIcon = getMaterialIcon("ExpandMoreOutlined");

// To be used with useSuspenseInfiniteQuery
const ViewMoreButton = ({ hasNextPage, isFetchingNextPage, fetchNextPage, showCaughtUp = false }) => {
  return (
    <>
      {hasNextPage ? (
        <button
          className="bg-transparent p-2 self-center text-sm"
          disabled={isFetchingNextPage}
          onClick={fetchNextPage}
        >
          {isFetchingNextPage ? (
            "Loading..."
          ) : (
            <>
              View More <ExpandMoreOutlinedIcon fontSize="small" />
            </>
          )}
        </button>
      ) : showCaughtUp && (
        <p className="bg-transparent p-2 self-center text-sm">You're all caught up!</p>
      )}
    </>
  );
};

export default ViewMoreButton;
