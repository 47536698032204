import React, { useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import api from "../../utils/api";
import BookCard from "../cards/BookCard";
import { getMaterialIcon } from "../../utils/icons";
const NavigateBefore = getMaterialIcon("NavigateBefore");
const NavigateNext = getMaterialIcon("NavigateNext");

const TrendingBooks = () => {
  const [trendingPage, setTrendingPage] = useState(1);
  const { data: trendingBooks, isLoading } = useSuspenseQuery({
    queryKey: ["trendingBooks"],
    queryFn: () => api.get(`/books?q=trending`),
  });

  const trendingPagination = (perPage) => {
    const totalPages = Math.ceil(trendingBooks.length / perPage);

    return (
      <div className="flex self-center gap-2">
        <div className="w-[20px] h-[20px]">
          <button
            className={`${
              trendingPage === 1 && "hidden"
            } rounded-full border shadow-main w-[20px] h-[20px] flex items-center justify-center`}
            onClick={() => setTrendingPage(trendingPage - 1)}
            disabled={isLoading || trendingPage === 1}
          >
            <NavigateBefore />
          </button>
        </div>
        {[...Array(totalPages).keys()].map((i) => (
          <button
            onClick={() => setTrendingPage(i + 1)}
            key={i}
            className={`flex gap-2 ${trendingPage === i + 1 && "font-bold"}`}
          >
            {i + 1}
          </button>
        ))}
        <div className="w-[20px] h-[20px]">
          <button
            className={`${
              trendingPage === totalPages && "hidden"
            } rounded-full border shadow-main w-[20px] h-[20px] flex items-center justify-center`}
            onClick={() => setTrendingPage(trendingPage + 1)}
            disabled={isLoading || trendingPage === totalPages}
          >
            <NavigateNext />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full text-left flex flex-col gap-6">
      <h2>Trending This Week</h2>

      <div className="hidden md:flex flex-wrap gap-6 md:gap-8 w-full justify-center md:justify-start">
        {trendingBooks.slice((trendingPage - 1) * 8, trendingPage * 8).map((book) => (
          <BookCard key={book.id} book={book} variant="vertical" />
        ))}
      </div>
      <div className="md:hidden flex flex-wrap gap-6 md:gap-8 w-full justify-center md:justify-start">
        {trendingBooks.slice((trendingPage - 1) * 6, trendingPage * 6).map((book) => (
          <BookCard key={book.id} book={book} variant="vertical" />
        ))}
      </div>
      <div className="hidden md:block self-center mt-2">{trendingBooks.length > 0 && trendingPagination(8)}</div>
      <div className="md:hidden self-center">{trendingBooks.length > 0 && trendingPagination(6)}</div>
    </div>
  );
};

export default withFallback(TrendingBooks);
