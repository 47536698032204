import React, { useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Rating } from "@mui/material";
import RecommendationCard from "../cards/RecommendationCard";
import AddBookCard from "../cards/AddBookCard";
import Button from "../helpers/Button";
import api from "../../utils/api";
import { openModal, openSnackbar } from "../../app/slices/globalUISlice";
import CommunityCard from "../cards/CommunityCard";
import PostCard from "../forum/PostCard";
import ReviewCard from "../review/ReviewCard";
import { getMaterialIcon } from "../../utils/icons";
const StarRoundedIcon = getMaterialIcon("StarRounded");
const AddIcon = getMaterialIcon("Add");

const BookPageRecommendations = ({ setShowContent, refreshData }) => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { currentUserId, currentUser } = useSelector((state) => state.user);
  const { book: currentBook, user_book: currentUserBook } = queryClient.getQueryData(["book", uuid]);
  const scrollableBookDivRef = useRef();
  const scrollableQuestDivRef = useRef();
  const communities = currentBook && currentBook.clubs.slice(0, 3)
    .map((e, i) => [e, currentBook.lists[i], currentBook.quests[i]])
    .flat().filter(Boolean) || [];

  const onClickAddBook = () => {
    if (!currentUserId) return dispatch(openModal("loginPrompt"));

    const addRecommendation = (recommended_book) => {
      const params = { book_id: currentBook.id, recommended_book_id: recommended_book.id };
      api
        .post(`/recommendations`, { recommendation: params })
        .then(refreshData)
        .catch((error) => {
          console.error("Error creating recommendation:", error?.message);
          if (error.response.status === 422) {
            dispatch(openSnackbar({ message: "Recommendation already added!", error: error }));
          }
        });
    };
    const addedBookIds = currentBook?.recommendations?.map((r) => r.recommended_book.id);
    dispatch(openModal({ name: "searchReturnBook", data: { handleClick: addRecommendation, addedBookIds } }));
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <b>From the Forum</b>
          <div className="flex flex-col gap-4 bg-mainBlue box-border border border-offBlack shadow-main rounded-default p-4 md:p-6 w-full max-w-[800px]">
            {currentBook?.posts?.length > 0 ? (
              <div className="flex flex-col gap-4">
                <button
                  onClick={() => {
                    if (!currentUserId) return dispatch(openModal("loginPrompt"));
                    dispatch(
                      openModal({
                        name: "newPost",
                        data: {
                          forum: currentBook.forum,
                          isCurrentRead: currentUserBook?.status === "current",
                          onSubmit: refreshData,
                        },
                      })
                    );
                  }}
                >
                  <div className="flex items-center justify-center bg-white text-center font-bold font-hanken text-gray ml-0 text-left py-3 px-4 my-auto border border-offBlack rounded-[20px]">
                    <AddIcon /> Create post
                  </div>
                </button>
                {currentBook?.posts?.map((post) => (
                  <PostCard
                    postData={post}
                    key={post.id}
                    onClick={() =>
                      dispatch(
                        openModal({
                          name: "showPost",
                          data: { postId: post.id, postData: post },
                        })
                      )
                    }
                  />
                ))}
              </div>
            ) : (
              <div className="flex flex-col gap-3">
                <p className="text-left font-bold">No posts yet</p>
                <p className="text-left">Kick off the convo with a theory, question, musing, or update</p>
                <Button
                  color="bg-neonBlue self-end"
                  text="➕ Create Post"
                  onClick={() => {
                    if (!currentUserId) return dispatch(openModal("loginPrompt"));
                    dispatch(
                      openModal({
                        name: "newPost",
                        data: {
                          forum: currentBook.forum,
                          isCurrentRead: currentUserBook?.status === "current",
                          onSubmit: refreshData,
                        },
                      })
                    );
                  }}
                />
              </div>
            )}
            {currentBook?.posts?.length > 0 && (
              <Button color="bg-neonBlue self-end" text="View more posts" onClick={() => setShowContent("forum")} />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <b>Recent Reviews</b>
          <div className="flex flex-col gap-4 bg-reviewContainer box-border border border-offBlack shadow-main rounded-default p-4 md:p-6 w-full max-w-[800px]">
            {currentBook?.reviews?.length > 0 ? (
              <div className="flex flex-col gap-3">
                {!currentUserBook?.review && (
                  <button
                    onClick={() => {
                      if (currentUserId) return dispatch(openModal("loginPrompt"));
                      dispatch(
                        openModal({
                          name: "newReview",
                          data: {
                            userBook: currentUserBook,
                            handleReviewSubmit: refreshData,
                          },
                        })
                      );
                    }}
                  >
                    <div className="flex items-center justify-center bg-white text-center font-bold font-hanken text-gray ml-0 text-left py-3 px-4 my-auto border border-offBlack rounded-[20px]">
                      <Rating
                        name="rating"
                        onChange={() => {
                          if (!currentUserId) return dispatch(openModal("loginPrompt"));
                          dispatch(
                            openModal({
                              name: "newReview",
                              data: {
                                userBook: currentUserBook,
                                handleReviewSubmit: refreshData,
                              },
                            })
                          );
                        }}
                        value={Number(currentUserBook?.review?.overall_rating ?? 0)}
                        precision={0.5}
                        size="medium"
                        icon={<StarRoundedIcon className="stroke-offBlack text-yellow" fontSize="medium" />}
                        emptyIcon={<StarRoundedIcon className="stroke-gray text-transparent" fontSize="medium" />}
                      />
                    </div>
                  </button>
                )}
                {currentBook?.reviews?.map((review) => (
                  <ReviewCard review={review} key={review.id} isUserReview={review.user_id === currentUser?.uuid} />
                ))}
                <Button
                  color="bg-neonBlue self-end"
                  text="View more reviews"
                  onClick={() => setShowContent("reviews")}
                />
              </div>
            ) : (
              <div className="flex flex-col gap-3">
                <p className="text-left font-bold">No reviews yet</p>
                <p className="text-left">Share a rating or write a review</p>
                <Button
                  color="bg-neonBlue self-end"
                  text="➕ New Review"
                  onClick={() => {
                    if (!currentUserId) return dispatch(openModal("loginPrompt"));
                    dispatch(
                      openModal({
                        name: "newReview",
                        data: {
                          userBook: currentUserBook,
                          handleReviewSubmit: refreshData,
                        },
                      })
                    );
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2 h-fit my-3">
        <div className="flex gap-6 items-center mb-2">
          <b>Community recs for similar books</b>
          {currentBook?.recommendations.length >= 3 && (
            <div className="flex justify-center items-center">
              <Button color="bg-fuschia" text="Show all" onClick={() => navigate(`/books/${uuid}/recommendations`)} />
            </div>
          )}
        </div>
        <div
          ref={scrollableBookDivRef}
          className="flex gap-3 md:gap-6 px-2 md:px-2.5 pb-1 overflow-x-scroll md:w-[500px] lg:w-[660px] xl:w-[900px] customScrollbar"
        >
          {currentBook?.recommendations?.map((recommendation) => (
            <RecommendationCard recommendation={recommendation} key={recommendation.id} />
          ))}
          <div className="flex" key="add-book-card-container">
            <AddBookCard onClick={onClickAddBook} key="add-book-card" variant="vertical" />
          </div>
        </div>
      </div>

      {communities.length > 0 && (
        <div className="flex flex-col gap-2 h-fit mb-10 md:mb-16">
          <div className="flex gap-6 items-center mb-2">
            <b>Find this book in...</b>
            {communities.length > 3 && (
              <div className="flex justify-center items-center">
                <Button color="bg-fuschia" text="Show all" onClick={() => navigate(`/books/${uuid}/lists`)} />
              </div>
            )}
          </div>
          <div
            ref={scrollableQuestDivRef}
            className="flex gap-3 md:gap-6 md:px-2.5 pb-1 overflow-x-scroll md:w-[500px] lg:w-[660px] xl:w-[900px] customScrollbar"
          >
            {communities.slice(0, 3).map((community) => (
              <CommunityCard item={community} key={community.uuid} type={community.class_name} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BookPageRecommendations;
