import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Table, TableBody, TableHead, TableRow, TableCell } from "@mui/material";
import BookCard from "../../cards/BookCard";
import AddBookCard from "../../cards/AddBookCard";
import { removeClubBook, addClubBook } from "../../../app/slices/clubSlice";
import { openModal, setShowListView } from "../../../app/slices/globalUISlice";
import Tag from "../../helpers/Tag";
import IconButton from "../../helpers/IconButton";
import Button from "../../helpers/Button";
import api from "../../../utils/api";
import { getMaterialIcon } from "../../../utils/icons";
const AddCircleOutlineIcon = getMaterialIcon("AddCircleOutline");
const RemoveCircleIcon = getMaterialIcon("RemoveCircle");
const GridViewRoundedIcon = getMaterialIcon("GridViewRounded");
const FormatListBulletedRoundedIcon = getMaterialIcon("FormatListBulletedRounded");
const ExpandMoreOutlinedIcon = getMaterialIcon("ExpandMoreOutlined");
const EditIcon = getMaterialIcon("Edit");
const ArrowDropUpRoundedIcon = getMaterialIcon("ArrowDropUpRounded");
const ArrowDropDownRoundedIcon = getMaterialIcon("ArrowDropDownRounded");

const AllClubBooks = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const currentClub = queryClient.getQueryData(["club", uuid]);
  const { showListView } = useSelector((state) => state.globalUI);
  const [moreBooks, setMoreBooks] = useState(false);
  const [loading, setLoading] = useState(false);
  const [books, setBooks] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [sortBy, setSortBy] = useState({ attribute: "added_at", isAscending: false });
  const pageRef = useRef(1);

  useEffect(() => {
    pageRef.current = 1;
    api
      .get(`/clubs/${uuid}/recommendations?page=1${sortBy.attribute != null ? `&sort_by=${sortBy.attribute}&asc=${sortBy.isAscending}` : ''}`)
      .then((data) => {
        setBooks(data.books);
        setMoreBooks(data.total_pages > 1);
      });
  }, [uuid, sortBy]);

  const removeBookIcon = (book) => {
    return (
      <button
        type="button"
        onClick={() => {
          dispatch(
            removeClubBook({
              book_id: book.id,
              club_uuid: currentClub.uuid,
            })
          );
          setBooks(books.filter((b) => b.id !== book.id));
        }}
      >
        <RemoveCircleIcon fontSize="small" sx={{ color: "#FF695F" }} />
      </button>
    );
  };

  const handleMoreData = async () => {
    setLoading(true);
    pageRef.current += 1;
    api.get(`/clubs/${uuid}/recommendations?page=${pageRef.current}${sortBy.attribute != null ? `&sort_by=${sortBy.attribute}&asc=${sortBy.isAscending}` : ''}`)
      .then((data) => {
        setBooks([...books, ...data.books]);
        setMoreBooks(data.total_pages > pageRef.current);
      })
      .finally(() => setLoading(false));
  };

  const onClickAddBookCard = () => {
    const handleClick = (book) => {
      dispatch(
        addClubBook({
          book_id: book.id,
          club_uuid: currentClub.uuid,
        })
      )
        .unwrap()
        .then((data) => setBooks([data.book, ...books]));
    };
    const addedBookIds = books.map((b) => b.id);
    dispatch(openModal({ name: "searchReturnBook", data: { addedBookIds, handleClick } }));
  };

  const updateSorting = (col) => {
    if (sortBy.attribute === col) {
      setSortBy((prev) => ({ ...prev, isAscending: !prev.isAscending }));
    } else {
      setSortBy({ attribute: col, isAscending: true });
    };
  };

  const sortIcon = sortBy.isAscending
    ? <span className="relative"><ArrowDropUpRoundedIcon classes={{ root: "absolute -top-[7px] -left-[8px]" }} fontSize="large" color="primary" /></span>
    : <span  className="relative"><ArrowDropDownRoundedIcon classes={{ root: "absolute -top-[7px] -left-[8px]" }} fontSize="large" color="primary" /></span>;

  return (
    <div className="w-full flex flex-col text-left">
      <div className="flex flex-row mb-6 justify-between">
        <div className="flex flex-row gap-3 items-center">
          <h2>All recommended books</h2>
          {currentClub.is_moderator ? (
            isEditing ? (
              <Button onClick={() => setIsEditing(false)} text="Save" color="bg-neonBlue" />
            ) : (
              <IconButton
                IconComponent={EditIcon}
                onClick={() => setIsEditing(true)}
                tooltip="Edit"
              />
            )
          ) : (
            <Tag text={`${currentClub.user_overlap_percent || 0}% overlap`} color="bg-yellow border-2 border-orange" />
          )}
        </div>
        <div className="hidden md:flex flex-row justify-end gap-2">
          <IconButton
            IconComponent={GridViewRoundedIcon}
            onClick={() => dispatch(setShowListView(false))}
            isActive={!showListView}
            tooltip="Showcase view"
          />
          <IconButton
            IconComponent={FormatListBulletedRoundedIcon}
            onClick={() => dispatch(setShowListView(true))}
            isActive={showListView}
            tooltip="List view"
          />
        </div>
      </div>

      <div className="flex flex-wrap gap-6 justify-center md:justify-start">
        {books.length === 0 && currentClub.member_status !== "joined" && <p>No recommendations yet ☹️</p>}
        {showListView ? (
          <Table>
            <TableHead classes={{ root: "whitespace-nowrap" }}>
              <TableRow>
                <TableCell classes={{ root: "w-[90px]" }}>Cover</TableCell>
                <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("title")}>
                  Title {sortBy.attribute === "title" && sortIcon}
                </TableCell>
                <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("author")}>
                  Author {sortBy.attribute === "author" && sortIcon}
                </TableCell>
                <TableCell classes={{ root: "w-[500px]" }}>Description</TableCell>
                <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("avg_rating")}>
                  Avg Rating {sortBy.attribute === "avg_rating" && sortIcon}
                </TableCell>
                <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("added_at")}>
                  Added on {sortBy.attribute === "added_at" && sortIcon}
                </TableCell>
                <TableCell classes={{ root: "min-w-[24px]" }}>{/* status icon button */}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentClub.member_status === "joined" && (
                <TableRow>
                  <TableCell>
                    <div
                      className="flex justify-center items-center w-[65px] h-[100px] bg-white border shadow-main cursor-pointer"
                      onClick={onClickAddBookCard}
                    >
                      <AddCircleOutlineIcon fontSize="small" sx={{ color: "#333" }} />
                    </div>
                  </TableCell>
                  <TableCell>add book</TableCell>
                </TableRow>
              )}
              {books.map((book) => (
                <BookCard
                  book={book}
                  key={book.id}
                  variant="row"
                  icon={currentClub.is_moderator && isEditing ? removeBookIcon(book) : null}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <>
            {currentClub.member_status === "joined" && <AddBookCard onClick={onClickAddBookCard} key="add-book-card" />}
            {books.map((book) => (
              <BookCard
                key={book.id}
                book={book}
                icon={currentClub.is_moderator && isEditing ? removeBookIcon(book) : null}
              />
            ))}
          </>
        )}
      </div>
      {moreBooks && (
        <button className="bg-transparent p-2 self-center mt-2" disabled={loading} onClick={handleMoreData}>
          {loading ? (
            "Loading..."
          ) : (
            <>
              View More <ExpandMoreOutlinedIcon fontSize="small" />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default AllClubBooks;
