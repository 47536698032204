import React from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import api from "../../../utils/api";
import ReviewCard from "../../review/ReviewCard";
import ClubCard from "../../cards/ClubCard";
import QuestCard from "../../quests/QuestCard";
import ListCard from "../../cards/ListCard";
import ProfileHeader from "./ProfileHeader";
import ProfileShowcasedBooks from "./ProfileShowcasedBooks";
import OtherUserLibrary from "./OtherUserLibrary";
import YourReadingChallengeCard from "../../yearChallenge/YourReadingChallengeCard";
import HeadTags from "../../helpers/HeadTags";

const OtherUserProfile = ({ showLibrary }) => {
  const { username } = useParams();
  const { data: user, refetch: refetchUser } = useSuspenseQuery({
    queryKey: ["user", username],
    queryFn: () => api.get(`/users?username=${username}`)
      .then((data) => api.get(`/users/${data[0].id}`))
  });

  const handleFollow = () => {
    api.post(`/users/${user.id}/${user.is_following ? "unfollow" : "follow"}`)
      .then(refetchUser);
  };

  if (showLibrary) {
    return (
      <div className="py-8 md:py-16">
        <ProfileHeader user={user} handleFollow={handleFollow} />
        <OtherUserLibrary user={user} />
      </div>
    );
  }

  return (
    <div className="py-8 md:py-16">
      <HeadTags title={user?.username} description={user?.bio} image={user.image_url} />
      <ProfileHeader user={user} handleFollow={handleFollow} />
      <ProfileShowcasedBooks user={user} />
      <div className="mx-8 md:mx-auto md:w-2/3 max-w-[900px] text-left self-center">
        <div className="mt-12 md:mt-16 max-w-[900px]">
          <YourReadingChallengeCard user={user} />
        </div>
        {user.recent_reviews.length > 0 && (
          <div>
            <h2 className="mt-12 md:mt-16 mb-6">Recent Reviews</h2>
            <div className="flex flex-col gap-4 md:gap-8">
              {user.recent_reviews.map((r) => (
                <ReviewCard key={r.id} review={r} variant="with-image" />
              ))}
            </div>
          </div>
        )}
        {user.lists.length > 0 && (
          <div>
            <h2 className="mt-12 md:mt-16 mb-6">Lists</h2>
            <div className="flex flex-col md:flex-row gap-6 flex-wrap">
              {user.lists.map((l) => (
                <ListCard key={l.uuid} listData={l} />
              ))}
            </div>
          </div>
        )}
        {user.joined_clubs.length > 0 && (
          <div>
            <h2 className="mt-12 md:mt-16 mb-6">Clubs Joined</h2>
            <div className="flex flex-col md:flex-row gap-6 flex-wrap">
              {user.joined_clubs.map((c) => (
                <ClubCard key={c.uuid} club={c} variant="small" />
              ))}
            </div>
          </div>
        )}
        {user.challenges.length > 0 && (
          <div>
            <h2 className="mt-12 md:mt-16 mb-6">Quests Joined</h2>
            <div className="flex flex-col md:flex-row gap-6 flex-wrap">
              {user.challenges.map((q) => (
                <QuestCard key={q.uuid} quest={q} variant="large" />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OtherUserProfile;
