import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import DiscoverBooks from "./DiscoverBooks";
import DiscoverClubs from "./DiscoverClubs";
import DiscoverPeople from "./DiscoverPeople";
import DiscoverQuests from "./DiscoverQuests";
import Button from "../helpers/Button";
import HeadTags from "../helpers/HeadTags";

const Discover = () => {
  const navigate = useNavigate();
  const view = useParams().view || "books";
  const colorGradient = "/assets/colorGradientVF.png";

  return (
    <div className="container mx-auto m-auto pt-8 md:pt-[4em]">
      <HeadTags title="Discover" description="Explore popular & new books, Clubs, Quests, and people to find your Pagebound community" />
      <header
        style={{
          backgroundImage: `url(${colorGradient})`,
          backgroundSize: "100% 116%",
        }}
        className="flex flex-col w-full md:w-[90%] lg:w-[80%] pb-10 box-border m-auto gap-5 md:gap-8 mb-8 md:mb-16 text-center"
      >
        <h1>Discover</h1>
        <div className="flex flex-row gap-2 md:gap-5 m-auto">
          {["Books", "Clubs", "People", "Quests"].map((cta) => (
            <Button
              key={cta}
              type="navigation"
              onClick={() => navigate(`/discover/${cta.toLowerCase()}`)}
              isActive={view === cta.toLowerCase()}
              text={cta}
            />
          ))}
        </div>
      </header>
      <div className="w-10/12 lg:w-full m-auto">
        {view === "books" && <DiscoverBooks />}
        {view === "people" && <DiscoverPeople />}
        {view === "clubs" && <DiscoverClubs />}
        {view === "quests" && <DiscoverQuests />}
      </div>
    </div>
  );
};

export default Discover;
