import { useDispatch, useSelector } from "react-redux";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { signInCallback } from "../../app/slices/userSlice";

const LoginPrompt = () => {
  const dispatch = useDispatch();
  const { authError } = useSelector((state) => state.user);

  const handleLogin = useGoogleLogin({
    onSuccess: (result) => signInCallback(result, dispatch),
    flow: "auth-code",
  });

  return (
    <div className="relative m-auto w-full max-w-[900px] h-[200px] p-7 flex flex-col items-center justify-around gap-4 z-10">
      <div className="absolute w-full h-full blur-md rounded-default bg-gradient-to-r from-pastelOrange to-pastelPurple -z-10" />
      <b>To access this feature, please log in</b>
      <GoogleLogin
        onSuccess={(result) => signInCallback(result, dispatch)}
        shape="pill"
        text="signin_with"
        width="250"
        ux_mode="popup"
        useOneTap
        auto_select
      />
      {authError && <b className="text-sm text-red">Sorry! That didn't work. Can you try again?</b>}
      <div>
        Don't have an account?{" "}
        <b className="text-fuschia cursor-pointer" onClick={handleLogin}>
          Create one
        </b>
      </div>
    </div>
  );
};

export default LoginPrompt;
