import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TableRow, TableCell, Rating } from "@mui/material";
import { openModal } from "../../app/slices/globalUISlice";
import BookCover from "../helpers/BookCover";
import BookStatusButton from "../helpers/BookStatusButton";
import SaveIcon from "../../assets/SaveIcon";
import api from "../../utils/api";
import { formatDate } from "../../utils/utils";
import Button from "../helpers/Button";
import { BOOK_STATUS_BG_COLOR } from "../../constants";
import { getMaterialIcon } from "../../utils/icons";
const StarRoundedIcon = getMaterialIcon("StarRounded");

const BookCard = ({ book, icon, variant }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUserId } = useSelector((state) => state.user);
  const [isSaved, setIsSaved] = useState(book?.is_bookmarked || false);
  const [isLoading, setIsLoading] = useState(false);
  const [userBookData, setUserBookData] = useState(book?.user_book);

  const handleIconClick = async (event) => {
    event.stopPropagation();
    if (!currentUserId) {
      dispatch(openModal({ name: "loginPrompt" }));
    } else {
      setIsLoading(true);
      const params = { type: "Book", id: book.id };
      const action = isSaved ? api.destroy(`/bookmarks/destroy`, params) : api.post(`/bookmarks`, params);
      action.then(() => {
        setIsLoading(false);
        setIsSaved(!isSaved);
      });
    }
  };

  const renderIcons = () => {
    const saveIcon = (
      <button className="p-1 rounded-full hover:bg-yellow" onClick={handleIconClick} disabled={isLoading}>
        <SaveIcon fillColor={isSaved ? "#F7DB90" : "transparent"} />
      </button>
    );

    return (
      <div className="absolute top-2 md:top-4 right-2 md:right-[10px] flex flex-col justify-center items-center gap-2">
        {icon || saveIcon}
        <BookStatusButton bookId={book.id} userBook={userBookData} setUserBookData={setUserBookData} />
      </div>
    );
  };

  if (variant === "large") {
    return (
      <div
        onClick={() => navigate(`/books/${book.uuid}`)}
        className="relative flex w-full cursor-pointer max-w-[900px] p-4 gap-4 md:p-6 md:gap-6 text-left bg-white border border-offBlack shadow-main rounded-default"
      >
        <button
          className="absolute top-2 md:top-4 right-2 md:right-[10px] p-1 rounded-full hover:bg-yellow"
          onClick={handleIconClick}
          disabled={isLoading}
        >
          <SaveIcon fillColor={isSaved ? "#F7DB90" : "transparent"} />
        </button>
        <div className="flex flex-none">
          <BookCover book={book} size="w-[62px] h-24 md:w-[106px] md:h-[165px] border shadow-main" clickable={false} />
        </div>

        <div className="flex flex-col w-full gap-3 text-left justify-between md:pl-2">
          <div className="flex flex-col gap-1 w-full">
            <p className="font-bold leading-none overflow-wrap">{book.title}</p>
            <p className="leading-tight text-[11px] md:text-[14px] truncate">{book.author_name}</p>
            <div className="hidden md:block">
              <div className="leading-tight text-[12px] text-ellipsis line-clamp-4 overflow-hidden mt-3">
                {book.description}
              </div>
            </div>
          </div>
          <BookStatusButton
            userBook={userBookData}
            bookId={book.id}
            setUserBookData={setUserBookData}
            variant="button"
          />
        </div>
      </div>
    );
  }

  if (variant === "vertical") {
    return (
      <div
        className={`relative flex flex-col flex-none box-border w-fit h-fit py-[22px] px-[32px] md:py-[30px] md:px-[45px] ${
          BOOK_STATUS_BG_COLOR[userBookData?.status || "set-status"]
        } items-center shadow-main rounded-default border border-offBlack`}
      >
        <div className="absolute top-4 justify-center items-center md:top-6 right-2 md:right-[10px] flex flex-col gap-2">
          {icon || (
            <button className="p-1 rounded-full hover:bg-yellow" onClick={handleIconClick} disabled={isLoading}>
              <SaveIcon fillColor={isSaved ? "#F7DB90" : "transparent"} />
            </button>
          )}
          <BookStatusButton userBook={userBookData} bookId={book.id} setUserBookData={setUserBookData} />
        </div>
        <div className="w-[68px] h-[104px] md:w-[104px] md:h-40 cursor-pointer">
          <BookCover book={book} size="w-full h-full border shadow-main" showTitle={true} />
        </div>
      </div>
    );
  }

  if (variant === "homeFeed") {
    return (
      <div
        className={`relative flex flex-col box-border p-4 md:p-6 gap-6 ${
          BOOK_STATUS_BG_COLOR[userBookData?.status || "set-status"]
        } items-center shadow-main rounded-default border border-offBlack`}
      >
        <div className="absolute top-2 justify-center items-center md:top-4 right-2 md:right-[10px] flex flex-col gap-2">
          <BookStatusButton userBook={userBookData} bookId={book.id} setUserBookData={setUserBookData} />
        </div>
        <div className="w-[62px] h-24 md:w-[104px] md:h-40 cursor-pointer">
          <BookCover book={book} showTitle={true} size="w-full h-full border shadow-main" />
        </div>

        <Button
          size="medium"
          text="Post an update"
          color="bg-neonBlue hidden md:block"
          onClick={() => dispatch(openModal({ name: "newPost", data: { forum: book.forum, isCurrentRead: true } }))}
        />
        <Button
          size="medium"
          text="Post update"
          color="bg-neonBlue md:hidden"
          onClick={() => dispatch(openModal({ name: "newPost", data: { forum: book.forum, isCurrentRead: true } }))}
        />
      </div>
    );
  }

  if (variant === "row") {
    return (
      <TableRow key={book.uuid}>
        <TableCell>
          <BookCover book={book} clickable size="w-[65px] h-[100px] border shadow-main" />
        </TableCell>
        <TableCell>
          <Link to={`/books/${book.uuid}`}>{book.title}</Link>
        </TableCell>
        <TableCell>
          <Link to={`/authors/${book.author_uuid}`}>{book.author_name}</Link>
        </TableCell>
        <TableCell>
          <div className="line-clamp-3 text-sm">{book.description}</div>
        </TableCell>
        <TableCell>
          {book.aggregate_ratings ? (
            <Rating
              value={Number(book.aggregate_ratings ?? 0)}
              precision={0.1}
              size="small"
              readOnly
              icon={<StarRoundedIcon className="stroke-offBlack text-yellow" fontSize="small" />}
              emptyIcon={<StarRoundedIcon className="stroke-offBlack text-white" fontSize="small" />}
            />
          ) : (
            "None yet!"
          )}
        </TableCell>
        <TableCell align="center">{formatDate(book.added_at)}</TableCell>
        <TableCell>
          <div className="flex flex-col gap-2">
            {icon || <BookStatusButton userBook={userBookData} setUserBookData={setUserBookData} bookId={book.id} />}
          </div>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div
      className={`relative flex md:flex-col w-full max-w-[330px] h-[130px] md:w-48 md:h-[290px] gap-4 md:gap-0 box-border p-4 md:px-4 md:pb-4 md:pt-6 ${
        BOOK_STATUS_BG_COLOR[userBookData?.status || "set-status"]
      } items-start md:items-center shadow-main rounded-default border border-offBlack`}
    >
      <div className="absolute md:hidden top-2 right-2">
        {icon || (
          <button className="p-1 rounded-full hover:bg-yellow" onClick={handleIconClick} disabled={isLoading}>
            <SaveIcon fillColor={isSaved ? "#F7DB90" : "transparent"} />
          </button>
        )}
      </div>
      <div className="hidden md:block">{renderIcons()}</div>
      <div className="w-[62px] h-24 md:w-[104px] md:h-40 cursor-pointer flex-none">
        <BookCover book={book} size="w-[62px] h-24 md:w-[104px] md:h-40 border shadow-main" />
      </div>
      <div className="flex flex-col items-start justify-between h-full md:justify-center md:items-center max-w-full pr-4 md:pr-0">
        <button
          className="flex flex-col gap-1 text-left md:text-center"
          onClick={() => navigate(`/books/${book.uuid}`)}
        >
          <p className="font-bold md:text-center leading-tight text-[14px] text-ellipsis line-clamp-3 w-full">
            {book.title}
          </p>
          <p className="leading-tight text-[12px] md:text-[14px] w-full md:text-center">{book.author_name}</p>
        </button>
        <div className="md:hidden justify-self-end">
          <BookStatusButton
            userBook={userBookData}
            bookId={book.id}
            setUserBookData={setUserBookData}
            variant="button"
          />
        </div>
      </div>
    </div>
  );
};

export default BookCard;
