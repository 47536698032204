import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import Modal from "./Modal";
import { signInCallback } from "../../app/slices/userSlice";

const LoginPromptModal = () => {
  const dispatch = useDispatch();
  const { currentUserId, authError } = useSelector((state) => state.user);

  const handleLogin = useGoogleLogin({
    onSuccess: (result) => signInCallback(result, dispatch),
    flow: "auth-code",
  });

  if (currentUserId) return null;

  return (
    <Modal isOpen>
      <div className="px-7 flex flex-col items-center gap-4">
        <b>To access this feature, please log in</b>
        <GoogleLogin
          onSuccess={(result) => signInCallback(result, dispatch)}
          shape="pill"
          text="signin_with"
          width="250"
          ux_mode="popup"
          useOneTap
          auto_select
        />
        {authError && <b className="text-sm text-red">Sorry! That didn't work. Can you try again?</b>}
        <div>
          Don't have an account?{" "}
          <b className="text-fuschia cursor-pointer" onClick={handleLogin}>
            Create one
          </b>
        </div>
      </div>
    </Modal>
  );
};

export default LoginPromptModal;
