import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import x from "../../assets/graphic-x.svg";
import { closeModal } from "../../app/slices/globalUISlice";

const Modal = ({ isOpen, onClose, classNames, headerActions = [], title, children, showActions = true }) => {
  const dispatch = useDispatch();
  const { modalIsDirty } = useSelector((state) => state.globalUI);

  useEffect(() => {
    const applyOverflow = () => {
      if (isOpen) {
        // Prevent background scrolling
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "visible";
      }
    };

    // Apply overflow after a short delay to ensure it takes effect
    const timeoutId = setTimeout(applyOverflow, 100);

    return () => {
      clearTimeout(timeoutId);
      document.body.style.overflow = "visible";
    };
  }, [isOpen]);

  const handleClose = () => {
    if (!modalIsDirty || confirm("You have unsaved changes! Are you sure you want to exit?")) {
      document.body.style.overflow = "visible";
      dispatch(closeModal());
      onClose && onClose();
    }
  };

  const container = classNames?.container || "w-fit";
  const actions = [...headerActions, { icon: x, onClick: handleClose }];

  if (!isOpen) return <></>;

  return (
    <div
      className={clsx(
        "fixed z-50 inset-0 overflow-y-auto bg-offBlack bg-opacity-35 flex justify-center",
        classNames?.root
      )}
      onClick={handleClose}
    >
      <div
        className={clsx(
          "relative bg-white border h-fit w-fit max-w-[90%] md:max-w-[70%] max-h-[80%] overflow-y-scroll border-offBlack shadow-main rounded-default",
          container,
          classNames?.margin || "mt-[30%] md:mt-[10%]"
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="p-4 md:p-6 text-left w-full">
          <div className={clsx("flex w-full mb-2 gap-1", title ? "justify-between" : "justify-end")}>
            {title && <b>{title}</b>}
            {showActions && (
              <div className="flex gap-1 justify-end items-start">
                {actions.map(({ icon, onClick }, i) => (
                  <button key={i} onClick={onClick}>
                    <img src={icon} className="h-5 w-5" />
                  </button>
                ))}
              </div>
            )}
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
