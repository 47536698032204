import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import pluralize from "pluralize";
import { Table, TableBody, TableHead, TableRow, TableCell } from "@mui/material";
import { openModal } from "../../../app/slices/globalUISlice";
import LibraryBookCard from "./LibraryBookCard";
import LibraryAddBookCard from "./LibraryAddBookCard";
import api from "../../../utils/api";
import Loader from "../../helpers/Loader";
import { BOOK_STATUS_LABEL_MAP } from "../../../constants";
import Tag from "../../helpers/Tag";
import { getMaterialIcon } from "../../../utils/icons";
const ExpandMoreOutlinedIcon = getMaterialIcon("ExpandMoreOutlined");
const ArrowDropUpRoundedIcon = getMaterialIcon("ArrowDropUpRounded");
const ArrowDropDownRoundedIcon = getMaterialIcon("ArrowDropDownRounded");

const StatusView = ({ currentStatus, setShowLibraryList, showListView }) => {
  const dispatch = useDispatch();
  const [statusBooks, setStatusBooks] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [moreData, setMoreData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState({ attribute: "added_at", isAscending: false });
  const pageRef = useRef(1);

  useEffect(() => {
    if (!currentStatus) return;
    pageRef.current = 1;
    api.get(`/user_books?status=${currentStatus}&page=1${sortBy.attribute != null ? `&sort_by=${sortBy.attribute}&asc=${sortBy.isAscending}` : ''}`)
      .then((data) => {
        setStatusBooks(data.user_books);
        setMoreData(data.total_pages > 1);
        setTotalCount(data.total_count);
      });
  }, [currentStatus, sortBy]);

  const handleMoreData = () => {
    setLoading(true);
    pageRef.current += 1;
    api
      .get(`/user_books?status=${currentStatus}&page=${pageRef.current}${sortBy.attribute != null ? `&sort_by=${sortBy.attribute}&asc=${sortBy.isAscending}` : ''}`)
      .then((data) => {
        setStatusBooks((prevBooks) => [...prevBooks, ...data.user_books]);
        setMoreData(data.total_pages > pageRef.current);
      })
      .finally(() => setLoading(false));
  };

  const addBook = (book) => {
    api.post("/user_books", { book_id: book.id, status: currentStatus }).then((data) => {
      setStatusBooks((prevBooks) => [data, ...prevBooks]);
      setTotalCount((prevCount) => prevCount + 1);
    });
  };

  const onClickAddBook = () => {
    const addedBookIds = statusBooks?.map((userBook) => userBook.book_id);
    dispatch(openModal({ name: "searchReturnBook", data: { addedBookIds, handleClick: addBook } }));
  };

  const updateSorting = (col) => {
    if (sortBy.attribute === col) {
      setSortBy((prev) => ({ ...prev, isAscending: !prev.isAscending }));
    } else {
      setSortBy({ attribute: col, isAscending: true });
    };
  };

  const sortIcon = sortBy.isAscending
    ? <span className="relative"><ArrowDropUpRoundedIcon classes={{ root: "absolute -top-[7px] -left-[9px]" }} fontSize="large" color="primary" /></span>
    : <span  className="relative"><ArrowDropDownRoundedIcon classes={{ root: "absolute -top-[7px] -left-[9px]" }} fontSize="large" color="primary" /></span>;

  return (
    <div className="w-full min-w-[350px] lg:w-[652px] xl:w-[856px] flex flex-col flex-wrap gap-2 bg-gradient-to-b from-pastelBlue to-pastelPink box-border p-6 md:p-8 rounded-default items-start text-left">
      <div className="flex flex-row gap-4 md:items-center mr-2">
        <button className="lg:hidden flex items-center gap-1 text-left" onClick={() => setShowLibraryList(true)}>
          <h2>{BOOK_STATUS_LABEL_MAP[currentStatus]}</h2>
          <ExpandMoreOutlinedIcon fontSize="small" />
        </button>
        <h2 className="hidden lg:inline-block">{BOOK_STATUS_LABEL_MAP[currentStatus]}</h2>
        <div className="flex flex-row gap-2">
          {totalCount !== null && <Tag text={pluralize("books", totalCount, true)} color="bg-pastelGreen" />}
        </div>
      </div>

      {statusBooks === null ? <Loader /> : (
        showListView ? (
          <Table>
            <TableHead classes={{ root: "whitespace-nowrap" }}>
              <TableRow>
                <TableCell classes={{ root: "w-[90px]" }}>Cover</TableCell>
                <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("title")}>
                  Title {sortBy.attribute === "title" && sortIcon}
                </TableCell>
                <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("author")}>
                  Author {sortBy.attribute === "author" && sortIcon}
                </TableCell>
                <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("rating")}>
                  My Rating {sortBy.attribute === "rating" && sortIcon}
                </TableCell>
                <TableCell classes={{ root: "min-w-[85px] cursor-pointer" }} onClick={() => updateSorting("read_at")}>
                  Read on {sortBy.attribute === "read_at" && sortIcon}
                </TableCell>
                <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("added_at")}>
                  Added on {sortBy.attribute === "added_at" && sortIcon}
                </TableCell>
                <TableCell classes={{ root: "min-w-[24px]" }}>{/* status icon button */}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statusBooks?.map((userBook) => (
                <LibraryBookCard userBook={userBook} key={userBook.id} view="row" />
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className="w-full flex flex-col md:grid md:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6 mt-4 items-center justify-center">
            <LibraryAddBookCard onClick={onClickAddBook} />
            {statusBooks?.map((userBook) => (
              <LibraryBookCard userBook={userBook} key={userBook.id} />
            ))}
          </div>
        )
      )}

      {statusBooks?.length > 0 && moreData && (
        <button className="bg-transparent p-2 self-center mt-2" disabled={loading} onClick={handleMoreData}>
          {loading ? (
            "Loading..."
          ) : (
            <>
              View More <ExpandMoreOutlinedIcon fontSize="small" />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default StatusView;
