import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Collapsible from "react-collapsible";
import clsx from "clsx";
import { MONTHS } from "../../../constants";
import MonthSchedule from "./MonthSchedule";
import YearPlan from "./YearPlan";
import Layout from "../../layout/Layout";
import { fetchScheduledBooks } from "../../../app/slices/userBookSlice";
import LibraryBookCard from "../library/LibraryBookCard";
import YourReadingChallengeCard from "../../yearChallenge/YourReadingChallengeCard";
import sparkle from "../../../assets/sparkle.svg";
import { capitalize } from "../../../utils/utils";
import HeadTags from "../../helpers/HeadTags";

const Plan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentView = useParams().view?.toLowerCase() || "monthly";
  const { scheduledBooks } = useSelector((state) => state.userBook);
  const { currentUserId, currentUser } = useSelector((state) => state.user);
  const [unscheduledYearBooks, setUnscheduledYearBooks] = useState([]);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const PLAN_YEARS = [currentYear, currentYear + 1];
  const PREV_MONTHS = MONTHS.slice(0, currentMonth);
  const CURRENT_MONTHS = MONTHS.slice(currentMonth);
  const hasPrevMonths =
    scheduledBooks?.filter(
      (b) => PREV_MONTHS.includes(MONTHS[b.scheduled_for_month]) && b.scheduled_for_year === currentYear
    ).length > 0;
  const chevron =
    "relative after:transform after:transition-all after:font-sans after:content-['›'] after:absolute after:top-0 after:left-[146px] md:after:left-[166px]";

  useEffect(() => {
    dispatch(fetchScheduledBooks());
  }, []);

  useEffect(() => {
    setUnscheduledYearBooks(
      scheduledBooks.filter((book) => !book.scheduled_for_month && book.scheduled_for_year == currentYear)
    );
  }, [scheduledBooks]);

  const renderPrevMonth = (month, index) => {
    const userBooks = scheduledBooks.filter(
      (book) => MONTHS[book.scheduled_for_month] === month && book.scheduled_for_year === currentYear
    );
    if (userBooks.length === 0) return null;

    return (
      <div key={index} className="flex flex-col flex-wrap w-full gap-4 box-border items-start pb-1">
        <div className="flex flex-col w-full gap-3">
          <h3 className="font-bold text-left">{`${month} ${currentYear}`}</h3>
          <div className="flex flex-wrap gap-3">
            {userBooks.map((book) => (
              <LibraryBookCard userBook={book} key={book.uuid} />
            ))}
          </div>
        </div>
      </div>
    );
  };

  const ctas = [
    { text: "Books", onClick: () => navigate("/library") },
    { text: "Plan", isActive: true },
    { text: "Bookmarks", onClick: () => navigate("/library/bookmarks") },
  ];

  return (
    <Layout
      title="Library"
      description="Schedule your TBR or keep track of new releases."
      ctas={ctas}
      showUnauth={!currentUserId}
    >
      <HeadTags title="Plan" description="Schedule your TBR or keep track of new releases." />
      <div className="container max-w-[1280px] mx-auto flex flex-col md:flex-row gap-12 justify-center box-border px-3 mb-[100px]">
        <div className="w-fit flex-none">
          <ul className="w-[250px] flex flex-col flex-none gap-1.5 justify-start text-left">
            {["monthly", "yearly"].map((view) => (
              <li
                key={view}
                onClick={() => navigate(`/library/plan/${view}`)}
                className={clsx(
                  "flex gap-1.5 font-space font-bold hover:text-pink cursor-pointer truncate",
                  currentView == view ? "text-offBlack" : "text-gray"
                )}
              >
                <img src={sparkle} alt="sparkle" className={currentView == view ? "visible" : "invisible"} />
                {capitalize(view)}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col lg:w-[670px] xl:w-[870px] gap-8 md:gap-12 box-border w-full">
          {currentView === "monthly" ? (
            <>
              {hasPrevMonths && currentMonth > 0 && (
                <div className="w-full">
                  <Collapsible
                    trigger={<p className="font-bold hover:text-pink">Previous Month Plans</p>}
                    triggerClassName={`${chevron} after:rotate-90`}
                    triggerOpenedClassName={`${chevron} after:-rotate-90`}
                    transitionTime={200}
                    className="flex flex-col w-full items-start"
                    openedClassName="flex flex-col w-full items-start gap-4"
                    contentOuterClassName="w-full"
                    contentInnerClassName="flex flex-col flex-wrap w-full gap-10 items-start"
                  >
                    {PREV_MONTHS.map((month, index) => renderPrevMonth(month, index))}
                  </Collapsible>
                </div>
              )}
              {CURRENT_MONTHS.map((month, index) => {
                return <MonthSchedule month={month} year={currentYear} key={index} />;
              })}
              {unscheduledYearBooks.length > 0 && (
                <div className="flex flex-col w-full bg-mainBlue bg-gradient-to-b from-pastelBlue to-pastelOrange p-5 md:p-8 rounded-default items-start gap-5">
                  <h3 className="font-bold">Unscheduled from your Year Plan</h3>
                  <div className="flex flex-wrap gap-5">
                    {unscheduledYearBooks.map((book) => (
                      <LibraryBookCard userBook={book} key={book.uuid} />
                    ))}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <YourReadingChallengeCard user={currentUser} />
              {PLAN_YEARS.map((year, index) => (
                <YearPlan year={year} key={index} />
              ))}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Plan;
