import React from "react";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import api from "../../utils/api";
import ListCard from "../cards/ListCard";
import Button from "../helpers/Button";
import ViewMoreButton from "../helpers/ViewMoreButton";

const PopularLists = () => {
  const popularListsQuery = useSuspenseInfiniteQuery({
    queryKey: ["popularLists"],
    queryFn: ({ pageParam }) => api.get(`/lists?type=popular&page=${pageParam}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage.length < 5 ? undefined : pages.length + 1,
  });

  const popularLists = popularListsQuery.data.pages.flat();

  return (
    <div className="flex flex-col gap-6 w-full">
      <div className="flex gap-4 items-center">
        <h2 className="text-left">Popular Lists</h2>
        <Button text="Browse all" type="link" to="/lists/browse" color="bg-fuschia" />
      </div>

      {popularLists.map((list) => (
        <ListCard key={list.id} listData={list} />
      ))}

      <ViewMoreButton {...popularListsQuery} />
    </div>
  );
};

export default withFallback(PopularLists);
