import { configureStore } from "@reduxjs/toolkit";
import createFilter from "redux-persist-transform-filter";
import { persistStore, persistCombineReducers } from "redux-persist";
import { CookieStorage } from "redux-persist-cookie-storage";
import Cookies from "js-cookie";
import reducers from "./rootReducer";

let reduxStore = null;
let reduxPersistor = null;

export const getStore = (preloadedState = {}) => {
  if (reduxStore) return { reduxStore, reduxPersistor };

  const userSubsetFilter = createFilter("user", [
    "currentUserId",
    "token",
    "currentUser.uuid",
    "currentUser.username",
    "currentUser.image_url",
    "currentUser.onboarding_completed",
  ]);
  const globalUISubsetFilter = createFilter("globalUI", ["showListView"]);
  const persistConfig = {
    key: "root",
    storage: new CookieStorage(Cookies, {
      expiration: {
        'default': 365 * 86400 // Cookies expire after one year
      },
    }),
    whitelist: ["user", "onboarding", "globalUI"],
    transforms: [userSubsetFilter, globalUISubsetFilter],
    version: 1,
    migrate: (state) => Promise.resolve(state),
    stateReconciler(inboundState, originalState) {
      // Ignore state from cookies, only use preloadedState provided
      return originalState;
    },
  };
  const rootReducer = persistCombineReducers(persistConfig, reducers);

  reduxStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ["persist/PERSIST", "persist/REHYDRATE", "persist/REGISTER", "globalUI/openModal", "globalUI/openSnackbar"],
          ignoredPaths: ["globalUI.modalData", "globalUI.snackbarData"],
        },
      }),
    preloadedState: preloadedState,
  });

  reduxPersistor = persistStore(reduxStore);

  return { reduxStore, reduxPersistor };
};
