import AddBookFromGoodreadsModal from "./AddBookFromGoodreadsModal";
import BookSocialActivityModal from "./BookSocialActivityModal";
import ChangeBookStatusModal from "./ChangeBookStatusModal";
import ConfirmationModal from "./ConfirmationModal";
import GoodreadsDirectionsModal from "./GoodreadsDirectionsModal";
import LoginPromptModal from "./LoginPromptModal";
import NewReviewModal from "./NewReviewModal";
import SearchReturnBookModal from "./SearchReturnBookModal";
import SearchTBRModal from "./SearchTBRModal";
import ShowPostModal from "./ShowPostModal";
import ShowReviewModal from "./ShowReviewModal";
import ViewMembersModal from "./ViewMembersModal";
import InfoModal from "./InfoModal";
import NewPostModal from "./NewPostModal";
import ShowActivityItemModal from "./ShowActivityItemModal";

const MODAL_CONFIG = {
  addBookFromGoodreads: AddBookFromGoodreadsModal,
  bookSocialActivity: BookSocialActivityModal,
  changeBookStatus: ChangeBookStatusModal,
  confirmation: ConfirmationModal,
  goodreadsDirections: GoodreadsDirectionsModal,
  loginPrompt: LoginPromptModal,
  newReview: NewReviewModal,
  searchReturnBook: SearchReturnBookModal,
  searchTBR: SearchTBRModal,
  showPost: ShowPostModal,
  showReview: ShowReviewModal,
  viewMembers: ViewMembersModal,
  info: InfoModal,
  newPost: NewPostModal,
  showActivityItem: ShowActivityItemModal,
};

export default MODAL_CONFIG;
