import React, { useEffect, useState, useRef } from "react";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import { useParams, Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { fetchBook, fetchGoodreadsData } from "../../app/slices/bookSlice";
import { setCurrentUserBook } from "../../app/slices/userBookSlice";
import { openModal } from "../../app/slices/globalUISlice";
import amazonLogo from "../../assets/amazon.svg";
import bookshopLogo from "../../assets/bookshop.svg";
import goodreadsLogo from "../../assets/goodreads.svg";
import BookPageRecommendations from "./BookPageRecommendations";
import BookPageDetails from "./BookPageDetails";
import BookPageSocialActivity from "./BookPageSocialActivity";
import BookStatusButton from "../helpers/BookStatusButton";
import Forum from "../forum/Forum";
import BookReviews from "../review/BookReviews";
import Button from "../helpers/Button";
import BookCover from "../helpers/BookCover";
import HeadTags from "../helpers/HeadTags";
import { debounce } from "../../utils/utils";
import api from "../../utils/api";
import SaveIcon from "../../assets/SaveIcon";
import { getMaterialIcon } from "../../utils/icons";
const ExpandMoreIcon = getMaterialIcon("ExpandMore");
const ExpandLessIcon = getMaterialIcon("ExpandLess");

const BookPage = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { currentUserId } = useSelector((state) => state.user);
  const [showContent, setShowContent] = useState("bookRecs");
  const [isDescExpanded, setIsDescExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);
  const reviewButtonRef = useRef(null);

  const { data: { book: currentBook, user_book: currentUserBook }, refetch: refetchBook } = useSuspenseQuery({
    queryKey: ["book", uuid],
    queryFn: () => dispatch(fetchBook(uuid)).unwrap()
  });

  const userBookStatus = currentUserBook?.status || "none"
  const canWriteReview = currentUserBook?.has_ever_finished || userBookStatus === "dnf" || userBookStatus === "finished";

  useEffect(() => {
    const checkOverflow = () => {
      const el = textRef.current;
      if (el) setIsOverflowing(el.scrollHeight > el.clientHeight);
    };

    if (!isOverflowing) {
      debounce(checkOverflow, 1500)();
    }
  }, [currentBook?.description]);

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.slice(1);
      if (elementId) {
        const element = document.getElementById(elementId);
        if (element) element.scrollIntoView();
      }
    }
  }, [location]);

  const handleBookmark = () => {
    if (!currentUserId) return dispatch(openModal("loginPrompt"));

    const params = { type: "Book", id: currentBook.id };
    const action = currentBook.is_bookmarked ? api.destroy(`/bookmarks/destroy`, params) : api.post(`/bookmarks`, params);
    action.then(refetchBook);
  };

  const setUserBookData = (userBook) => {
    dispatch(setCurrentUserBook(userBook));
    queryClient.setQueryData(["book", uuid], (oldData) => ({ book: oldData.book, user_book: userBook }));
  };

  return (
    <div className="container relative flex flex-col box-border lg:flex-row gap-8 lg:gap-16 lg:mt-20 mb-[100px]">
      <HeadTags title={currentBook?.title} description={currentBook?.description} image={currentBook?.image_url} />
      <div className="flex px-4 pb-4 pt-8 lg:p-0 border-b bg-gradient-to-r from-pastelBlue to-pastelPink lg:border-none w-[100vw] lg:flex-col lg:fixed lg:top-[65px] lg:left-0 lg:pt-[80px] lg:h-[100vh] lg:w-[305px] lg:bg-bookpage-squiggle lg:bg-cover flex-none gap-4 lg:gap-8 justify-start items-start lg:items-center z-10">
        <div className="flex flex-col gap-4 items-center">
          <div className="w-[110px] h-[170px] lg:w-[207px] lg:h-80 flex-none">
            <BookCover
              book={currentBook}
              clickable={false}
              size="w-[110px] h-[170px] lg:w-[207px] lg:h-80 border shadow-main"
            />
          </div>
          {!currentBook.image_url && currentBook.goodreads_id && (
            <Button
              text="Fetch Bookcover"
              onClick={() => dispatch(fetchGoodreadsData(currentBook.uuid))}
              color="bg-orange"
            />
          )}
        </div>
        <div className="lg:hidden flex flex-col gap-1 justify-center text-left">
          <BookPageDetails setShowContent={setShowContent} reviewButtonRef={reviewButtonRef} />
          <div className="lg:hidden flex flex-row gap-6">
            <BookStatusButton
              userBook={currentUserBook}
              bookId={currentBook.id}
              variant="button"
              setUserBookData={setUserBookData}
            />

            <button onClick={handleBookmark}>
              <SaveIcon fillColor={currentBook?.is_bookmarked ? "#F7DB90" : "transparent"} width="18" height="26" />
            </button>
          </div>
        </div>
        <div className="hidden lg:flex flex-col gap-8 items-center">
          <div className="flex flex-row gap-6">
            <BookStatusButton
              userBook={currentUserBook}
              bookId={currentBook.id}
              variant="button"
              setUserBookData={setUserBookData}
            />
            <button onClick={handleBookmark}>
              <SaveIcon fillColor={currentBook?.is_bookmarked ? "#F7DB90" : "transparent"} width="23" height="32" />
            </button>
          </div>
          {canWriteReview ? (
            <Button
              color="bg-fuschia"
              size="medium"
              text={currentUserBook?.review ? "See Review" : "Write a review"}
              onClick={() => {
                setShowContent("reviews");
                if (reviewButtonRef.current) {
                  const yOffset = -40;
                  const y = reviewButtonRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
                  window.scrollTo({ top: y, behavior: "smooth" });
                }
              }}
            />
          ) : (
            <Tooltip title="You must finish or DNF this book to write a review" disableFocusListener>
              <div className="w-fit h-fit leading-none whitespace-nowrap text-sm px-4 py-1.5 md:px-6 md:py-2 font-bold font-space rounded-full cursor-pointer border bg-offWhite border-gray text-gray">
                Write a review
              </div>
            </Tooltip>
          )}
        </div>

        <div className="hidden lg:flex flex-row gap-2 justify-center">
          <Link to={currentBook?.goodreads_url} target="_blank">
            <img src={goodreadsLogo} alt="goodreads" className="w-6 h-6" />
          </Link>
          <Link
            to={`https://bookshop.org/search?keywords=${currentBook?.title.split(" ").join("+")}`}
            target="_blank"
          >
            <img src={bookshopLogo} alt="bookshop" className="w-6 h-6" />
          </Link>
          <Link to={currentBook?.amazon_url || "https://www.amazon.com"} target="_blank">
            <img src={amazonLogo} alt="amazon" className="w-6 h-6" />
          </Link>
        </div>
      </div>
      <div className="flex flex-col p-4 grow lg:ml-[335px] w-full max-w-[800px] justify-center lg:justify-start text-left z-0">
        <div className="flex flex-col gap-2.5">
          <div className="hidden lg:block relative">
            <BookPageDetails setShowContent={setShowContent} reviewButtonRef={reviewButtonRef} />
          </div>
          <div className="container mx-auto">
            <p
              ref={textRef}
              className={`overflow-hidden text-left text-[14px] leading-normal whitespace-pre-wrap ${
                !isDescExpanded && "text-ellipsis line-clamp-4 md:line-clamp-5 overflow-hidden"
              }`}
            >
              {currentBook?.description}
            </p>
            {isOverflowing && currentBook?.description?.length > 0 && (
              <button
                onClick={() => setIsDescExpanded(!isDescExpanded)}
                className="text-gray text-xs md:text-sm mt-2"
              >
                {isDescExpanded ? (
                  <>
                    Read Less <ExpandLessIcon fontSize="small" />
                  </>
                ) : (
                  <>
                    Read More <ExpandMoreIcon fontSize="small" />
                  </>
                )}
              </button>
            )}
            <BookPageSocialActivity />
          </div>
          <hr className="text-gray my-4 md:my-6" ref={reviewButtonRef} />
          <div className="flex flex-row gap-3 w-full justify-center">
            <Button
              type="navigation"
              text="Community"
              onClick={() => setShowContent("bookRecs")}
              isActive={showContent === "bookRecs"}
            />
            <Button
              type="navigation"
              text="Forum"
              onClick={() => setShowContent("forum")}
              isActive={showContent === "forum"}
            />
            <Button
              type="navigation"
              text="Reviews"
              onClick={() => setShowContent("reviews")}
              isActive={showContent === "reviews"}
            />
          </div>
        </div>

        <div id="forum-reviews" className="flex flex-col mt-6 w-full max-w-[800px]">
          {showContent == "bookRecs" && <BookPageRecommendations setShowContent={setShowContent} refreshData={refetchBook}/>}
          {showContent == "forum" && <Forum resource={currentBook} resourceType="Book" />}
          {showContent == "reviews" && <BookReviews userBookStatus={userBookStatus} />}
        </div>
      </div>
    </div>
  );
};

export default withFallback(BookPage, undefined, () => (<h2>Oops! We couldn't find that.</h2>));
