import React, { useState } from "react";
import { useDispatch } from "react-redux";
import BookCover from "../../helpers/BookCover";
import Button from "../../helpers/Button";
import Tag from "../../helpers/Tag";
import Tooltip from "@mui/material/Tooltip";
import { openModal } from "../../../app/slices/globalUISlice";
import { updateShowcasedBooks } from "../../../app/slices/userSlice";
import IconButton from "../../helpers/IconButton";
import { getMaterialIcon } from "../../../utils/icons";
const EditIcon = getMaterialIcon("Edit");
const RemoveCircleIcon = getMaterialIcon("RemoveCircle");
const AddCircleOutlineIcon = getMaterialIcon("AddCircleOutline");

const ProfileShowcasedBooks = ({ user, isCurrentUser }) => {
  const dispatch = useDispatch();
  const [showcasedBooks, setShowcasedBooks] = useState(user.showcased_books);
  const [isEditing, setIsEditing] = useState(false);
  const deleteButton = "/assets/delete.svg";

  const onClickAddBook = () => {
    const handleClick = (book) => {
      if (showcasedBooks.length < 5) {
        setShowcasedBooks((prev) => [...prev, book]);
      }
    };
    const addedBookIds = showcasedBooks.map((b) => b.id);
    dispatch(openModal({ name: "searchReturnBook", data: { addedBookIds, handleClick } }));
  };

  const handleUpdate = () => {
    dispatch(updateShowcasedBooks(showcasedBooks.map((b) => b.id)));
    setIsEditing(false);
  };

  return (
    <div className="mt-8 bg-gradient-to-r from-pastelPurple to-pastelGreen border-b border-t overflow-x-scroll customScrollbar">
      <div className="w-fit my-8 mx-8 md:mx-auto flex flex-row gap-8 md:gap-16 justify-center items-center">
        {user.currently_reading && (
          <div className="flex flex-col flex-none gap-4">
            <b>Currently Reading</b>
            <BookCover book={user.currently_reading} showTitle size="h-36 md:h-48 w-24 md:w-32" />
          </div>
        )}
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-4 items-center">
            <b className="flex-none">My Taste</b>
            {isCurrentUser && !isEditing && (
              <IconButton IconComponent={EditIcon} onClick={() => setIsEditing(true)} tooltip="Edit" />
            )}
            {isEditing && (
              <Button onClick={handleUpdate} text="Update" color="bg-neonBlue" disabled={showcasedBooks.length < 3} />
            )}
            {!isEditing && (
              <Button
                type="link"
                to={`/users/${user.username}/library`}
                text={isCurrentUser ? "My public library" : "See Library"}
                color="bg-fuschia"
              />
            )}
            {!isCurrentUser && (
              <Tooltip title="Overlap between your full libraries" disableFocusListener>
                <div className="flex">
                  <Tag text={`${user.user_overlap_percent || 0}% overlap`} color="bg-yellow border-2 border-orange" />
                </div>
              </Tooltip>
            )}
          </div>
          <div className="flex flex-row gap-4">
            {showcasedBooks.length > 0
              ? showcasedBooks.map((b) => (
                  <div className="relative shrink-0" key={b.id}>
                    <BookCover book={b} showTitle size="h-36 md:h-48 w-24 md:w-32 border shadow-main" />
                    {isEditing && (
                      <button
                        className="absolute -top-[8px] -right-[7px]"
                        onClick={() => setShowcasedBooks(showcasedBooks.filter((book) => book.id !== b.id))}
                        style={{
                          backgroundImage: `url(${deleteButton})`,
                          backgroundSize: "cover",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxSizing: "border-box",
                        }}
                      >
                        <RemoveCircleIcon
                          fontSize="small"
                          sx={{
                            cursor: "pointer",
                            color: "red",
                          }}
                        />
                      </button>
                    )}
                  </div>
                ))
              : !isEditing && <div>None yet!</div>}
            {isEditing && showcasedBooks.length < 5 && (
              <button
                onClick={onClickAddBook}
                className="flex justify-center items-center bg-white h-36 md:h-48 w-24 md:w-[119px] shadow-main border border-offBlack"
              >
                <AddCircleOutlineIcon fontSize={"small"} sx={{ color: "#333" }} />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileShowcasedBooks;
