import React from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ClubMemberView from "./ClubMemberView";
import ClubModeratorView from "./ClubModeratorView";
import { fetchClub } from "../../../app/slices/clubSlice";
import HeadTags from "../../helpers/HeadTags";

const ClubShow = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();

  const { data: currentClub } = useSuspenseQuery({
    queryKey: ["club", uuid],
    queryFn: () => dispatch(fetchClub(uuid)).unwrap()
  });

  return (
    <>
      <HeadTags title={currentClub?.name} description={currentClub?.description} />
      {currentClub.is_moderator ? <ClubModeratorView /> : <ClubMemberView />}
    </>
  );
};

export default withFallback(ClubShow, undefined, () => (<h2>Oops! No club found.</h2>));
