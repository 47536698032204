import React from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchBook } from "../../app/slices/bookSlice";
import Button from "../helpers/Button";
import Layout from "../layout/Layout";
import HeadTags from "../helpers/HeadTags";
import BookCommunityRecs from "./BookCommunityRecs";

const AllRecommendationsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uuid, view } = useParams();

  const { data: { book: currentBook } } = useSuspenseQuery({
    queryKey: ["book", uuid],
    queryFn: () => dispatch(fetchBook(uuid)).unwrap()
  });

  const ctas = [
    {
      text: "Books",
      onClick: () => navigate(`/books/${uuid}/recommendations`),
      isActive: view === "recommendations",
    },
    {
      text: "Clubs",
      onClick: () => navigate(`/books/${uuid}/clubs`),
      isActive: view === "clubs",
    },
    {
      text: "Quests",
      onClick: () => navigate(`/books/${uuid}/quests`),
      isActive: view === "quests",
    },
    {
      text: "Lists",
      onClick: () => navigate(`/books/${uuid}/lists`),
      isActive: view === "lists",
    },
  ];

  return (
    <Layout title={`Recs if you like ${currentBook?.title || "this book"}...`} ctas={ctas}>
      <HeadTags title="Community Recs" description={`Like ${currentBook?.title || "this book"}? Check out these other recommendations from the Pagebound community`} image={currentBook?.image_url} />
      <div className="container max-w-[908px] flex flex-col items-center gap-8 mx-auto m-auto px-3 md:px-0 mb-[100px]">
        <div className="flex flex-col md:flex-row gap-6 w-full">
          <Button type="link" to={`/books/${uuid}`} text="⬅ Back to book" color="bg-neonBlue self-start" />
        </div>
        <BookCommunityRecs bookUuid={uuid} view={view} />
      </div>
    </Layout>
  );
};

export default AllRecommendationsPage;
