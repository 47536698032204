import React, { useState } from "react";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import api from "../../utils/api";
import QuestCard from "./QuestCard";
import InternalSearchbar from "../helpers/InternalSearchbar";
import HeadTags from "../helpers/HeadTags";
import ViewMoreButton from "../helpers/ViewMoreButton";

const AllQuests = () => {
  const [viewAll, setViewAll] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const allQuestsQuery = useSuspenseInfiniteQuery({
    queryKey: ["allQuests"],
    queryFn: ({ pageParam }) => api.get(`/challenges?page=${pageParam}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage.length < 20 ? undefined : pages.length + 1,
  });

  const quests = allQuestsQuery.data.pages.flat();

  const handleSearchResults = (results, query) => {
    setViewAll(false);
    setSearchResults(results);
    setSearchQuery(query);
  };

  const onReset = () => {
    setViewAll(true);
  };

  return (
    <div className="container max-w-[900px] m-auto text-left px-2 py-8 md:py-16 flex flex-col">
      <HeadTags title="Browse Quests" description="Themed challenges of 10 books or less that you earn points for completing" />
      <div className="flex flex-col gap-6 self-center">
        <h2 className="self-center">All Quests</h2>
        <InternalSearchbar type="Quest" resultHandler={handleSearchResults} onReset={onReset} />
      </div>
      <div className="mt-4 md:mt-8 flex flex-col gap-3 md:gap-6">
        {viewAll ? (
          <>
            {quests.map((quest) => (
              <QuestCard key={quest.id} quest={quest} />
            ))}
            <ViewMoreButton {...allQuestsQuery} />
          </>
        ) : searchResults.length > 0 ? (
          searchResults.map((quest) => <QuestCard key={quest.id} quest={quest} />)
        ) : (
          <p className="text-center">We couldn't find any quests matching "{searchQuery}" ☹️</p>
        )}
      </div>
    </div>
  );
};

export default AllQuests;
