import React from "react";
import { useSuspenseInfiniteQuery, useSuspenseQuery } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import UserCard from "../cards/UserCard";
import api from "../../utils/api";
import ViewMoreButton from "../helpers/ViewMoreButton";

const DiscoverPeople = () => {
  const { data: topContributors } = useSuspenseQuery({
    queryKey: ["topContributors"],
    queryFn: () => api.get(`/users?q=top`),
  });
  const newUsersQuery = useSuspenseInfiniteQuery({
    queryKey: ["newUsers"],
    queryFn: ({ pageParam }) => api.get(`/users?q=new&page=${pageParam}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage.length < 10 ? undefined : pages.length + 1,
  });
  const newUsers = newUsersQuery.data.pages.flat();

  return (
    <div className="container flex flex-col max-w-[900px] mx-auto m-auto gap-10 items-start mb-[100px]">
      <div className="w-full text-left flex flex-col gap-6">
        <h2>Top Contributors</h2>
        <div className="flex flex-col gap-3">
          {topContributors.map((user) => (
            <UserCard key={user.id} user={user} variant="showcase" />
          ))}
        </div>
      </div>

      <div className="w-full text-left flex flex-col gap-6">
        <h2>New & Friendly</h2>
        <div className="flex flex-col gap-3">
          {newUsers.map((user) => (
            <UserCard key={user.id} user={user} variant="showcase" />
          ))}
          <ViewMoreButton {...newUsersQuery} />
        </div>
      </div>
    </div>
  );
};

export default withFallback(DiscoverPeople);
