import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { setCurrentUserBook } from "../../app/slices/userBookSlice";
import api from "../../utils/api";
import ReviewForm from "./ReviewForm";
import ReviewCard from "./ReviewCard";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "../helpers/Checkbox";
import Loader from "../helpers/Loader";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { getMaterialIcon } from "../../utils/icons";
const ExpandMoreOutlinedIcon = getMaterialIcon("ExpandMoreOutlined");

const BookReviews = (props) => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { book: currentBook, user_book: userBook } = queryClient.getQueryData(["book", uuid]);
  const { currentUserId } = useSelector((state) => state.user);
  const [showForm, setShowForm] = useState(false);
  const [bookReviews, setBookReviews] = useState([]);
  const [userReview, setUserReview] = useState(null);
  const [filters, setFilters] = useState({ onlyWrittenReviews: true,  onlyFollowing: false });
  const [moreReviews, setMoreReviews] = useState(false);
  const [isLoadingReviews, setIsLoadingReviews] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const pageRef = useRef(1);
  const canWriteReview = userBook?.has_ever_finished || ["dnf", "finished"].includes(props.userBookStatus);

  useEffect(() => {
    pageRef.current = 1;
    setBookReviews([]);
    setIsLoadingReviews(true);
    api
      .get(
        `/books/${currentBook.id}/reviews?page=1&only_written_reviews=${filters.onlyWrittenReviews}&only_following=${filters.onlyFollowing}`
      )
      .then((data) => {
        setBookReviews(data.reviews);
        setUserReview(data.user_review);
        setMoreReviews(data?.total_pages > 1);
      })
      .catch((error) => {
        console.error("Failed to fetch reviews:", error);
        setBookReviews([]);
      })
      .finally(() => setIsLoadingReviews(false));
  }, [currentBook, currentUserId, filters]);

  useEffect(() => {
    return () => (pageRef.current = 1);
  }, []);

  const handleNewReview = ({ newReview }) => {
    setShowForm(false);
    dispatch(setCurrentUserBook({ ...userBook, review: newReview }));
    queryClient.setQueryData(["book", uuid], (oldData) => ({ book: oldData.book, user_book: { ...oldData.user_book, review: newReview } }));
    setUserReview(newReview);
  };

  const fetchMoreReviews = async () => {
    setLoadingMore(true);
    pageRef.current += 1;
    api
      .get(
        `/books/${currentBook.id}/reviews?page=${pageRef.current}&only_written_reviews=${filters.onlyWrittenReviews}&only_following=${filters.onlyFollowing}`
      )
      .then((data) => {
        setBookReviews((prev) => [...prev, ...data.reviews]);
        if (data.total_pages <= pageRef.current) setMoreReviews(false);
      })
      .finally(() => setLoadingMore(false));
  };

  const renderReviews = () => {
    if (isLoadingReviews) return <Loader />;

    return (
      <React.Fragment>
        {bookReviews.length === 0 && !userReview && (
          <div className="w-full bg-reviewContainer flex justify-between items-center rounded-default p-6 border border-offBlack shadow-main">
            <div className="flex flex-col gap-3 text-left pr-5">
              <p className="font-bold">No reviews yet ☹️</p>
              <p>Already finished? Be the first to write a review.</p>
            </div>
          </div>
        )}
        <ul className="flex flex-col gap-4">
          {userReview && <ReviewCard review={userReview} isUserReview key={userReview?.id} />}
          {bookReviews.map((review) => (
            <ReviewCard key={review.id} review={review} />
          ))}
          {moreReviews && (
            <button className="bg-transparent p-2 self-center mt-2" disabled={loadingMore} onClick={fetchMoreReviews}>
              {loadingMore ? (
                "Loading..."
              ) : (
                <>
                  View More <ExpandMoreOutlinedIcon fontSize="small" />
                </>
              )}
            </button>
          )}
        </ul>
      </React.Fragment>
    );
  };

  return (
    <div className="flex flex-col gap-3 box-border border border-offBlack shadow-main rounded-default bg-reviewContainer p-5 md:p-8 w-full">
      {showForm ? (
        <div className="bg-white p-8 border border-offBlack rounded-default shadow-main">
          <ReviewForm postSubmitHandler={handleNewReview} userBook={userBook} review={userReview} />
        </div>
      ) : canWriteReview ? (
        <button className="bg-white p-6 rounded-default border border-offBlack" onClick={() => setShowForm(true)}>
          <div className="text-gray ml-0 text-left py-3 px-4 my-auto border border-offBlack rounded-[20px]">
            {userReview ? "Edit your review" : "Leave a review"}
          </div>
        </button>
      ) : (
        <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => setOpenTooltip(false)}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="You must finish or DNF this book to write a review"
            followCursor={true}
          >
            <div
              className="bg-[#e3e3e3] p-6 rounded-default border border-offBlack"
              onClick={() => setOpenTooltip(true)}
            >
              <div className="text-gray ml-0 text-left py-3 px-4 my-auto border border-offBlack rounded-default">
                Leave a review
              </div>
            </div>
          </Tooltip>
        </ClickAwayListener>
      )}

      <div className="flex flex-row gap-3 md:gap-6 my-2 md:my-3 text-sm md:text-base">
        <Checkbox
          id="onlyWrittenReviews"
          isChecked={filters.onlyWrittenReviews}
          onChange={() => setFilters((prev) => ({ ...prev, onlyWrittenReviews: !prev.onlyWrittenReviews }))}
          text="Written reviews only"
        />
        <Checkbox
          id="onlyFollowing"
          isChecked={filters.onlyFollowing}
          onChange={() => setFilters((prev) => ({ ...prev, onlyFollowing: !prev.onlyFollowing }))}
          text="People I follow"
        />
      </div>
      {renderReviews()}
    </div>
  );
};

export default BookReviews;
