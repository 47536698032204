import React from "react";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import api from "../../utils/api";
import ListCard from "../cards/ListCard";
import ViewMoreButton from "../helpers/ViewMoreButton";

const NewLists = () => {
  const newListsQuery = useSuspenseInfiniteQuery({
    queryKey: ["newLists"],
    queryFn: ({ pageParam }) => api.get(`/lists?type=new&page=${pageParam}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage.length < 5 ? undefined : pages.length + 1,
  });

  const newLists = newListsQuery.data.pages.flat();

  return (
    <div className="flex flex-col gap-6 w-full">
      <div className="flex gap-4 items-center">
        <h2 className="text-left">New Lists</h2>
      </div>

      {newLists.map((list) => (
        <ListCard key={list.id} listData={list} />
      ))}

      <ViewMoreButton {...newListsQuery} />
    </div>
  );
};

export default withFallback(NewLists);
