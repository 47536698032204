import React, { useEffect, useRef, useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import { useParams } from "react-router-dom";
import api from "../../utils/api";
import BookCard from "../cards/BookCard";
import Button from "../helpers/Button";
import HeadTags from "../helpers/HeadTags";
import Layout from "../layout/Layout";

const AuthorPage = withFallback(
  () => {
    const { uuid } = useParams();
    const [expanded, setExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const textRef = useRef(null);
    const { data: author } = useSuspenseQuery({
      queryKey: ["author", uuid],
      queryFn: () => api.get(`/authors/${uuid}`),
    });

    useEffect(() => {
      const checkOverflow = () => {
        const el = textRef.current;
        if (el) setIsOverflowing(el.scrollHeight > el.clientHeight);
      };

      checkOverflow();
    }, [author?.description]);

    return (
      <Layout title={author.name}>
        <HeadTags title={author?.name} description={author?.description} />
        <div className="text-left">
          <div ref={textRef} className={!expanded && "text-ellipsis line-clamp-3"}>
            {author.description}
          </div>
          {isOverflowing && (
            <button onClick={() => setExpanded(!expanded)} className="text-gray text-xs">
              {expanded ? "Read Less" : "Read More"}
            </button>
          )}
        </div>
        <div className="mt-8 flex flex-row gap-4 flex-wrap">
          {author.books.map((book) => (
            <BookCard key={book.id} book={book} />
          ))}
        </div>
      </Layout>
    );
  },
  undefined, // use default Loading component
  () => (
    <div className="min-h-[100vh] h-max m-auto pt-8 flex flex-col gap-4 items-center justify-center pb-[50px] md:pb-[100px]">
      <h2>Hmm, we couldn't find that...</h2>
      <Button type="link" to="/feed" text="⬅ Back to home" color="bg-fuschia" />
    </div>
  )
);

export default AuthorPage;
