import React, { startTransition, useState } from "react";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import pluralize from "pluralize";
import { Table, TableBody, TableHead, TableRow, TableCell } from "@mui/material";
import { openModal, setShowListView } from "../../app/slices/globalUISlice";
import BookCard from "../cards/BookCard";
import AddBookCard from "../cards/AddBookCard";
import api from "../../utils/api";
import Tag from "../helpers/Tag";
import IconButton from "../helpers/IconButton";
import Button from "../helpers/Button";
import ViewMoreButton from "../helpers/ViewMoreButton";
import { getMaterialIcon } from "../../utils/icons";
const AddCircleOutlineIcon = getMaterialIcon("AddCircleOutline");
const RemoveCircleIcon = getMaterialIcon("RemoveCircle");
const GridViewRoundedIcon = getMaterialIcon("GridViewRounded");
const FormatListBulletedRoundedIcon = getMaterialIcon("FormatListBulletedRounded");
const ArrowDropUpRoundedIcon = getMaterialIcon("ArrowDropUpRounded");
const ArrowDropDownRoundedIcon = getMaterialIcon("ArrowDropDownRounded");

const ListBooks = ({ list, isEditing }) => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const currentUserId = useSelector((state) => state.user.currentUserId);
  const { showListView } = useSelector((state) => state.globalUI);
  const [sortBy, setSortBy] = useState({ attribute: "added_at", isAscending: false });

  const listBooksQuery = useSuspenseInfiniteQuery({
    queryKey: ["listBooks", uuid, sortBy],
    queryFn: ({ pageParam }) => api.get(`/lists/${uuid}/books?page=${pageParam}${sortBy.attribute != null ? `&sort_by=${sortBy.attribute}&asc=${sortBy.isAscending}` : ''}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => pages.length < lastPage.total_pages ? pages.length + 1 : undefined,
  });

  const books = listBooksQuery.data.pages.map((p) => p.books).flat();

  const addBook = (book) => {
    api.post(`/lists/${uuid}/add_book`, { book_id: book.id }).then(listBooksQuery.refetch);
  };

  const removeBookIcon = (book) => {
    return (
      <button
        type="button"
        onClick={() => {
          api.post(`/lists/${uuid}/remove_book`, { book_id: book.id }).then(listBooksQuery.refetch);
        }}
      >
        <RemoveCircleIcon fontSize="small" sx={{ color: "#FF695F" }} />
      </button>
    );
  };

  const onClickAddBookCard = () => {
    const addedBookIds = books.map((b) => b.id);
    dispatch(openModal({ name: "searchReturnBook", data: { addedBookIds, handleClick: addBook } }));
  };

  const updateSorting = (col) => {
    if (sortBy.attribute === col) {
      startTransition(() => setSortBy((prev) => ({ ...prev, isAscending: !prev.isAscending })));
    } else {
      startTransition(() => setSortBy({ attribute: col, isAscending: true }));
    }
  };

  const sortIcon = sortBy.isAscending ? (
    <span className="relative">
      <ArrowDropUpRoundedIcon classes={{ root: "absolute -top-[7px] -left-[8px]" }} fontSize="large" color="primary" />
    </span>
  ) : (
    <span className="relative">
      <ArrowDropDownRoundedIcon
        classes={{ root: "absolute -top-[7px] -left-[8px]" }}
        fontSize="large"
        color="primary"
      />
    </span>
  );

  return (
    <div className="w-full flex flex-col gap-2">
      <div className="flex flex-row w-full justify-between px-4 lg:px-0">
        <div className="flex flex-row w-full justify-between">
          <div className="flex flex-row gap-2">
            <div>{pluralize("books", list.book_count, true)}</div>
            {currentUserId && <Tag text={`${list.user_overlap_percent || 0}% overlap`} color="bg-yellow border-2 border-orange" />}
          </div>
          {list.comment_count > 0 && (
            <Button
              text="Jump to comments"
              type="link"
              color="bg-neonBlue md:hidden"
              to={`/lists/${list.uuid}#comments`}
            />
          )}
        </div>
        <div>{/* TODO: search bar */}</div>
        <div className="hidden lg:flex flex-row gap-2">
          <IconButton
            IconComponent={GridViewRoundedIcon}
            onClick={() => dispatch(setShowListView(false))}
            isActive={!showListView}
            tooltip="Showcase view"
          />
          <IconButton
            IconComponent={FormatListBulletedRoundedIcon}
            onClick={() => dispatch(setShowListView(true))}
            isActive={showListView}
            tooltip="List view"
          />
        </div>
      </div>
      <div className="flex flex-col w-full flex-wrap gap-6 justify-center md:justify-start mt-3">
        {showListView ? (
          <Table>
            <TableHead classes={{ root: "whitespace-nowrap" }}>
              <TableRow>
                <TableCell classes={{ root: "w-[90px]" }}>Cover</TableCell>
                <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("title")}>
                  Title {sortBy.attribute === "title" && sortIcon}
                </TableCell>
                <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("author")}>
                  Author {sortBy.attribute === "author" && sortIcon}
                </TableCell>
                <TableCell classes={{ root: "w-[350px]" }}>Description</TableCell>
                <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("avg_rating")}>
                  Avg Rating {sortBy.attribute === "avg_rating" && sortIcon}
                </TableCell>
                <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("added_at")}>
                  Added on {sortBy.attribute === "added_at" && sortIcon}
                </TableCell>
                <TableCell classes={{ root: "min-w-[24px]" }}>{/* status icon button */}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isEditing && (
                <TableRow>
                  <TableCell>
                    <div
                      className="flex justify-center items-center w-[65px] h-[100px] bg-white border shadow-main cursor-pointer"
                      onClick={onClickAddBookCard}
                    >
                      <AddCircleOutlineIcon fontSize="small" sx={{ color: "#333" }} />
                    </div>
                  </TableCell>
                  <TableCell>add book</TableCell>
                </TableRow>
              )}
              {books?.map((book) => (
                <BookCard key={book.id} book={book} variant="row" icon={isEditing ? removeBookIcon(book) : null} />
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className="flex flex-wrap gap-6 justify-center md:justify-start">
            {currentUserId === list.user.id && <AddBookCard onClick={onClickAddBookCard} />}
            {books?.map((book) => (
              <BookCard key={book.id} book={book} icon={isEditing ? removeBookIcon(book) : null} />
            ))}
          </div>
        )}

        <ViewMoreButton {...listBooksQuery} />
      </div>
    </div>
  );
};

export default withFallback(ListBooks);
