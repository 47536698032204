import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Collapsible from "react-collapsible";
import clsx from "clsx";
import ChatBubble from "../../assets/chat-bubble.svg";
import { timeAgo } from "../../utils/utils";
import Tag from "../helpers/Tag";
import { Rating } from "@mui/material";
import BookCover from "../helpers/BookCover";
import pin from "../../assets/pin.svg";
import Menu from "../helpers/Menu";
import { openModal } from "../../app/slices/globalUISlice";
import api from "../../utils/api";
import UserProvidedContent from "../helpers/richText/UserProvidedContent";
import { getMaterialIcon } from "../../utils/icons";
const FavoriteBorderOutlinedIcon = getMaterialIcon("FavoriteBorderOutlined");
const FavoriteOutlinedIcon = getMaterialIcon("FavoriteOutlined");
const StarRoundedIcon = getMaterialIcon("StarRounded");

const StarRating = ({ text, value, isTrigger }) => {
  // NOTE: this is obnoxious because of the chevron trigger in the Collapsible component below -
  // flex positioning messes up the chevron positioning (but only on actual mobile devices)
  return (
    <span className={clsx("mb-1 text-xs md:text-sm", !isTrigger && "flex flex-row gap-2 items-center")}>
      {text && <span className="w-[70px] md:w-[120px]">{`${text}: `}</span>}

      <Rating
        name={`${text}-rating`}
        value={Number(value ?? 0)}
        precision={0.1}
        size="small"
        readOnly
        icon={<StarRoundedIcon className="stroke-offBlack text-yellow" fontSize="inherit" />}
        emptyIcon={<StarRoundedIcon className="stroke-offBlack text-white" fontSize="inherit" />}
      />
      <span className={clsx("mx-2", isTrigger && "relative -top-[2px]")}>{value > 0 && value}</span>
    </span>
  );
};

const ReviewCard = ({ review: reviewData, isUserReview, variant }) => {
  const dispatch = useDispatch();
  const { currentUserId } = useSelector((state) => state.user);
  const [review, setReview] = useState(reviewData);
  const [expanded, setExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [loadingLike, setLoadingLike] = useState(false);
  const textRef = useRef(null);
  const chevron = "relative after:transform after:transition-all after:font-sans after:content-['›'] after:absolute";

  const checkOverflow = () => {
    const el = textRef.current;
    if (el) setIsOverflowing(el.scrollHeight > el.clientHeight);
  };

  useEffect(() => {
    checkOverflow();
  }, [review]);

  useEffect(() => {
    setReview(reviewData);
  }, [reviewData]);

  const handleEdit = () => {
    const handleReviewSubmit = ({ newReview }) => setReview(newReview);
    const data = { review, handleReviewSubmit, isOtherUser: !isUserReview };
    dispatch(openModal({ name: "newReview", data }));
  };

  const handleLike = (event) => {
    event.stopPropagation();
    if (!currentUserId) return dispatch(openModal("loginPrompt"));
    setLoadingLike(true);
    api
      .post(`/reviews/${review.id}/like`)
      .then((data) => setReview({ ...review, upvotes: data.upvotes, is_liked: data.is_liked }))
      .catch(() => {})
      .finally(() => setLoadingLike(false));
  };

  const handleRemoveLike = (event) => {
    event.stopPropagation();
    if (!currentUserId) return dispatch(openModal("loginPrompt"));
    setLoadingLike(true);
    api
      .post(`/reviews/${review.id}/remove_like`)
      .then((data) => setReview({ ...review, upvotes: data.upvotes, is_liked: data.is_liked }))
      .catch(() => {})
      .finally(() => setLoadingLike(false));
  };

  const handleFlag = () => {
    const body = (
      <>
        Flag this review if it violates Pagebound's{" "}
        <a href="https://support.pagebound.co/terms_of_use" target="_blank">
          Terms of Use
        </a>
        . We will remove the review until we can do a manual review. Since we are a small company, this review may take
        up to 24 hours.
        <br />
        <br />
        Thanks for your help moderating the Pagebound community!
      </>
    );
    dispatch(
      openModal({
        name: "confirmation",
        data: {
          title: "Are you sure you want to flag this review?",
          body,
          confirmText: "Yes, flag",
          onConfirm: () => {
            api
              .post(`/flags`, { resource_type: "Review", resource_id: review.id })
              .then(() => setReview({ ...review, is_flagged: true }));
          },
        },
      })
    );
  };

  const actionItems = [
    isUserReview && { text: "edit", onClick: handleEdit },
    currentUserId && !isUserReview && !review?.is_flagged && { text: "flag", onClick: handleFlag },
  ];

  return (
    <li
      className={clsx(
        "relative p-4 flex gap-4 text-left w-full",
        variant !== "modal" && "bg-white rounded-default border border-offBlack shadow-main md:p-6 cursor-pointer"
      )}
      key={review.id}
      onClick={() =>
        dispatch(openModal({ name: "showReview", data: { reviewId: review.id, setCardReview: setReview } }))
      }
    >
      <div className="absolute top-4 right-4 flex gap-2.5 justify-between md:justify-normal">
        {review.is_spoiler && <Tag text="spoilers" color="bg-seafoam" />}
        {review.is_dnf && <Tag text="DNF" color="bg-pastelPink" />}
        {isUserReview && <img src={pin} alt="pin" className="w-6 h-6" />}
        {currentUserId && <Menu items={actionItems} />}
      </div>
      {variant === "with-image" && (
        <div
          className="self-center md:self-start"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <BookCover
            book={review.book}
            showTitle
            size="flex-none w-[62px] h-24 md:w-[104px] md:h-40 border shadow-main"
          />
        </div>
      )}
      <div className="flex flex-col justify-between gap-2 items-start flex-1 min-w-0">
        <div className="flex flex-col gap-2 md:gap-3 w-full items-start">
          <div
            className="flex text-xs text-gray gap-3 md:gap-6 justify-between items-center"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {isUserReview ? (
              <b className="text-mainBlue">My Review</b>
            ) : (
              <Link to={`/users/${review.username}`}>{review.username}</Link>
            )}
            <div>{timeAgo(review.created_at)}</div>
            {review.is_edited && <div>Edited</div>}
          </div>

          <div
            className="font-space font-bold flex flex-col"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Collapsible
              trigger={<StarRating value={review.overall_rating} isTrigger />}
              triggerClassName={`${chevron} after:rotate-90`}
              triggerOpenedClassName={`${chevron} after:-rotate-90`}
              transitionTime={200}
              triggerElementProps={{ id: `collapsible-trigger-${review.id}` }}
              contentElementId={`collapsible-content-${review.id}`}
            >
              <StarRating text="Enjoyment" value={review.entertainment_rating || 0} />
              <StarRating text="Quality" value={review.quality_rating || 0} />
              <StarRating text="Characters" value={review.character_rating || 0} />
              <StarRating text="Plot" value={review.plot_rating || 0} />
            </Collapsible>
            <div className="flex flex-row gap-2 items-center">
              {review.emojis.map((emoji, index) => (
                <div className="text-[20px]" key={index}>
                  {emoji}
                </div>
              ))}
            </div>
          </div>

          <div
            ref={textRef}
            className={`text-[14px] md:text-base w-full whitespace-pre-wrap overflow-hidden${
              !expanded && " text-ellipsis line-clamp-3 md:line-clamp-4"
            }`}
          >
            <UserProvidedContent resource={review} content={review.review} type="review" onReveal={checkOverflow} />
          </div>
          {isOverflowing && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}
              className="text-gray text-xs hover:text-mainBlue"
            >
              {expanded ? "Read Less" : "Read More"}
            </button>
          )}
        </div>

        <div className="flex flex-row mt-2 justify-between md:justify-normal gap-3 w-full font-bold">
          <div className="flex gap-3 items-center">
            <div className="self-start flex items-center justify-center rounded-default border h-[26px] px-2.5 min-w-[51px] bg-white border-gray">
              <button
                onClick={review.is_liked ? handleRemoveLike : handleLike}
                disabled={loadingLike}
                className="flex items-center"
              >
                {review.is_liked ? (
                  <FavoriteOutlinedIcon color="#FF695F" fontSize="small" className="text-red" />
                ) : (
                  <FavoriteBorderOutlinedIcon color="#D2D2D2" fontSize="small" className="text-gray hover:text-red" />
                )}
              </button>
              <div className="text-gray text-xs">{review.upvotes}</div>
            </div>
            <div className="items-center text-xs text-gray rounded-default border py-1 px-3 bg-white border-gray">
              <img src={ChatBubble} alt="comments" className="inline" />
              {"  "}
              {review.comment_count}
            </div>
            <div className="text-gray text-xs">Reply</div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ReviewCard;
