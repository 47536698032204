import React from "react";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import api from "../../utils/api";
import ClubCard from "../cards/ClubCard";
import OnboardingClubCard from "../onboarding/OnboardingClubCard";
import ViewMoreButton from "../helpers/ViewMoreButton";

const PopularClubs = ({ isOnboarding }) => {
  const popularClubsQuery = useSuspenseInfiniteQuery({
    queryKey: ["popularClubs"],
    queryFn: ({ pageParam }) => api.get(`/clubs/index_by_type?type=popular&page=${pageParam}${isOnboarding ? "isOnboarding=true" : ""}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage.length < 5 ? undefined : pages.length + 1,
  });

  const popularClubs = popularClubsQuery.data.pages.flat();
  const Component = isOnboarding ? OnboardingClubCard : ClubCard;

  return (
    <div className="flex flex-col gap-6 w-full">
      {popularClubs.map((club) => (
        <Component key={club.id} club={club} />
      ))}
      <ViewMoreButton {...popularClubsQuery} />
    </div>
  );
};

export default withFallback(PopularClubs);
