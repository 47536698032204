import React from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import pluralize from "pluralize";
import BookCard from "../cards/BookCard";
import { openModal } from "../../app/slices/globalUISlice";
import SaveIcon from "../../assets/SaveIcon";
import Squiggle from "../../assets/SquiggleIcon";
import api from "../../utils/api";
import Button from "../helpers/Button";
import HeadTags from "../helpers/HeadTags";
import Tag from "../helpers/Tag";

const QuestPage = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { currentUserId } = useSelector((state) => state.user);
  const { data: quest, refetch: refetchQuest } = useSuspenseQuery({
    queryKey: ["quest", uuid],
    queryFn: () => api.get(`/challenges/${uuid}`),
  });

  const handleJoinQuest = () => {
    if (!currentUserId) {
      dispatch(openModal({ name: "loginPrompt" }));
    } else {
      api.post(`/challenges/${uuid}/join`).then(refetchQuest);
    }
  };

  const handleLeaveQuest = () => {
    dispatch(
      openModal({
        name: "confirmation",
        data: {
          title: "Are you sure you want to leave this quest?",
          confirmText: "Yes, leave",
          onConfirm: () => api.post(`/challenges/${uuid}/leave`).then(refetchQuest),
        },
      })
    );
  };

  const handleBookmark = async () => {
    if (!currentUserId) {
      dispatch(openModal({ name: "loginPrompt" }));
    } else {
      const params = { type: "Challenge", id: quest.id };
      const action = quest.is_bookmarked ? api.destroy(`/bookmarks/destroy`, params) : api.post(`/bookmarks`, params);
      action.then(refetchQuest);
    }
  };

  const onViewMembers = () => {
    dispatch(openModal({ name: "viewMembers", data: { quest } }));
  };

  return (
    <div className="relative h-max min-h-[100vh] mx-auto m-auto bg-cover bg-no-repeat bg-quest-squiggle">
      <HeadTags title={quest?.name} description={quest?.description} />
      <div className="container mx-auto m-auto pt-8 md:pt-[4em]">
        <header className="flex flex-col w-[80%] m-auto gap-5 md:gap-6 mb-12 md:mb-16 items-center">
          <h1>Quest</h1>
          <div className="hidden md:block">
            <Squiggle width="340" height="37" />
          </div>
          <div className="md:hidden">
            <Squiggle width="250" height="26" />
          </div>
        </header>
        <div className="container m-auto w-9/12 max-w-[1280px] box-border pb-[100px]">
          <div className="flex flex-col gap-6 md:gap-8 text-left">
            <div className="flex justify-between">
              <div className="flex flex-col md:flex-row gap-8 md:items-center">
                <h2>{quest.name}</h2>
                <div className="flex gap-8 flex-row">
                  {quest.joined ? (
                    <Button text="Joined" onClick={handleLeaveQuest} color="bg-mainGreen" size="medium" />
                  ) : (
                    <Button text="Join" onClick={handleJoinQuest} color="bg-neonBlue" size="medium" />
                  )}
                  {quest.joined && quest.user_challenge.status === "in_progress" && (
                    <Tag
                      text={`${quest.user_challenge.completed_books_count} of ${quest.goal} read`}
                      color="bg-yellow border-2 border-orange"
                      size="medium"
                    />
                  )}
                  {quest.joined && quest.user_challenge.status === "finished" && (
                    <Tag text="Finished" color="bg-mainGreen" size="medium" />
                  )}
                </div>
              </div>

              <button onClick={handleBookmark} className="self-start p-1 rounded-full hover:bg-yellow">
                <SaveIcon fillColor={quest.is_bookmarked ? "#F7DB90" : "transparent"} width="23" height="32" />
              </button>
            </div>
            <div>
              <p className="mb-2 text-sm">
                created {quest.created_at} //{' '}
                <b onClick={onViewMembers} className="cursor-pointer hover:text-pink">
                  {pluralize("participants", quest.participant_count, true)}
                </b>
              </p>
              <p>{quest.description}</p>
              <div className="flex gap-2 mt-2">
                {quest.emojis.map((emoji, index) => (
                  <div key={index} className="text-[25px]">
                    {emoji}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-wrap gap-6">
              {quest.books.map((book) => <BookCard book={book} key={book.uuid} />)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestPage;
