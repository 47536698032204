import React from "react";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import ClubCard from "../cards/ClubCard";
import api from "../../utils/api";
import ViewMoreButton from "../helpers/ViewMoreButton";

const NewClubs = () => {
  const newClubsQuery = useSuspenseInfiniteQuery({
    queryKey: ["newClubs"],
    queryFn: ({ pageParam }) => api.get(`/clubs/index_by_type?type=newest&page=${pageParam}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage.length < 5 ? undefined : pages.length + 1,
  });

  const newClubs = newClubsQuery.data.pages.flat();

  return (
    <div className="flex flex-col gap-6">
      {newClubs.map((club) => (
        <ClubCard key={club.id} club={club} />
      ))}
      <ViewMoreButton {...newClubsQuery} />
    </div>
  );
};

export default withFallback(NewClubs);
