import React, { useEffect } from "react";
import { useQueryClient, useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import { useSelector, useDispatch } from "react-redux";
import { useConfig } from 'vike-react/useConfig';
import { useNavigate } from "react-router-dom";
import { openModal } from "../../app/slices/globalUISlice";
import api from "../../utils/api";
import FeedCard from "../cards/FeedCard";
import BookCard from "../cards/BookCard";
import Button from "../helpers/Button";
import ViewMoreButton from "../helpers/ViewMoreButton";

const HomeFeed = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const config = useConfig();
  const queryClient = useQueryClient();
  const { currentUserId, currentUser } = useSelector((state) => state.user);
  config({ title: "Home | Pagebound" });

  useEffect(() => {
    if (!currentUserId) navigate("/", { replace: true });
    else queryClient.refetchQueries(["unreadNotifications"]);
  }, [currentUserId]);

  if (!currentUserId) return null;

  const feedItemsQuery = useSuspenseInfiniteQuery({
    queryKey: ["feedItems"],
    queryFn: ({ pageParam }) => api.get(`/home_feed?page=${pageParam}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage.feed_items.length > 0 ? pages.length + 1 : undefined,
  });
  const feedItems = feedItemsQuery.data.pages.map((p) => p.feed_items).flat();
  const currentBooks = feedItemsQuery.data.pages.map((p) => p.current_reads).flat();

  const setCurrentlyReading = (book) => {
    api.post("/user_books?return_book=true", { book_id: book.id, status: "current" })
      .then(feedItemsQuery.refetch);
  };

  return (
    <div className="min-h-[100vh] h-max m-auto pt-8 md:bg-cover bg-no-repeat bg-home-squiggle pb-[50px] md:pb-[100px]">
      <div className="container m-auto px-4 md:px-0 md:w-9/12 flex flex-col items-center text-center">
        <header className="flex flex-col mb-12 mt-2 md:my-16 gap-8 w-full md:w-9/12">
          <h2>Welcome back, {currentUser?.username || "reader"}</h2>
          <div
            className="flex self-center w-fit max-w-full gap-3 md:gap-4 pb-2 px-2 overflow-x-scroll customScrollbar"
          >
            {currentBooks?.length > 0 ? (
              currentBooks.map((book) => <BookCard key={book.id} book={book} variant="homeFeed" />)
            ) : (
              <div className="w-full bg-current flex flex-col gap-4 justify-center items-center rounded-default p-6 border border-offBlack shadow-main">
                <p className="font-bold">What are you currently reading? 📖</p>
                <Button
                  text="Search"
                  onClick={() =>
                    dispatch(openModal({ name: "searchReturnBook", data: { handleClick: setCurrentlyReading } }))
                  }
                  color="bg-neonBlue"
                />
              </div>
            )}
          </div>
        </header>
        <div className="flex flex-col gap-4 md:gap-8 w-full items-center">
          {feedItems.map((item, index) => (
            <FeedCard key={index} activityItemData={item} />
          ))}
          <ViewMoreButton {...feedItemsQuery} showCaughtUp />
        </div>
      </div>
    </div>
  );
};

export default withFallback(HomeFeed);
