import React from "react";
import { useSuspenseQuery, useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../layout/Layout";
import Button from "../helpers/Button";
import ClubCard from "../cards/ClubCard";
import PostCard from "../forum/PostCard";
import PopularClubs from "./PopularClubs";
import NewClubs from "./NewClubs";
import LoginPrompt from "../helpers/LoginPrompt";
import api from "../../utils/api";
import { openModal } from "../../app/slices/globalUISlice";
import HeadTags from "../helpers/HeadTags";
import ViewMoreButton from "../helpers/ViewMoreButton";

const ClubHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUserId = useSelector((state) => state.user.currentUserId);
  const { data: joinedClubs } = useSuspenseQuery({
    queryKey: ["joinedClubs"],
    queryFn: () => api.get(`/clubs/joined`),
  });
  const clubHighlightsQuery = useSuspenseInfiniteQuery({
    queryKey: ["clubHighlights"],
    queryFn: ({ pageParam }) => api.get(`/clubs/joined_top_posts?page=${pageParam}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage.length < 10 ? undefined : pages.length + 1,
  });
  const clubHighlights = clubHighlightsQuery.data.pages.flat();

  const onCreateClub = () => {
    dispatch(openModal({
      name: "confirmation",
      data: {
        title: "FYI, you'll have 7 days to gain at least 5 members to keep your Club active.",
        body: "We do this to ensure that Clubs stay vibrant and engaging",
        confirmText: "Sounds good!",
        cancelText: "Go back",
        onConfirm: () => navigate("/clubs/new"),
      },
    }));
  };

  return (
    <Layout
      title="Clubs"
      description="Discuss books with likeminded readers and find your community."
    >
      <HeadTags title="Clubs" description="Discuss books with likeminded readers and find your community." />
      <div className="relative flex flex-col gap-8 md:gap-16">
        <div className="w-10/12 md:w-9/12 max-w-[900px] m-auto justify-center">
          <div className="flex gap-8 text-left w-full mb-6">
            <h2 className="hidden md:block">Clubs You've Joined</h2>
            <h2 className="block md:hidden">Joined</h2>
            {!!currentUserId && (
              <>
                <div className="flex md:hidden gap-2">
                  <Button text="Create" onClick={onCreateClub} color="bg-neonBlue" size="small" />
                  <Button text="Browse" type="link" to="/clubs/browse" color="bg-fuschia" size="small" />
                </div>
                <div className="hidden md:flex gap-3">
                  <Button text="Create Club" onClick={onCreateClub} color="bg-neonBlue" />
                  <Button text="Browse all" type="link" to="/clubs/browse" color="bg-fuschia" />
                </div>
              </>
            )}
          </div>
          {!currentUserId ? (
            <LoginPrompt />
          ) : joinedClubs.length > 0 ? (
            <div className="flex flex-col md:flex-row flex-wrap gap-8">
              {joinedClubs.map((club) => (
                <ClubCard key={club.id} club={club} variant="small" />
              ))}
            </div>
          ) : (
            <div className="w-full max-w-[900px] bg-lightPurple flex justify-between items-center rounded-default p-6 border border-offBlack shadow-main">
              <div className="flex flex-col gap-3 text-left pr-5">
                <b>You haven't joined any Clubs yet!</b>
                <p>Don't be shy - browse and join a Club. Once you do, you can find all your Clubs here.</p>
              </div>
            </div>
          )}
        </div>

        {joinedClubs.length == 0 ? (
          <>
            <div className="w-10/12 md:w-9/12 max-w-[900px] m-auto justify-center">
              <div className="flex gap-8 text-left w-full mb-6">
                <h2>Discover Popular Clubs</h2>
              </div>
              <PopularClubs />
            </div>
      
            <div className="w-10/12 md:w-9/12 max-w-[900px] m-auto justify-center">
              <div className="flex flex-col gap-2 text-left w-full mb-6">
                <h2>Discover New Clubs</h2>
                <p>Newly created Clubs have 7 days to gain at least 5 members.</p>
              </div>
              <NewClubs />
            </div>
          </>
        ) : (
          <div className="relative w-10/12 md:w-9/12 max-w-[900px] m-auto justify-center">
            <div className="flex gap-8 text-left w-full mb-6">
              <h2>Highlights From Your Clubs</h2>
            </div>
            {clubHighlights.length == 0 ? (
              <div className="w-full max-w-[900px] bg-lightPurple flex justify-between items-center rounded-default p-6 border border-offBlack shadow-main">
                <div className="flex flex-col gap-3 text-left pr-5">
                  <b>No posts in your Clubs yet!</b>
                  <p>Don't be shy - post in a Club forum to start the convo.</p>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-6">
                {clubHighlights.map((post) => (
                  <div
                    key={post.id}
                    className="bg-pastelBlue rounded-default border border-offBlack text-left shadow-main flex flex-col p-4 gap-2"
                  >
                    <b>From the {post.forum.name} forum</b>
                    <PostCard key={post.id} postData={post} onClick={() => navigate(`/posts/${post.id}`)} />
                  </div>
                ))}
                <ViewMoreButton {...clubHighlightsQuery} showCaughtUp />
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ClubHome;
