import React from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ListCard from "../cards/ListCard";
import api from "../../utils/api";
import Button from "../helpers/Button";
import Layout from "../layout/Layout";
import PopularLists from "./PopularLists";
import LoginPrompt from "../helpers/LoginPrompt";
import HeadTags from "../helpers/HeadTags";

const ListsHome = () => {
  const currentUserId = useSelector((state) => state.user.currentUserId);
  const { data: createdLists } = useSuspenseQuery({
    queryKey: ["createdLists"],
    queryFn: () => api.get(`/lists/user_lists`),
  });

  return (
    <div className="relative h-max min-h-[100vh] mx-auto m-auto bg-cover bg-no-repeat bg-club-squiggle">
      <HeadTags title="Lists" description="Like a bookish Pinterest board, browse and create themed collections." />
      <Layout title="Lists" description="Like a bookish Pinterest board, browse and create themed collections.">
        <div className="relative flex flex-col gap-8 md:gap-16">
          <div className="w-10/12 md:w-9/12 max-w-[900px] m-auto justify-center">
            <div className="flex gap-8 text-left w-full mb-6">
              <h2>Your Lists</h2>
              {!!currentUserId && (
                <>
                  <div className="hidden md:flex flex-row gap-3">
                    <Button text="Create List" type="link" to="/lists/new" color="bg-neonBlue" />
                    <Button text="Bookmarked Lists" type="link" to="/library/bookmarks/lists" color="bg-fuschia" />
                  </div>
                  <div className="flex flex-row flex-none gap-3 md:hidden">
                    <Button text="Create" type="link" to="/lists/new" color="bg-neonBlue" />
                    <Button text="Bookmarked" type="link" to="/library/bookmarks/lists" color="bg-fuschia" />
                  </div>
                </>
              )}
            </div>
            {!currentUserId ? (
              <LoginPrompt />
            ) : createdLists.length > 0 ? (
              <div className="flex flex-col gap-4">
                {createdLists.map((list) => (
                  <ListCard key={list.id} listData={list} />
                ))}
              </div>
            ) : (
              <div className="w-full max-w-[900px] bg-pastelPink flex justify-between items-center rounded-default p-6 border border-offBlack shadow-main">
                <div className="flex flex-col gap-3 text-left pr-5">
                  <b>You haven't created any Lists yet!</b>
                  <p>Are you ready to be a tastemaker?</p>
                </div>
              </div>
            )}
          </div>

          <div className="w-10/12 md:w-9/12 max-w-[900px] m-auto justify-center">
            <PopularLists />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ListsHome;
