import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import pluralize from "pluralize";
import Button from "../../helpers/Button";
import AllClubBooks from "./AllClubBooks";
import Forum from "../../forum/Forum";
import { joinClub, leaveClub } from "../../../app/slices/clubSlice";
import { openModal, openSnackbar } from "../../../app/slices/globalUISlice";
import api from "../../../utils/api";
import BookCard from "../../cards/BookCard";
import SaveIcon from "../../../assets/SaveIcon";
import { CLUB_THEMES } from "../../../constants";
import Tag from "../../helpers/Tag";
import shareIcon from "../../../assets/shareIcon.svg";
import Tooltip from "@mui/material/Tooltip";
import { getMaterialIcon } from "../../../utils/icons";
const LockOutlinedIcon = getMaterialIcon("LockOutlined");

const ClubMemberView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { uuid, view = "about-us" } = useParams();
  const { currentUserId } = useSelector((state) => state.user);
  const currentClub = queryClient.getQueryData(["club", uuid]);
  const [isSaved, setIsSaved] = useState(currentClub?.is_bookmarked || false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const handleJoin = () => {
    if (!currentUserId) {
      dispatch(openModal({ name: "loginPrompt" }));
    } else {
      dispatch(joinClub(currentClub.uuid)).unwrap()
        .then((data) => queryClient.setQueryData(["club", uuid], data));
    }
  };

  const handleLeave = () => {
    dispatch(
      openModal({
        name: "confirmation",
        data: {
          title: "Are you sure you want to leave this club?",
          confirmText: "Yes, leave",
          onConfirm: () => dispatch(leaveClub(currentClub.uuid)).unwrap()
            .then((data) => queryClient.setQueryData(["club", uuid], data)),
        },
      })
    );
  };

  const handleSave = async () => {
    if (!currentUserId) {
      dispatch(openModal({ name: "loginPrompt" }));
    } else {
      setIsLoadingSave(true);
      const params = { type: "Club", id: currentClub.id };
      const action = isSaved ? api.destroy(`/bookmarks/destroy`, params) : api.post(`/bookmarks`, params);
      action.then(() => {
        setIsLoadingSave(false);
        setIsSaved(!isSaved);
      });
    }
  };

  const handleShare = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(`${window.location.origin}/clubs/${currentClub.uuid}`);
    dispatch(openSnackbar({ message: "Link copied to clipboard" }));
  };

  const AboutUsView = () => {
    const membersText = `${pluralize(currentClub.member_nickname || "members", currentClub.member_count, true)} joined`;
    const onViewMembers = () => {
      dispatch(openModal({ name: "viewMembers", data: { club: currentClub } }));
    };

    return (
      <div className="flex flex-col gap-8 md:gap-16 md:items-start md:justify-start">
        <div className="flex flex-col gap-2 pl-4 md:pl-0 w-full">
          <div className="flex gap-3 md:gap-6 text-left items-center justify-between w-full">
            <h2>About Us</h2>
            <div className="flex gap-3">
              <button
                onClick={handleShare}
                className="bg-transparent rounded-full border shadow-main w-[20px] h-[20px] md:w-[30px] md:h-[30px] flex items-center justify-center"
              >
                <img src={shareIcon} alt="share" className="w-[10px] h-[10px] md:w-[15px] md:h-[15px]" />
              </button>
              <button
                onClick={handleSave}
                disabled={isLoadingSave}
                className="hidden md:block p-1 rounded-full hover:bg-yellow"
              >
                <SaveIcon fillColor={isSaved ? "#F7DB90" : "transparent"} width="23" height="32" />
              </button>
              <button onClick={handleSave} disabled={isLoadingSave} className="md:hidden">
                <SaveIcon fillColor={isSaved ? "#F7DB90" : "transparent"} width="15" height="20" />
              </button>
            </div>
          </div>
          <div className="flex gap-2 md:gap-4">
            {currentClub.creator_username && (
              <p>
                created by <Link to={`/users/${currentClub.creator_username}`}>{currentClub.creator_username}</Link>
              </p>
            )}
            {currentClub.name !== "Pagebound Club" && (
              <>
                <p>//</p>{" "}
                <p className="font-bold">
                  <Button type="text" onClick={onViewMembers} text={membersText} />
                </p>
              </>
            )}
          </div>
          <div className="flex gap-2">
            {currentClub.emojis.map((emoji, index) => (
              <div className="text-[25px]" key={index}>
                {emoji}
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <div className="flex gap-6 md:gap-8 pl-4 md:pl-0 items-start justify-start">
            <div className="flex gap-3 md:gap-4 items-center">
              <h2>Our Taste</h2>
            </div>
            {currentClub.book_count - currentClub.showcased_books.length > 0 && (
              <React.Fragment>
                <div className="hidden md:block">
                  <Button
                    text={`See Additional ${currentClub.book_count - currentClub.showcased_books.length} Recommendations`}
                    onClick={() => navigate(`/clubs/${uuid}/recommendations`)}
                    color="bg-fuschia"
                  />
                </div>
                <div className="md:hidden">
                  <Button text="All books" onClick={() => navigate(`/clubs/${uuid}/recommendations`)} color="bg-fuschia" />
                </div>
              </React.Fragment>
            )}
            <Tooltip title="Overlap between your library and all Club book recommendations">
              <div className="flex">
                <Tag
                  text={`${currentClub.user_overlap_percent || 0}% overlap`}
                  color="bg-yellow border-2 border-orange"
                />
              </div>
            </Tooltip>
          </div>
          {currentClub.taste_description && <p className="pl-4 md:pl-0 text-left">{currentClub.taste_description}</p>}
          <div className="flex flex-wrap gap-6 mt-4 justify-center md:justify-start">
            {currentClub.showcased_books.map((book) => (
              <BookCard key={book.id} book={book} />
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <header
        className={`${CLUB_THEMES[currentClub.color]} w-screen flex items-center justify-center border-b px-5 md:px-0`}
      >
        <div className="flex flex-col my-8 md:my-12 gap-6 m-auto max-w-[650px] justify-center items-center">
          <h1 className="flex items-center gap-2">
            {currentClub.name}
            {currentClub.private && <LockOutlinedIcon className="text-offBlack" fontSize="inherit" />}
          </h1>
          <h3 className="text-center">{currentClub.description}</h3>
          <div className="flex gap-6 justify-center">
            {currentClub.member_status === "joined" ? (
              <Button onClick={handleLeave} text="Joined" color="bg-mainGreen" size="medium" />
            ) : currentClub.member_status === "pending" ? (
              <Button disabled text="Pending" size="medium" />
            ) : (
              <Button onClick={handleJoin} text={currentClub.private ? "Request to Join" : "Join"} color="bg-neonBlue" size="medium" />
            )}
          </div>
        </div>
      </header>

      <div className="container justify-center items-center md:items-start md:justify-start m-auto md:px-0 w-11/12 md:w-9/12 max-w-[1280px] box-border pb-[100px]">
        <div className="flex gap-6 items-center justify-center my-8 md:my-16">
          <Button
            type="navigation"
            text="About Us"
            isActive={view === "about-us"}
            onClick={() => navigate(`/clubs/${uuid}/about-us`)}
          />
          <Button
            type="navigation"
            text="Forum"
            isActive={view === "forum"}
            onClick={() => navigate(`/clubs/${uuid}/forum`)}
          />
          <Button
            type="navigation"
            text="Book Recs"
            isActive={view === "recommendations"}
            onClick={() => navigate(`/clubs/${uuid}/recommendations`)}
          />
        </div>

        {view === "about-us" && <AboutUsView />}
        {view === "recommendations" && <AllClubBooks />}
        {view === "forum" && (
          <div className="flex justify-center">
            {currentClub.private && currentClub.member_status !== "joined" ? (
              <div className="flex flex-col gap-3 box-border border border-offBlack shadow-main rounded-default bg-mainBlue p-5 md:p-8 w-full max-w-[800px]">
                <div className="bg-white rounded-xl p-6 text-center">
                  This club is private! Request to join to see and participate in the discussion.
                </div>
              </div>
            ) : (
              <Forum resource={currentClub} resourceType="Club" />
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ClubMemberView;
