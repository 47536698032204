import React, { useState } from "react";
import { openModal } from "../../app/slices/globalUISlice";
import { useDispatch, useSelector } from "react-redux";
import Collapsible from "react-collapsible";
import clsx from "clsx";
import { fetchShelves, massUpdateShelfBook } from "../../app/slices/shelfSlice";
import check from "../../assets/graphic-check.svg";
import { updateUserBookStatus, createUserBook } from "../../app/slices/userBookSlice";
import Modal from "../modals/Modal";
import { BOOK_STATUS_BG_COLOR, BOOK_STATUS_ICON_MAP, BOOK_STATUS_TEXT_MAP } from "../../constants";
import Button from "../helpers/Button";
import { getMaterialIcon } from "../../utils/icons";
const LockOutlinedIcon = getMaterialIcon("LockOutlined");

const BookStatusButton = ({ userBook, bookId, variant, setUserBookData }) => {
  const dispatch = useDispatch();
  const status = userBook?.status || "set-status";
  const { currentUserId } = useSelector((state) => state.user);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedShelves, setSelectedShelves] = useState(userBook ? userBook.shelves.map((shelf) => shelf.id) : []);
  const [selectedStatus, setSelectedStatus] = useState(userBook?.status || "none");
  const { shelves, loading } = useSelector((state) => state.shelf);

  const handleOpenModal = (event) => {
    event.stopPropagation();
    if (!currentUserId) return dispatch(openModal("loginPrompt"));
    if (shelves.length === 0) dispatch(fetchShelves());
    setIsModalOpen(true);
  };

  const renderModal = () => {
    const handleSave = () => {
      if (userBook && userBook.id) {
        if (selectedStatus !== userBook.status) {
          dispatch(updateUserBookStatus({ userBook: userBook, status: selectedStatus }));
        }

        if (shouldUpdateShelves()) {
          dispatch(massUpdateShelfBook({ shelfIds: selectedShelves, userBookId: userBook.id }));
        }

        const updatedShelves = shelves.filter((shelf) => selectedShelves.includes(shelf.id));
        setUserBookData({ ...userBook, ...{ shelves: updatedShelves, status: selectedStatus } });
      } else if (selectedStatus !== "none" || selectedShelves.length > 0) {
        dispatch(
          createUserBook({
            userBookData: { status: selectedStatus, book_id: bookId },
            shelfIds: selectedShelves,
          })
        )
          .unwrap()
          .then((payload) => setUserBookData(payload));
      }
      setIsModalOpen(false);
    };

    const shouldUpdateShelves = () => {
      if (!userBook) {
        return true;
      }
      if (userBook.shelves.length !== selectedShelves.length) {
        return true;
      }
      // compare the shelfIds to see if update is necessary
      const sortedCurrentShelves = userBook.shelves.map((shelf) => shelf.id).sort((a, b) => a - b);
      const sortedSelectedShelves = selectedShelves.slice().sort((a, b) => a - b);
      const updated = !sortedCurrentShelves.every((value, index) => value === sortedSelectedShelves[index]);
      return updated;
    };

    const handleToggle = (shelfId) => {
      if (selectedShelves.includes(shelfId)) {
        const updatedShelves = selectedShelves.filter((selectedShelf) => selectedShelf !== shelfId);
        setSelectedShelves(updatedShelves);
      } else {
        const updatedShelves = [...selectedShelves, shelfId];
        setSelectedShelves(updatedShelves);
      }
    };

    const renderButton = (status) => {
      const isSelected = selectedStatus === status;
      return (
        <div className={clsx("rounded-boxy", isSelected && "shadow-highlight")} key={status}>
          <button
            className={clsx(
              BOOK_STATUS_BG_COLOR[status],
              "flex flex-row gap-1 items-center justify-center shadow-main font-bold font-space text-[11px] md:text-[13px] rounded-boxy w-[115px] py-1 md:py-1.5 cursor-pointer border border-offBlack"
            )}
            onClick={() => setSelectedStatus(status)}
            type="button"
          >
            {status === "none" ? "none" : BOOK_STATUS_TEXT_MAP[status]}

            <img src={BOOK_STATUS_ICON_MAP[status]} alt={status} className="w-[13px] h-[13px] md:w-5 md:h-5 object-contain" />
          </button>
        </div>
      );
    };

    const chevron = "relative after:transform after:transition-all after:font-sans after:content-['›'] after:absolute";
    return (
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title="Update book in library">
        <div className="md:w-[300px]">
          <hr className="text-gray my-3" />
          <p>Set reading status</p>
          <div className="flex flex-col gap-2 items-center mt-3">
            {["tbr", "current", "finished", "dnf", "none"].map((status) => renderButton(status))}
          </div>
          <hr className="text-gray my-4" />
          <div className="flex flex-col gap-1 my-2 md:gap-2 md:my-4">
            <Collapsible
              trigger={<span className="mr-3">Add tags</span>}
              triggerClassName={`${chevron} after:rotate-90`}
              triggerOpenedClassName={`${chevron} after:-rotate-90`}
              transitionTime={200}
            >
              <p className="text-xs mb-4">Select as many as you'd like</p>
              <div className="flex flex-col w-full gap-1">
                {loading ? (
                  <div>Loading...</div>
                ) : shelves.length === 0 ? (
                  <div className="text-sm">No tags yet!</div>
                ) : (
                  shelves.map(({ id, name, private: isPrivate }) => {
                    const isSelected = selectedShelves.includes(id);
                    return (
                      <label
                        key={id}
                        htmlFor={id}
                        className={clsx(
                          "py-1 px-2 hover:bg-mainGreen flex justify-between items-center rounded-default cursor-pointer",
                          isSelected && "bg-mainGreen"
                        )}
                      >
                        <div className="flex flex-row gap-1 items-center">
                          {isSelected && <img src={check} alt="selected" className="w-5 h-5" />}
                          {name}
                        </div>
                        {isPrivate && <LockOutlinedIcon className="text-offBlack" fontSize="small" />}
                        <input
                          hidden
                          id={id}
                          type="checkbox"
                          checked={isSelected}
                          value={name}
                          onChange={() => handleToggle(id)}
                        />
                      </label>
                    );
                  })
                )}
              </div>
            </Collapsible>
          </div>
          <div className="flex flex-row justify-end mt-4">
            <Button text="Save" color="bg-neonBlue" onClick={handleSave} />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      {variant === "button" ? (
        <button
          className={clsx(
            BOOK_STATUS_BG_COLOR[status],
            "flex flex-row gap-1 items-center justify-center shadow-main font-bold font-space text-[11px] md:text-[13px] rounded-boxy w-[87px] md:w-[106px] py-1 md:py-1.5 cursor-pointer border border-offBlack"
          )}
          onClick={handleOpenModal}
          key={status}
          type="button"
        >
          {BOOK_STATUS_TEXT_MAP[status]}
          <img src={BOOK_STATUS_ICON_MAP[status]} alt={status} className="w-[13px] h-[13px] md:w-5 md:h-5 object-contain" />
        </button>
      ) : (
        <button onClick={handleOpenModal}>
          <img
            src={BOOK_STATUS_ICON_MAP[status]}
            alt="selected"
            className="w-5 h-5 cursor-pointer object-contain"
          />
        </button>
      )}
      {isModalOpen && renderModal()}
    </>
  );
};

export default BookStatusButton;
