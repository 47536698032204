import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { clsx } from "clsx";
import sparkle from "../../../assets/sparkle.svg";
import Layout from "../../layout/Layout";
import { capitalize } from "../../../utils/utils";
import HeadTags from "../../helpers/HeadTags";
import BookmarkList from "./BookmarkList";
import { getMaterialIcon } from "../../../utils/icons";
const ExpandMoreOutlinedIcon = getMaterialIcon("ExpandMoreOutlined");

const Bookmarks = () => {
  const navigate = useNavigate();
  const currentType = useParams().type?.toLowerCase() || "books";
  const currentUserId = useSelector((state) => state.user.currentUserId);
  const [showCategories, setShowCategories] = useState(true);

  const renderCategories = () => {
    const types = ["books", "lists", "quests", "clubs"];

    const handleClick = (type) => {
      setShowCategories(false);
      navigate(`/library/bookmarks/${type}`);
    };

    return (
      <ul className="w-[250px] flex flex-col flex-none gap-1.5 justify-start text-left">
        {types.map((type) => (
          <li
            key={type}
            onClick={() => handleClick(type)}
            className={clsx(
              "flex gap-1.5 font-space font-bold hover:text-pink cursor-pointer truncate",
              currentType == type ? "text-offBlack" : "text-gray"
            )}
          >
            <img src={sparkle} alt="sparkle" className={currentType == type ? "visible" : "invisible"} />
            {capitalize(type)}
          </li>
        ))}
      </ul>
    );
  };

  const ctas = [
    { text: "Books", onClick: () => navigate("/library") },
    { text: "Plan", onClick: () => navigate("/library/plan") },
    { text: "Bookmarks", isActive: true, onClick: () => setShowCategories(true) },
  ];

  return (
    <Layout
      title="Library"
      ctas={ctas}
      description={`When you're not quite ready to commit, but you don't want to lose your place...\nfind your saved items here.`}
      showUnauth={!currentUserId}
    >
      <HeadTags title="Bookmarks" description="When you're not quite ready to commit, but you don't want to lose your place... find your saved items here." />
      <div className="container max-w-[1280px] mx-auto flex lg:gap-12 justify-center box-border px-3 mb-[100px]">
        {/* Desktop */}
        <div className="hidden lg:inline-block w-fit flex-none">{renderCategories()}</div>
        <div className="hidden lg:inline-block flex flex-col lg:w-[670px] xl:w-[870px] gap-2 bg-gradient-to-b to-pastelOrange from-pastelGreen box-border p-6 md:p-8 rounded-default w-full items-start text-left">
          <h2>{capitalize(currentType)}</h2>
          <BookmarkList type={currentType} />
        </div>

        {/* Mobile */}
        <div className="lg:hidden w-full flex justify-center">
          {showCategories && (
            <div className="min-w-[300px] py-4 rounded-default border border-offBlack shadow-main bg-lightPurple flex m-auto justify-center items-center">
              {renderCategories()}
            </div>
          )}
          {!showCategories && (
            <div className="flex flex-col lg:w-[670px] xl:w-[870px] gap-2 bg-gradient-to-b to-pastelOrange from-pastelGreen box-border p-6 md:p-8 rounded-default w-full items-start text-left">
              <button className="flex items-center gap-1" onClick={() => setShowCategories(true)}>
                <h2>{capitalize(currentType)}</h2>
                <ExpandMoreOutlinedIcon fontSize="small" />
              </button>
              <BookmarkList type={currentType} />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Bookmarks;
