import React from "react";
import { useNavigate } from "react-router-dom";
import ClubCard from "../cards/ClubCard";
import Button from "../helpers/Button";

const ClubResults = ({ searchQuery, searchResults }) => {
  const navigate = useNavigate();

  const handleCreateClub = () => {
    navigate("/clubs/new");
  };

  if (searchResults.length === 0) {
    return (
      <div className="bg-white rounded-xl p-6 text-center">
        <b>Hmmm, we didn't find any anything for "{searchQuery}"</b>
        <p className="mt-2">
          Not finding the Club you're looking for?{" "}
          <Button type="text" onClick={handleCreateClub} text="Create" color="text-red" />
          {" "}one!
        </p>
      </div>
    );
  };

  return (
    <>
      {searchResults.map((club) => <ClubCard key={club.id} club={club} />)}
      <div className="flex flex-row items-center justify-between gap-6 bg-white rounded-xl shadow-main p-6 bg-opacity-80">
        <div>
          <b>Not finding the Club you're looking for?</b>
          <p className="mt-2">
            You should create it 😉
          </p>
        </div>
        <Button onClick={handleCreateClub} text="Create Club" color="bg-red" />
      </div>
    </>
  );
};

export default ClubResults;
