import React, { useState, useEffect } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useParams, Link, useLocation } from "react-router-dom";
import api from "../../utils/api";
import ListBooks from "./ListBooks";
import Votes from "../votes/Votes";
import { useDispatch, useSelector } from "react-redux";
import { openModal, openSnackbar } from "../../app/slices/globalUISlice";
import shareIcon from "../../assets/shareIcon.svg";
import SaveIcon from "../../assets/SaveIcon";
import Button from "../helpers/Button";
import EditListForm from "./EditListForm";
import IconButton from "../helpers/IconButton";
import HeadTags from "../helpers/HeadTags";
import CommentForm from "../forum/showPost/CommentForm";
import Comment from "../forum/showPost/Comment";
import { getMaterialIcon } from "../../utils/icons";
const EditIcon = getMaterialIcon("Edit");

const ListPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { uuid } = useParams();
  const currentUserId = useSelector((state) => state.user.currentUserId);
  const [isEditing, setIsEditing] = useState(false);
  const { data: { list, comments }, refetch: refetchList } = useSuspenseQuery({
    queryKey: ["list", uuid],
    queryFn: () => api.get(`/lists/${uuid}`),
  });

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.slice(1);
      if (elementId) {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView();
        }
      }
    }
  }, [location]);

  const handleBookmark = async () => {
    if (!currentUserId) {
      dispatch(openModal({ name: "loginPrompt" }));
    } else {
      const params = { type: "List", id: list.id };
      const action = list.is_bookmarked ? api.destroy(`/bookmarks/destroy`, params) : api.post(`/bookmarks`, params);
      action.then(refetchList);
    }
  };

  const handleUpvote = () => {
    api.post(`/lists/${uuid}/upvote`).then(refetchList);
  };

  const handleDownvote = () => {
    api.post(`/lists/${uuid}/downvote`).then(refetchList);
  };

  const handleShare = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(`${window.location.origin}/lists/${list.uuid}`);
    dispatch(openSnackbar({ message: "Link copied to clipboard" }));
  };

  const afterListEdit = () => {
    refetchList();
    setIsEditing(false);
  };

  return (
    <div className="container relative flex flex-col box-border lg:flex-row gap-8 lg:gap-0">
      <HeadTags title={list?.name} description={list?.description} />
      <div className="relative z-10">
        <div className="lg:fixed flex flex-col lg:pt-[100px] pb-[18px] lg:pb-0 px-3 lg:px-6 gap-6 lg:gap-8 items-center w-full lg:max-w-[350px] lg:h-[100vh] bg-gradient-to-b from-pastelOrange to-pastelBlue">
          {isEditing ? (
            <EditListForm list={list} afterListEdit={afterListEdit} />
          ) : (
            <>
              <div className="flex flex-col lg:gap-2 pt-[48px] lg:pt-0 items-center text-center">
                <h2>{list.name}</h2>
                <p className="max-w-[400px]">{list.description}</p>
                <div className="flex gap-2">
                  {list.emojis.map((emoji, index) => (
                    <div className="text-[20px] md:text-[31px]" key={index}>
                      {emoji}
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex flex-col lg:gap-3">
                <p>
                  created by <Link to={`/users/${list.user.username}`}>{list.user.username}</Link>
                </p>
                <p className="text-xs md:text-sm">last updated {list.updated_at}</p>
              </div>

              <div className="flex gap-6">
                <Votes
                  upvotes={list.upvotes}
                  handleUpvote={handleUpvote}
                  handleDownvote={handleDownvote}
                  userVote={list.user_vote}
                  variant="large"
                />
                <button
                  onClick={handleBookmark}
                  className="hidden lg:block p-1 rounded-full hover:bg-yellow"
                >
                  <SaveIcon fillColor={list.is_bookmarked ? "#F7DB90" : "transparent"} width="23" height="32" />
                </button>
                <button
                  onClick={handleBookmark}
                  className="lg:hidden p-1 rounded-full hover:bg-yellow"
                >
                  <SaveIcon fillColor={list.is_bookmarked ? "#F7DB90" : "transparent"} width="15" height="20" />
                </button>
              </div>
              <div className="self-end lg:self-auto -mt-[16px] lg:-mt-0 mr-[10px] lg:mr-0">
                {currentUserId === list.user.id && (
                  <div className="flex gap-3">
                    {!isEditing ? (
                      <IconButton
                        IconComponent={EditIcon}
                        onClick={() => setIsEditing(true)}
                        tooltip="Edit"
                      />
                    ) : (
                      <Button onClick={() => setIsEditing(false)} text="Save" color="bg-neonBlue" />
                    )}
                    <IconButton onClick={handleShare} tooltip="Share">
                      <img src={shareIcon} alt="share" className="w-[10px] h-[10px] md:w-[15px] md:h-[15px]" />
                    </IconButton>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="flex flex-col w-full lg:ml-[440px] lg:pt-[100px] pb-[100px]">
        <ListBooks list={list} isEditing={isEditing} />
        <div className="flex flex-col items-start w-full max-w-[600px] mt-16 px-4" id="comments">
          <h2 className="mb-2 self-start">Comments</h2>
          {comments.length > 0 && (
            <div className="mb-4 md:mb-6 w-full">
              {comments.map((c) => (
                <Comment key={c.uuid} commentData={c} listId={list.uuid} bgColor="bg-beige" />
              ))}
            </div>
          )}
          <CommentForm showDivider={false} resourceType="List" resourceId={list.id} onSuccess={refetchList} />
        </div>
      </div>
    </div>
  );
};

export default ListPage;
