import React from "react";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import Button from "../helpers/Button";
import api from "../../utils/api";
import QuestCard from "../quests/QuestCard";
import ViewMoreButton from "../helpers/ViewMoreButton";

const DiscoverQuests = () => {
  const popularQuestsQuery = useSuspenseInfiniteQuery({
    queryKey: ["popularQuests"],
    queryFn: ({ pageParam }) => api.get(`/challenges?q=popular&page=${pageParam}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage.length < 5 ? undefined : pages.length + 1,
  });
  const newQuestsQuery = useSuspenseInfiniteQuery({
    queryKey: ["newQuestsPaginated"],
    queryFn: ({ pageParam }) => api.get(`/challenges?q=new&page=${pageParam}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage.length < 5 ? undefined : pages.length + 1,
  });

  const popularQuests = popularQuestsQuery.data.pages.flat();
  const newQuests = newQuestsQuery.data.pages.flat();

  return (
    <div className="container flex flex-col max-w-[900px] mx-auto m-auto gap-8 md:gap-10 items-start mb-[100px]">
      <div className="w-full text-left flex flex-col gap-5 md:gap-6">
        <div className="flex gap-8 items-center">
          <h2>Popular Quests</h2>
          <Button type="link" to="/quests/browse" text="Browse all" color="bg-fuschia" />
        </div>
        <div className="flex flex-col gap-3">
          {popularQuests.map((quest) => (
            <QuestCard key={quest.id} quest={quest} />
          ))}
          <ViewMoreButton {...popularQuestsQuery} />
        </div>
      </div>

      <div className="w-full text-left flex flex-col gap-5 md:gap-6">
        <h2>New Quests</h2>
        <div className="flex flex-col gap-3">
          {newQuests.map((quest) => (
            <QuestCard key={quest.id} quest={quest} />
          ))}
          <ViewMoreButton {...newQuestsQuery} />
        </div>
      </div>
    </div>
  );
};

export default withFallback(DiscoverQuests);
