import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { updateYearGoal, addToYearlyGoal, removeFromYearlyGoal } from "../../app/slices/userBookSlice";
import { openModal } from "../../app/slices/globalUISlice";
import LibraryBookCard from "../user/library/LibraryBookCard";
import LibraryAddBookCard from "../user/library/LibraryAddBookCard";
import api from "../../utils/api";
import Loader from "../helpers/Loader";
import HeadTags from "../helpers/HeadTags";
import IconButton from "../helpers/IconButton";
import { getMaterialIcon } from "../../utils/icons";
const EditIcon = getMaterialIcon("Edit");
const RemoveCircleIcon = getMaterialIcon("RemoveCircle");

const YearChallengePage = () => {
  const { username } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { currentUser } = useSelector((state) => state.user);
  const currentYear = new Date().getFullYear();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [challengeData, setChallengeData] = useState(null);
  const [challengeUserBooks, setChallengeUserBooks] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const percentComplete = Math.ceil(((challengeData?.books_read_count || 0) / (challengeData?.goal || 0)) * 100);
  const isCurrentUser = location.pathname === "/reading-challenge" || username === currentUser?.username;

  useEffect(() => {
    if (!currentUser) return;
    setLoading(true);

    api.get(`/yearly_goal?username=${isCurrentUser ? currentUser.username : username}&year=${currentYear}`)
      .then((data) => {
        setChallengeData(data.yearly_goal);
        setUser(data.user);

        api.get(`/users/current/user_books?book_ids=${data.user_books.map((ub) => ub.book_id)}`)
          .then((currUserData) => {
            const currUserBooks = currUserData.user_books.reduce((acc, value) => ({ ...acc, [value.book_id]: value }), {});
            const combinedBookData = data.user_books.map((ub) => {
              const match = currUserBooks[ub.book_id];
              return { ...ub, id: match?.id, uuid: match?.uuid, status: match?.status, shelves: match?.shelves || [] }
              // fields to consider: date_added, dates_read, review?
            });
            setChallengeUserBooks(combinedBookData);
          })
        .catch((err) => console.log("Error: ", err))
        .finally(() => setLoading(false));
      })
  }, [currentUser]);

  const removeBookButton = (userBook) => {
    const removeBook = (userBook) => {
      dispatch(removeFromYearlyGoal(userBook.id))
        .unwrap()
        .then((data) => {
          if (data.error) {
            console.error("Error removing book from yearly goal:", data.error);
          } else {
            setChallengeUserBooks((prev) => prev?.filter((ub) => ub.id !== userBook.id));
            setChallengeData((prev) => ({
              ...prev,
              books_read_count: prev.books_read_count - 1,
            }));
            setChallengeData((prev) => {
              return prev;
            });
          }
        })
        .catch((error) => {
          console.error("Error removing book from yearly goal:", error);
        });
    };

    return (
      <button
        onClick={() =>
          dispatch(
            openModal({
              name: "confirmation",
              data: {
                title: `Are you sure you want to remove this book from your ${currentYear} Reading Challenge?`,
                body: "If yes, the book will be removed here but remain in your library",
                confirmText: "Yes, remove",
                onConfirm: () => removeBook(userBook),
              },
            })
          )
        }
      >
        <RemoveCircleIcon fontSize="small" sx={{ color: "#FF695F" }} />
      </button>
    );
  };

  const addBookToGoal = (book) => {
    dispatch(addToYearlyGoal(book.id))
      .unwrap()
      .then((data) => {
        if (data.error) {
          console.error("Error adding book to Year Goal:", data.error);
        } else {
          setChallengeUserBooks((prev) => [...prev, data]);
          setChallengeData((prev) => ({
            ...prev,
            books_read_count: prev.books_read_count + 1,
          }));
        }
      })
      .catch((error) => {
        console.error("Error adding book to Year Goal:", error);
      });
  };

  const handleSubmit = (values) => {
    const newGoal = Number(values.goal);

    if (newGoal != challengeData.goal) {
      dispatch(updateYearGoal({ yearlyGoalId: challengeData.id, goal: newGoal }));
    }

    setChallengeData((prev) => ({ ...prev, goal: newGoal }));
    setIsEditing(false);
  };

  const renderEditForm = () => {
    const goalSchema = Yup.object().shape({
      goal: Yup.number().required().integer().max(999).min(1),
    });

    return (
      <Formik
        initialValues={{
          goal: challengeData.goal,
        }}
        validationSchema={goalSchema}
        onSubmit={handleSubmit}
      >
        {({ errors }) => (
          <Form className="flex items-center">
            <Field
              id="goal"
              name="goal"
              className="w-[75px] ml-0 mr-5 text-center px-2 py-1 font-bold text-base md:text-xl my-auto border border-offBlack rounded-default focus:outline-none focus:ring-1 focus:border-seafoam focus:ring-seafoam"
            />
            <button
              className="shadow-main bg-fuschia min-w-[60px] h-fit whitespace-nowrap font-bold leading-none font-space text-[11px] md:text-[13px] rounded-[50px] px-3 py-1.5 md:py-[.5em] cursor-pointer border border-offBlack disabled:bg-offWhite disabled:shadow-none disabled:border-gray disabled:text-gray"
              disabled={errors.goal}
              type="submit"
            >
              Save
            </button>
          </Form>
        )}
      </Formik>
    );
  };

  const onClickAddBookCard = () => {
    const addedBookIds = challengeUserBooks.map((ub) => ub.book_id);
    dispatch(openModal({ name: "searchReturnBook", data: { handleClick: addBookToGoal, addedBookIds } }));
  };

  if (loading) {
    return <Loader variant="fullPage" />;
  }

  if (!isCurrentUser && challengeData.goal === 0) {
    return (
      <div className="min-h-[100vh] h-max m-auto pt-8 flex flex-col items-center pb-[50px] md:pb-[100px]">
        <h1 className="pt-8 md:pt-[4em]">{`${user?.username}'s ${currentYear} Reading Challenge`}</h1>
        <div className="relative mt-[65px] w-full max-w-[900px] h-[200px] p-7 flex flex-col items-center justify-around gap-4">
          <div className="absolute w-full h-full blur-md rounded-default bg-gradient-to-r from-pastelOrange to-pastelPurple -z-10" />
          <b>{user?.username} has not set their reading goal</b>
        </div>
      </div>
    );
  }

  return (
    <div className="relative min-h-[100vh] h-max mx-auto m-auto bg-gradient-to-b from-pastelOrange to-pastelGreen pb-[100px]">
      <HeadTags title="Reading Challenge" description="Set a reading goal for the year and track your progress" />
      <div className="container m-auto pt-8 md:pt-[4em] flex flex-col">
        <header className="flex flex-col gap-4 md:gap-6 mb-16 md:mb-24 items-center text-center">
          <h1>{`${isCurrentUser ? "Your" : `${user.username}'s`} ${currentYear} Reading Challenge`}</h1>

          {!challengeData?.goal > 0 && (
            <h3 className="max-w-[300px] md:max-w-full">
              Set your reading goal for the year, and add or edit the books you finished.
            </h3>
          )}

          <div className="flex items-center self-center gap-3">
            <h2 className="font-bold">Goal:</h2>
            {isEditing && renderEditForm()}
            {!isEditing && (
              <React.Fragment>
                <h2 className="font-bold">{challengeData.goal === 0 ? "not set" : `${challengeData.goal} books`}</h2>
                {isCurrentUser && (
                  <IconButton
                    IconComponent={EditIcon}
                    onClick={() => setIsEditing(true)}
                    tooltip="Edit"
                  />
                )}
              </React.Fragment>
            )}
          </div>

          {challengeData?.goal > 0 && (
            <div className="relative z-0 w-8/12 min-w-[320px] max-w-[800px] px-4 py-1 bg-white rounded-default border border-offBlack overflow-hidden box-border">
              <div
                className="absolute z-10 h-full left-0 top-0"
                style={{
                  width: `${percentComplete}%`,
                  background: `${challengeData.complete ? "#D6FE63" : "#FEDE83"}`,
                }}
              />
              <div className="relative z-20 font-space">
                {challengeData.books_read_count} of {challengeData.goal} read {challengeData.complete ? "🎉" : ""}
              </div>
            </div>
          )}
        </header>

        {challengeData && (
          <div className="relative flex flex-col gap-3 md:grid md:grid-cols-3 lg:grid-cols-4 w-full max-w-[320px] md:max-w-[800px] md:w-fit self-center items-center md:gap-5">
            <h2 className="absolute -top-[34px] md:-top-[54px] left-0">Finished books</h2>
            {challengeUserBooks.map((userBook) => (
              <LibraryBookCard
                userBook={userBook}
                key={userBook.id}
                isOtherUser={!isCurrentUser}
                icon={isEditing ? removeBookButton(userBook) : <></>}
              />
            ))}
            {isCurrentUser && <LibraryAddBookCard onClick={onClickAddBookCard} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default YearChallengePage;
