import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Hits, InstantSearch, SearchBox, useInstantSearch, Configure } from "react-instantsearch";
import { history } from "instantsearch.js/es/lib/routers";
import { ALGOLIA_CLIENT, indexName } from "../../utils/algolia.jsx";
import BookCard from "../cards/BookCard";
import { openModal } from "../../app/slices/globalUISlice";
import Button from "../helpers/Button";
import api from "../../utils/api.js";

const BookResults = (props) => {
  const dispatch = useDispatch();
  const timeoutRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // prevents "no result" card from flickering
    setIsLoading(true);
    timeoutRef.current = setTimeout(() => {
      setIsLoading(false);
    }, 100);
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const handleAddBook = () => {
    dispatch(openModal("addBookFromGoodreads"));
  };

  const Results = ({ searchQuery }) => {
    const { results } = useInstantSearch();
    const [userBooks, setUserBooks] = useState({});

    useEffect(() => {
      if (results.hits.length === 0) return;
      api.get(`/users/current/user_books?book_ids=${results.hits.map((h) => h.id)}`).then((data) => {
        setUserBooks(data.user_books.reduce((acc, value) => ({ ...acc, [value.book_id]: value }), {}));
      });
    }, [results.hits.length]);

    if (results.hits.length === 0) {
      return (
        <div className="bg-white rounded-xl p-6 text-center">
          <b>Hmmm, we didn't find any anything for "{searchQuery}"</b>
          <p className="mt-2">
            Is it a spelling error, or are we missing something from our database?
            <br />
            If you think we're missing a book, you can add it{" "}
            <Button type="text" onClick={handleAddBook} text="here" color="text-red" />!
          </p>
        </div>
      );
    }

    return (
      <>
        <div className="flex flex-col md:flex-row items-center justify-between gap-6 bg-white rounded-xl border border-offBlack shadow-main p-6 bg-opacity-80">
          <div>
            <b>Are we missing a book?</b>
            <p className="mt-2">
              Apologies! We're expanding our book catalog as quickly as we can. As a team of two, we would love your
              help adding the book you're looking for (it's quick and easy!)
            </p>
          </div>
          <Button onClick={handleAddBook} text="add book" color="bg-red" />
        </div>
        <Hits
          hitComponent={({ hit }) => <BookCard book={{ ...hit, user_book: userBooks[hit.id] }} variant="large" />}
          classNames={{ list: "flex flex-col gap-5", item: "!p-0 !shadow-none !bg-transparent" }}
        />
      </>
    );
  };

  return (
    <>
      <InstantSearch
        searchClient={ALGOLIA_CLIENT}
        indexName={indexName}
        future={{ preserveSharedStateOnUnmount: true }}
        routing={{
          router: history({ cleanUrlOnDispose: false }),
          stateMapping: {
            stateToRoute(uiState) {
              return { query: uiState[indexName].query, type: "Book" };
            },
            routeToState(routeState) {
              return { [indexName]: { query: routeState.query } };
            },
          },
        }}
      >
        <Configure hitsPerPage={20} />
        <SearchBox className="hidden" />
        {!isLoading && <Results {...props} />}
      </InstantSearch>
    </>
  );
};

export default BookResults;
