import React, { useEffect, useState } from "react";
import { withFallback } from "vike-react-query";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import Home from "../components/home/Home";
import HomeFeed from "../components/home/HomeFeed";
import BookPage from "../components/book/BookPage";
import AllRecommendationsPage from "../components/book/AllRecommendationsPage";
import AuthorPage from "../components/author/AuthorPage";
import LibraryBooks from "../components/user/library/LibraryBooks";
import Bookmarks from "../components/user/bookmarks/Bookmarks";
import Plan from "../components/user/schedule/Plan";
import CurrentUserProfile from "../components/user/profile/CurrentUserProfile";
import OtherUserProfile from "../components/user/profile/OtherUserProfile";
import QuestsHome from "../components/quests/QuestsHome";
import Discover from "../components/discover/Discover";
import QuestPage from "../components/quests/QuestPage";
import ClubHome from "../components/clubs/ClubHome";
import ClubShow from "../components/clubs/show/ClubShow";
import CreateClub from "../components/clubs/CreateClub";
import SearchResults from "../components/results/SearchResults";
import YearChallengePage from "../components/yearChallenge/YearChallengePage";
import OnboardingProfile from "../components/onboarding/OnboardingProfile";
import OnboardingJoinClub from "../components/onboarding/OnboardingJoinClub";
import OnboardingGoodreadsImport from "../components/onboarding/OnboardingGoodreadsImport";
import OnboardingReferral from "../components/onboarding/OnboardingReferral";
import PostPage from "../components/forum/PostPage";
import NotFound from "../components/NotFound";
import NotificationsHome from "../components/notifications/NotificationsHome";
import GoodreadsImport from "../components/user/profile/GoodreadsImport";
import ActivityItemPage from "../components/home/ActivityItemPage";
import AllAuthors from "../components/author/AllAuthors";
import AllClubs from "../components/clubs/AllClubs";
import AllQuests from "../components/quests/AllQuests";
import ListsHome from "../components/lists/ListsHome";
import ListPage from "../components/lists/ListPage";
import CreateList from "../components/lists/CreateList";
import AllLists from "../components/lists/AllLists";
import ReviewPage from "../components/review/ReviewPage";
import Discuss from "../components/discuss/Discuss";

const Page = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.user);
  const [prevLocation, setPrevLocation] = useState(null);

  useEffect(() => {
    if (!currentUser && location.pathname.startsWith("/onboarding")) {
      navigate("/");
    }

    if (currentUser && !currentUser.onboarding_completed && !location.pathname.startsWith("/onboarding")) {
      navigate("/onboarding/profile");
    }
  }, [location, currentUser]);

  useEffect(() => {
    if (prevLocation) {
      const prevSlugs = prevLocation.pathname.split("/");
      const currSlugs = location.pathname.split("/");
      if (currSlugs[1] === "onboarding" || prevSlugs[1] !== currSlugs[1] || prevSlugs.length !== currSlugs.length) {
        window.scrollTo(0, 0);
      }
    }

    setPrevLocation(location);
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/feed" element={<HomeFeed />} />
      <Route path="/notifications" element={<NotificationsHome />} />

      <Route path="/onboarding/profile" element={<OnboardingProfile />} />
      <Route path="/onboarding/join-clubs" element={<OnboardingJoinClub />} />
      <Route path="/onboarding/goodreads-import" element={<OnboardingGoodreadsImport />} />
      <Route path="/onboarding/referral" element={<OnboardingReferral />} />

      <Route path="/books/:uuid" element={<BookPage />} />
      <Route path="/books/:uuid/:view" element={<AllRecommendationsPage />} />
      <Route path="/authors" element={<AllAuthors />} />
      <Route path="/authors/:uuid" element={<AuthorPage />} />

      <Route path="/library/:status?" element={<LibraryBooks />} />
      <Route path="/library/tags/:uuid" element={<LibraryBooks />} />
      <Route path="/library/plan/:view?" element={<Plan />} />
      <Route path="/library/bookmarks/:type?" element={<Bookmarks />} />
      <Route path="/reading-challenge" element={<YearChallengePage />} />

      <Route path="/discover/:view?" element={<Discover />} />
      <Route path="/results" element={<SearchResults />} />

      <Route path="/profile" element={<CurrentUserProfile />} />
      <Route path="/goodreads-import" element={<GoodreadsImport />} />
      <Route path="/users/:username" element={<OtherUserProfile />} />
      <Route path="/users/:username/library/:status?" element={<OtherUserProfile showLibrary />} />
      <Route path="/users/:username/library/tags/:uuid" element={<OtherUserProfile showLibrary />} />
      <Route path="/users/:username/reading-challenge" element={<YearChallengePage />} />

      <Route path="/clubs" element={<ClubHome />} />
      <Route path="/clubs/browse" element={<AllClubs />} />
      <Route path="/clubs/new" element={<CreateClub />} />
      <Route path="/clubs/:uuid/:view?" element={<ClubShow />} />

      <Route path="/quests" element={<QuestsHome />} />
      <Route path="/quests/browse" element={<AllQuests />} />
      <Route path="/quests/:uuid" element={<QuestPage />} />

      <Route path="/posts/:id" element={<PostPage />} />
      <Route path="/activity/:id" element={<ActivityItemPage />} />
      <Route path="/reviews/:id" element={<ReviewPage />} />

      <Route path="/lists" element={<ListsHome />} />
      <Route path="/lists/new" element={<CreateList />} />
      <Route path="/lists/browse" element={<AllLists />} />
      <Route path="/lists/:uuid" element={<ListPage />} />

      <Route path="/discuss" element={<Discuss />} />

      {/* Must be listed last! */}
      <Route path="*" element={<NotFound />}></Route>
    </Routes>
  );
};

export default withFallback(Page);
