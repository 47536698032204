import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { BOOK_STATUS_LABEL_MAP } from "../../../constants";
import sparkle from "../../../assets/sparkle.svg";
import Button from "../../helpers/Button";
import { getMaterialIcon } from "../../../utils/icons";
const LockOutlinedIcon = getMaterialIcon("LockOutlined");

const LibraryList = ({ currentStatus, shelfUuid, setShowLibraryList }) => {
  const navigate = useNavigate();
  const { shelves, loading } = useSelector((state) => state.shelf);

  const handleClick = (path) => {
    if (setShowLibraryList) setShowLibraryList(false);
    navigate(path);
  };

  if (loading) return null;

  return (
    <ul className="w-[250px] flex flex-col flex-none gap-1.5 justify-center md:justify-start text-left">
      {["current", "tbr", "finished", "dnf"].map((s) => (
        <li key={s}>
          <button
            onClick={() => handleClick(`/library/${s}`)}
            className={clsx(
              "flex gap-1.5 font-space font-bold hover:text-pink truncate",
              currentStatus == s ? "text-offBlack" : "text-gray"
            )}
          >
            <img src={sparkle} alt="sparkle" className={currentStatus == s ? "visible" : "invisible"} />
            {BOOK_STATUS_LABEL_MAP[s]}
          </button>
        </li>
      ))}
      <li className="my-3">
        <Button
          onClick={() => handleClick("/library/tags/new")}
          size="medium"
          text="Create Tag"
          color="bg-neonBlue w-full-shadow"
        />
      </li>
      {shelves.map((shelf) => (
        <li key={shelf.uuid} className="flex flex-row justify-between">
          <button
            onClick={() => handleClick(`/library/tags/${shelf.uuid}`)}
            className={clsx(
              "flex gap-1.5 font-space font-bold hover:text-pink truncate",
              shelfUuid == shelf.uuid ? "text-offBlack" : "text-gray"
            )}
          >
            <img src={sparkle} alt="sparkle" className={shelfUuid == shelf.uuid ? "visible" : "invisible"} />
            {shelf.name}
          </button>
          {shelf.private && (
            <LockOutlinedIcon className={shelfUuid == shelf.uuid ? "text-offBlack" : "text-gray"} fontSize="small" />
          )}
        </li>
      ))}
    </ul>
  );
};

export default LibraryList;
