import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchForumPosts, clearForum } from "../../app/slices/forumSlice";
import NewPostForm from "./NewPostForm";
import PostCard from "./PostCard";
import { openModal } from "../../app/slices/globalUISlice";
import Checkbox from "../helpers/Checkbox";
import Loader from "../helpers/Loader";
import { getMaterialIcon } from "../../utils/icons";
const ExpandMoreOutlinedIcon = getMaterialIcon("ExpandMoreOutlined");

const Forum = ({ resource, resourceType }) => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const { forum, posts, error, loading } = useSelector((state) => state.forum);
  const { currentUserId } = useSelector((state) => state.user);
  const { currentClub } = useSelector((state) => state.club);
  const [filters, setFilters] = useState({ onlyFollowing: false });
  const [morePosts, setMorePosts] = useState(false);
  const [loadingMorePosts, setLoadingMorePosts] = useState(false);
  const pageRef = useRef(1);

  useEffect(() => {
    return () => {
      dispatch(clearForum());
      pageRef.current = 1;
    };
  }, []);

  useEffect(() => {
    pageRef.current = 1;
    dispatch(fetchForumPosts({ resource_id: resource.id, resource_type: resourceType, page: 1, filters }))
      .unwrap()
      .then((data) => setMorePosts(data.total_pages > 1));
  }, [resource?.id, resourceType, filters]);

  const handleMorePosts = () => {
    setLoadingMorePosts(true);
    pageRef.current += 1;
    dispatch(fetchForumPosts({ resource_id: resource.id, resource_type: resourceType, page: pageRef.current, filters }))
      .unwrap()
      .then((data) => {
        if (data.total_pages === pageRef.current) setMorePosts(false);
      })
      .finally(() => setLoadingMorePosts(false));
  };

  const handleOpenPostModal = (postId) => {
    dispatch(openModal({ name: "showPost", data: { postId } }));
  };

  const onClickCreate = () => {
    if (!currentUserId) dispatch(openModal("loginPrompt"));
    else if (forum.resource_type === "Club" && currentClub.member_status !== "joined") {
      dispatch(
        openModal({
          name: "info",
          data: {
            title: "Only club members can post in the club forum!",
            body: "Join this club to participate in the discussion.",
          },
        })
      );
    } else {
      setShowForm(true);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-3 box-border border border-offBlack shadow-main rounded-default bg-mainBlue p-5 md:p-8 w-full max-w-[800px]">
        {showForm ? (
          <div className="bg-white p-6 border border-offBlack rounded-default shadow-main">
            <NewPostForm forum={forum} onSubmit={() => setShowForm(false)} />
          </div>
        ) : (
          <button className="bg-white p-6 rounded-default border border-offBlack" onClick={onClickCreate}>
            <div className="font-hanken text-gray ml-0 text-left py-3 px-4 my-auto border border-offBlack rounded-[20px]">
              Create post
            </div>
          </button>
        )}

        <div className="flex flex-row gap-3 md:gap-6 my-2 md:my-3 text-sm md:text-base">
          <Checkbox
            id="onlyFollowing"
            isChecked={filters.onlyFollowing}
            onChange={() => setFilters((prev) => ({ ...prev, onlyFollowing: !prev.onlyFollowing }))}
            text="People I follow"
          />
        </div>
        {loading ? (
          <Loader />
        ) : (
          <ul className="flex flex-col gap-4">
            {posts.map((post) => (
              <PostCard key={post.uuid} postData={post} onClick={handleOpenPostModal} />
            ))}
            {morePosts && (
              <button
                className="bg-transparent p-2 self-center mt-2"
                disabled={loadingMorePosts}
                onClick={handleMorePosts}
              >
                {loadingMorePosts ? (
                  "Loading..."
                ) : (
                  <>
                    View More <ExpandMoreOutlinedIcon fontSize="small" />
                  </>
                )}
              </button>
            )}
          </ul>
        )}
        {posts.length === 0 && !loading && <div>No posts yet! Kick off the convo by posting above.</div>}
      </div>
    </>
  );
};

export default Forum;
