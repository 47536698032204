import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import FullReview from "../review/FullReview";
import expand from "../../assets/graphic-expand.svg";
import { closeModal } from "../../app/slices/globalUISlice";

const ShowReviewModal = ({ reviewId, setCardReview }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (!reviewId) return;

  return (
    <Modal
      isOpen
      classNames={{
        container: "min-w-[95%] md:min-w-[90%] lg:min-w-[700px] lg:max-w-[800px] !overflow-y-clipped",
        margin: "mt-[30%] md:mt-[120px]",
      }}
      headerActions={[
        {
          icon: expand,
          onClick: () => {
            navigate(`/reviews/${reviewId}`);
            dispatch(closeModal());
          },
        },
      ]}
    >
      <FullReview reviewId={reviewId} setCardReview={setCardReview} />
    </Modal>
  );
};

export default ShowReviewModal;
