import React from "react";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import BookCard from "../../cards/BookCard";
import ListCard from "../../cards/ListCard";
import QuestCard from "../../quests/QuestCard";
import ClubCard from "../../cards/ClubCard";
import api from "../../../utils/api";
import ViewMoreButton from "../../helpers/ViewMoreButton";

const BookmarkList = ({ type }) => {
  const [typeParam, typeText] =
    type === "lists" ? ["List", "list"]
    : type === "quests" ? ["Challenge", "Quest"]
    : type === "clubs" ? ["Club", "Club"]
    : ["Book", "book"];

  const bookmarksQuery = useSuspenseInfiniteQuery({
    queryKey: ["bookmarks", type],
    queryFn: ({ pageParam }) => api.get(`/bookmarks?type=${typeParam}&page=${pageParam}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage.length < 10 ? undefined : pages.length + 1,
  });
  const bookmarks = bookmarksQuery.data.pages.flat();

  return (
    <div className="w-full flex flex-col gap-4 items-center justify-center">
      <div className={`w-full flex flex-col gap-4 mt-4 items-center justify-center ${type === "books" ? "md:grid md:grid-cols-3 xl:grid-cols-4" : ""}`}>
        {bookmarks?.length === 0 && (
          <div className="w-full flex flex-col gap-3 bg-white rounded-xl p-6 items-center text-center">
            <b>Nothing here yet!</b>
            <p>
              To Bookmark a {typeText}, click the yellow bookmark icon on the top right of any {typeText} card,
              or directly from the {typeText} page.
            </p>
          </div>
        )}
        {bookmarks?.map((b) => (
          <React.Fragment key={b.id}>
            {type === "books" && <BookCard book={b} key={b.id} />}
            {type === "lists" && <ListCard listData={b} key={b.id} />}
            {type === "quests" && <QuestCard quest={b} key={b.id} />}
            {type === "clubs" && <ClubCard club={b} key={b.id} />}
          </React.Fragment>
        ))}
      </div>
      <ViewMoreButton {...bookmarksQuery} />
    </div>
  );
};

export default withFallback(BookmarkList);
