import React from "react";
import Button from "../helpers/Button";
import Modal from "./Modal";

const GoodreadsDirectionsModal = () => {
  return (
    <Modal isOpen>
      <div className="md:max-w-[500px] p-2 flex flex-col gap-4">
        <p>To export your Goodreads data into a CSV file <b>(note: this works on desktop only)</b>...</p>
        <p>1. Go to <a href="https://www.goodreads.com/review/import" target="_blank">https://www.goodreads.com/review/import</a> and log into your Goodreads account (if you weren't already).</p>
        <p>2. Click the "Export Library" button at the top of the page, right below the Export heading.</p>
        <p>3. Wait a bit until a link "Your export from (date) - (time)" appears below the button. Click on that link to download the CSV file.</p>
        <Button text="Take me to Goodreads" type="link" to="https://www.goodreads.com/review/import" color="bg-neonBlue self-end" />
      </div>
    </Modal>
  );
};

export default GoodreadsDirectionsModal;
